import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import eye from "../../../assets/admin/assets/img/eye.svg";
import "./approval.scss";
import "../../admin/Driver/driver.scss";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Exportorder = () => {
  const [loading, setloading] = useState(true);
  const [export_orders, setexport_orders] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // .........................TablePagination ............................................

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ............................Table Sort.......................................

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = [...export_orders].sort((a, b) => {
    if (sort.direction === "asc") {
      return a[sort.key] > b[sort.key] ? 1 : -1;
    }
    return b[sort.key] > a[sort.key] ? 1 : -1;
  });

  // ...........................export list api .......................................

  useEffect(() => {
    const fetchexportData = async () => {
      try {
        const res = await axios.get("/admin/export-order-list");
        if (res.data.status === true) {
          setexport_orders(res.data.OrderList);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchexportData();
  }, []);

  // ..............................export_data list.................................

  let export_data = "";

  export_data =
    sortedData.length > 0 ? (
      sortedData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          const serialNo = page * rowsPerPage + index + 1;
          return (
            <tr key={item.id}>
              <td>{serialNo}</td>
              <td>{item.order_id}</td>
              <td>
                <span> {item.customer_first_name}/</span>
                {item.customer_last_name}/{item.customer_company_name}
              </td>

              <td>{item.loading}</td>
              <td>{item.unloading}</td>
              <td>
                {item.type}-{item.vehicle_capacity}
              </td>
              <td>{item.order_amount}</td>
              <td>{item.order_date}</td>
              <td>
                <Link to={`/admin/order/approval-view/${item.id}`}>
                  <img src={eye} width="35px"></img>
                </Link>
              </td>
            </tr>
          );
        })
    ) : (
      <tr>
        <td colSpan="12" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  return (
    <div className="container-fluid approval Driver Export">
      <div className="title px-2 py-2">
        <h4>Export Approval List</h4>
      </div>
      <div className="card">
        <div className="card-body ">
          <div className="table-responsive">
            <table className="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th className="">S No</th>
                  <th className="Customer_table">
                    Order ID
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Customer
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("customer_first_name")}
                    />
                  </th>

                  <th className="Customer_table">
                    Loading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("loading")}
                    />
                  </th>
                  <th className="Customer_table">
                    Unloading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("unloading")}
                    />
                  </th>
                  <th className="Customer_table">
                    Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("type")}
                    />
                  </th>
                  <th className="Customer_table">
                    Order Amount
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_amount")}
                    />
                  </th>
                  <th className="Customer_table">
                    Booking Date
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_date")}
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{export_data}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={sortedData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Exportorder;
