import Dashboard from "../ComponentsA/Vendor/Dashboard";
import VendorOrder from "../ComponentsA/Vendor/VendorOrder/VendorOrder";
import VendorRoute from "../ComponentsA/Vendor/VendorRoute/VendorRoute";
import vendorVechile from "../ComponentsA/Vendor/VendorVechile/vendorVechile";
import VendorVehicleview from "../ComponentsA/Vendor/VendorVechile/VendorVehicleview";
import VendorOrderView from "../ComponentsA/Vendor/VendorOrder/VendorOrderView";
import VendorRouteshow from "../ComponentsA/Vendor/VendorRoute/VendorRouteshow";
import DriverList from "../ComponentsA/Vendor/Driver/DriverList";
import Adddrivers from "../ComponentsA/Vendor/Driver/AddDriver";
import VendorvechileCreate from "../ComponentsA/Vendor/VendorVechile/VendorvechileCreate";
import Driverview from "../ComponentsA/Vendor/Driver/Driverview";
import Driverupdate from "../ComponentsA/Vendor/Driver/Driverupdate";
import Vechileupdate from "../ComponentsA/Vendor/VendorVechile/Vendorvechileupdate";
import Credits from "../ComponentsA/Vendor/Credit/Credits";
import Import from "../ComponentsA/Vendor/List/Import";
import Export from "../ComponentsA/Vendor/List/Export";

import User from "../ComponentsA/Vendor/User";
import Editvendoruser from "../ComponentsA/Vendor/Editvendoruser";

const vendorRouts = [
  { path: "/vendor", exact: true, name: "vendor" },

  {
    path: "/vendor/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  { path: "/vendor/user", exact: true, name: "user", component: User },
  {
    path: "/vendor/edituser",
    exact: true,
    name: "Editvendoruser",
    component: Editvendoruser,
  },

  {
    path: "/vendor/orderlist",
    exact: true,
    name: "vendororder",
    component: VendorOrder,
  },
  {
    path: "/vendor/orderview/:id",
    exact: true,
    name: "vendororderview",
    component: VendorOrderView,
  },
  {
    path: "/vendor/vehiclelist",
    exact: true,
    name: "vendorvehicle",
    component: vendorVechile,
  },
  {
    path: "/vendor/vechile-add",
    exact: true,
    name: "vechilecreate",
    component: VendorvechileCreate,
  },
  {
    path: "/vendor/vechile-update/:id",
    exact: true,
    name: "vechileupdate",
    component: Vechileupdate,
  },
  {
    path: "/vendor/vehicle-view/:id",
    exact: true,
    name: "vendorvehicleview",
    component: VendorVehicleview,
  },

  {
    path: "/vendor/routelist",
    exact: true,
    name: "vendorroute",
    component: VendorRoute,
  },
  {
    path: "/vendor/routeshow/:id",
    exact: true,
    name: "vendorrouteshow",
    component: VendorRouteshow,
  },
  {
    path: "/vendor/driverlist",
    exact: true,
    name: "driverlist",
    component: DriverList,
  },
  {
    path: "/vendor/driver-add",
    exact: true,
    name: "adddriver",
    component: Adddrivers,
  },
  {
    path: "/vendor/driver-update/:id",
    exact: true,
    name: "driverupdate",
    component: Driverupdate,
  },
  {
    path: "/vendor/driver-view/:id",
    exact: true,
    name: "drivershow",
    component: Driverview,
  },

  { path: "/vendor/Credits", exact: true, name: "Credits", component: Credits },
  {
    path: "/vendor/List/Import",
    exact: true,
    name: "Import",
    component: Import,
  },
  {
    path: "/vendor/List/Export",
    exact: true,
    name: "Export",
    component: Export,
  },
];
export default vendorRouts;
