import React from "react";
import { Link } from "react-router-dom";
import "../../assets/admin/js/scripts";
import HomeIcon from "@mui/icons-material/Home";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import MapIcon from "@mui/icons-material/Map";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import driver from "../../assets/admin/assets/img/driver.svg";
import vendor from "../../assets/admin/assets/img/admin.svg";
import im_icon from "../../Import.png";
import ex_icon from "../../Export.png";
import cr_icon from "../../Credit.png";
const VendorSidebar = () => {
  return (
    <nav
      className="sb-sidenav accordion sb-sidenav-dark nav-bgco"
      id="sidenavAccordion"
    >
      <div className="sb-sidenav-menu">
        <div className="nav navi-bg">
          {/* <div className="sb-sidenav-menu-heading">Core</div> */}
          <Link className="nav-link" to="/vendor/dashboard">
            <div className="sb-nav-link-icon">
              {" "}
              <HomeIcon />
            </div>
            <p>Dashboard</p>
          </Link>
          <Link className="nav-link" to="/vendor/orderlist">
            <div className="sb-nav-link-icon">
              <img src={vendor} width="20px"></img>
            </div>
            <p>All Orders</p>
          </Link>
          <Link className="nav-link" to="/vendor/routelist">
            <div className="sb-nav-link-icon">
              <MapIcon />
            </div>
            <p>Route</p>
          </Link>
          <Link className="nav-link" to="/vendor/driverlist">
            <div className="sb-nav-link-icon">
              <img src={driver} width="20px"></img>
            </div>
            <p> Driver</p>
          </Link>
          <Link className="nav-link" to="/vendor/vehiclelist">
            <div className="sb-nav-link-icon">
              <LocalShippingIcon />
            </div>
            <p>Vehicle</p>
          </Link>
          {/* <Link className="nav-link" to="/vendor/List/Import">
            <div className="sb-nav-link-icon">
              <img src={im_icon} width="20px"></img>
            </div>
            <p>Import</p>
          </Link>
          <Link className="nav-link" to="/vendor/List/Export">
            <div className="sb-nav-link-icon">
              <img src={ex_icon} width="20px"></img>
            </div>
            <p>Export</p>
          </Link>
          <Link className="nav-link" to="/vendor/Credits">
            <div className="sb-nav-link-icon">
              <img src={cr_icon} width="20px"></img>
            </div>
            <p>Credits</p>
          </Link> */}
        </div>
      </div>
    </nav>
  );
};

export default VendorSidebar;
