import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import "./AddVechile.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Radio from "@mui/material/Radio";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Vehicleadd = () => {
  const alert = useAlert();
  const history = useHistory();
  const { truckcapacity, trucktype, state } = useContext(UserContext);
  const [city, setcity] = useState([]);
  const [vendorid, setvendorid] = useState("");
  const [vendorname, setvendorname] = useState([]);
  const [drivername, setdrivername] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [vechileview, setvechileview] = useState({
    vehicle_manufacture: "",
    vehicle_type: "",
    truck_capacity_id: "",
    vehicle_number: "",
    city_id: "",
    state_id: "",
    make_year: "",
    vendor_id: "",
    status: "",
    fc_exc: "",
    insurance_exc: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ................selectedValue.............
  const [selectedValue, setSelectedValue] = React.useState("1");

  const handleChangee = (event) => {
    setSelectedValue(event.target.value);
  };

  // ....................Document.........................
  const [insurancedoc, setinsurancedoc] = useState("");
  const [rcbookdoc, setrcbookdoc] = useState("");
  const [localpermit, setlocalpermit] = useState("");
  const [roadtax, setroadtax] = useState("");
  const [nationalpermit, setnationalpermit] = useState("");
  const [polutiondoc, setpolutiondoc] = useState("");
  const [fcdoc, setfc] = useState("");

  function insurancedocChange(event) {
    setinsurancedoc(event.target.files[0]);
  }

  function rcbookChange(event) {
    setrcbookdoc(event.target.files[0]);
  }

  function localChange(event) {
    setlocalpermit(event.target.files[0]);
  }

  function setroadtaxChange(event) {
    setroadtax(event.target.files[0]);
  }

  function setnationalchange(event) {
    setnationalpermit(event.target.files[0]);
  }

  function setpolutionChange(event) {
    setpolutiondoc(event.target.files[0]);
  }

  function setfcChange(event) {
    setfc(event.target.files[0]);
  }

  //  ...................handlevendor..............................
  const handlevendor = (e) => {
    e.persist();
    const vendorid = e.target.value;
    setvendorid(vendorid);
    setvechileview({
      ...vechileview,
      [e.target.name]: e.target.value,
    });
  };

  // ......................vendorlist api get.......................
  useEffect(() => {
    axios
      .get(`/vendorlist`)
      .then((res) => {
        if (res.data.status === true) {
          setvendorname(res.data.vendor);
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  // ......................driverlist api get.......................

  useEffect(() => {
    if (vendorid) {
      axios
        .get(`/driverlist/${vendorid}`)
        .then((res) => {
          if (res.data.status === true) {
            setdrivername(res.data.driver);
          }
        })
        .catch(() => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
  }, [vendorid]);

  // ..........................handleInput.................................
  const currentDate = new Date();
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1
  );
  const mindate = minDate.toISOString().split("T")[0];

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setvechileview({
          ...vechileview,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
        setvechileview({
          ...vechileview,
          [name]: value,
        });
        break;
      case "state_id":
      case "city_id":
      case "driver_id":
      case "truck_capacity_id":
      case "truck_type_id":
      case "fc_exc":
      case "insurance_exc":
        setvechileview({
          ...vechileview,
          [name]: value,
        });
        break;
      case "make_year":
        setvechileview({
          ...vechileview,
          [name]: value.replace(/\D/g, "").slice(0, 4),
        });
        break;
      case "vehicle_number":
        setvechileview({
          ...vechileview,
          [name]: value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 13),
        });
        break;
      default:
        setvechileview({
          ...vechileview,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  //  ..............................validate.................................
  const validate = (values) => {
    const errors = {};
    const Year = /^\d{4}$/;
    const vec = /^[a-zA-Z0-9]{10}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.vendor_id) {
      errors.vendor_id = "Vendor Name is required !";
    }

    if (!values.driver_id) {
      errors.driver_id = "Driver Name is required !";
    }

    if (!values.email) {
      errors.email = "Email ID is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }

    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password Confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }

    if (!values.company_name) {
      errors.company_name = "Company Name is required !";
    }

    if (!values.make_year) {
      errors.make_year = "Make Year is required !";
    } else if (!Year.test(values.make_year)) {
      errors.make_year = "Make Year Must be 4 digits !";
    }

    if (!values.vehicle_number) {
      errors.vehicle_number = "Vehicle Number is required !";
    } else if (!vec.test(values.vehicle_number)) {
      errors.vehicle_number =
        "Vehicle Number must be 10 alphanumeric characters !";
    }

    if (!values.truck_type_id) {
      errors.truck_type_id = "Vehicle Type is required !";
    }
    if (!values.vehicle_manufacture) {
      errors.vehicle_manufacture = "Vehicle Manufacturer is required !";
    }

    if (!values.truck_capacity_id) {
      errors.truck_capacity_id = "Vehicle Size is required !";
    }
    if (!values.insurance_exc) {
      errors.insurance_exc = "Insurance Expiry is date required !";
    }
    if (!values.fc_exc) {
      errors.fc_exc = "Fc Expiry date is required !";
    }

    if (!insurancedoc) {
      errors.insurancedoc = "Insurance Document is required !";
    }
    if (!fcdoc) {
      errors.fcdoc = "FC Document is required !";
    }
    if (!rcbookdoc) {
      errors.rcbookdoc = "Rc Book Document is required !";
    }
    if (!localpermit) {
      errors.localpermit = "Local Permit Document is required !";
    }

    if (!roadtax) {
      errors.roadtax = "Road Tax Document is required !";
    }
    if (!nationalpermit) {
      errors.nationalpermit = "National Permit Document is required !";
    }
    if (!polutiondoc) {
      errors.polutiondoc = "Pollution Certificate Document is required !";
    }

    if (!values.city_id) {
      errors.city_id = "City is required !";
    }

    if (!values.state_id) {
      errors.state_id = "State is required !";
    }

    if (!selectedValue) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  // ........................submit.............................

  const submitvendor = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(vechileview);
    setErrors(errors);
    setIsSubmit(true);
    const formData = new FormData();
    formData.append("insurance", insurancedoc);
    if (insurancedoc != "") {
      formData.append("insurance", insurancedoc.name);
    }
    formData.append("rcbook", rcbookdoc);
    if (rcbookdoc != "") {
      formData.append("rcbook", rcbookdoc.name);
    }
    formData.append("localpermit", localpermit);
    if (localpermit != "") {
      formData.append("localpermit", localpermit.name);
    }
    formData.append("roadtax", roadtax);
    if (roadtax != "") {
      formData.append("roadtax", roadtax.name);
    }
    formData.append("nationalpermit", nationalpermit);
    if (nationalpermit != "") {
      formData.append("nationalpermit", nationalpermit.name);
    }
    formData.append("polution", polutiondoc);
    if (polutiondoc != "") {
      formData.append("polution", polutiondoc.name);
    }
    formData.append("fc", fcdoc);
    if (fcdoc != "") {
      formData.append("fc", fcdoc.name);
    }
    formData.append("vendor_id", vechileview.vendor_id);
    formData.append("driver_id", vechileview.driver_id);
    formData.append("vehicle_manufacture", vechileview.vehicle_manufacture);
    formData.append("vehicle_number", vechileview.vehicle_number);
    formData.append("truck_type_id", vechileview.truck_type_id);
    formData.append("truck_capacity_id", vechileview.truck_capacity_id);
    formData.append("city_id", vechileview.city_id);
    formData.append("state_id", vechileview.state_id);
    formData.append("make_year", vechileview.make_year);
    formData.append("status", selectedValue);
    formData.append("insurance_exc", vechileview.insurance_exc);
    formData.append("fc_exc", vechileview.fc_exc);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`/admin/vehicle-create`, formData, config)
      .then((res) => {
        if (res.data.status === true) {
          setvechileview(res.data.vehicle);
          swal("Created", res.data.message, "success");
          history.push("/admin/vehicle/vehiclelist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  //  ........................isSubmit..........................
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  // ..........city list get api...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.status === false) {
          swal("Error", data.status, "error");
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Add Vehicle</span>
          <Link
            to="/admin/vehicle/vehiclelist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card  mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vendor Name <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="vendor_id"
                  onChange={handlevendor}
                  value={vechileview.vendor_id}
                >
                  {vendorname.length == 0 ? (
                    <>
                      <option value="">Loading...</option>
                    </>
                  ) : (
                    <>
                      <option value="">--Select Vendor--</option>
                      {vendorname.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.first_name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                <p>{errors.vendor_id}</p>
              </div>

              <div className="col-md-6 form-group mb-3">
                <label>
                  Driver Name <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="driver_id"
                  onChange={handleInput}
                  value={vechileview.driver_id}
                >
                  {drivername.length == 0 ? (
                    <>
                      <option value="">--No Driver Found--</option>
                    </>
                  ) : (
                    <>
                      <option value="">--Select Driver--</option>
                      {drivername.map((itm) => {
                        return (
                          <option value={itm.id} key={itm.id}>
                            {itm.first_name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                <p>{errors.driver_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vehicle Manufacturer <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  name="vehicle_manufacture"
                  placeholder="Vehicle Manufacturer"
                  onChange={handleInput}
                  value={vechileview.vehicle_manufacture}
                  className="form-control"
                />
                <p>{errors.vehicle_manufacture}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vehicle Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  name="vehicle_number"
                  placeholder="Vehicle Number"
                  onChange={handleInput}
                  value={vechileview.vehicle_number}
                  className="form-control"
                />
                <p>{errors.vehicle_number}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vehicle Type <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="truck_type_id"
                  onChange={handleInput}
                  value={vechileview.truck_type_id}
                >
                  <option value="">--Select Vehicle Type--</option>
                  {trucktype.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.type_name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.truck_type_id}</p>
              </div>

              <div className="col-md-6 form-group mb-3">
                <label>
                  Vehicle Size <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="truck_capacity_id"
                  onChange={handleInput}
                  value={vechileview.truck_capacity_id}
                >
                  <option value="">--Select Vehicle Size--</option>
                  {truckcapacity.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.capactiy_name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.truck_capacity_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  State <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="state_id"
                  onChange={handleInput}
                  value={vechileview.state_id}
                >
                  <option value="">--Select State--</option>
                  {state.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.state_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  City <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleInput}
                  value={vechileview.city_id}
                >
                  <option value="">--Select City--</option>
                  {city.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.city_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Make Year <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  name="make_year"
                  placeholder="Make Year"
                  value={vechileview.make_year}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.make_year}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Insurance Expiry Date <span className="star_icon">*</span>
                </label>
                <input
                  type="date"
                  name="insurance_exc"
                  value={vechileview.insurance_exc}
                  onChange={handleInput}
                  className="form-control"
                  min={mindate}
                />
                <p>{errors.insurance_exc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Fc Expiry Date <span className="star_icon">*</span>
                </label>
                <input
                  type="date"
                  name="fc_exc"
                  value={vechileview.fc_exc}
                  onChange={handleInput}
                  className="form-control"
                  min={mindate}
                />
                <p>{errors.fc_exc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Insurance Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  name="insurance"
                  onChange={insurancedocChange}
                  className="form-control"
                />
                <p>{errors.insurancedoc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  RC Book Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  name="rcbook"
                  onChange={rcbookChange}
                  className="form-control"
                />
                <p>{errors.rcbookdoc}</p>
              </div>

              <div className="col-md-6 form-group mb-3">
                <label>
                  Local Permit Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  name="localpermit"
                  onChange={localChange}
                  className="form-control"
                />
                <p>{errors.localpermit}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  FC Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  name="fc"
                  onChange={setfcChange}
                  className="form-control"
                />
                <p>{errors.fcdoc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Road Tax Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  name="roadtax"
                  onChange={setroadtaxChange}
                  className="form-control"
                />
                <p>{errors.roadtax}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Pollution Certificate Document{" "}
                  <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  name="polution"
                  onChange={setpolutionChange}
                  className="form-control"
                />
                <p>{errors.polutiondoc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  National Permit Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  name="nationalpermit"
                  onChange={setnationalchange}
                  className="form-control"
                />
                <p>{errors.nationalpermit}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === "1"}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "1" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === "0"}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "0" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-secondary float-end px-5 p-2"
                  onClick={submitvendor}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Vehicleadd;
