import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import eye from "../../../assets/vendor/assets/img/eye.svg";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import swal from "sweetalert";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const VendorRoute = () => {
  const [loading, setloading] = useState(true);
  const [routelist, setroutelist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // ......................TablePagination..............................

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // .........................Table Sort.................................
  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...routelist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [routelist, sort]);

  // ........................search Table.................................
  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.loadingPoint.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloadingPoint.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.truck_type_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(item.costing_price)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.truck_capacity_id.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // .......................vendor-route-list.................................

  useEffect(() => {
    const fetchrouteData = async () => {
      try {
        const res = await axios.get("/vendor/vendor-route-list");
        if (res.data.status === true) {
          setroutelist(res.data.routelist);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchrouteData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .................................vendor_route............................

  let vendor_route = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    vendor_route =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.loadingPoint}</td>
                <td>{item.unloadingPoint}</td>
                <td>{item.type}</td>
                <td>{item.truck_type_id}</td>
                <td>{item.truck_capacity_id}</td>
                {/* <td>{item.costing_price}</td> */}
                <td>
                  <div className="actions">
                    <Link to={`/vendor/routeshow/${item.id}`}>
                      <img src={eye} width="35px"></img>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr>
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  const TotalCount = filteredData.reduce((total, item) => {
    return total + item.costing_price;
  }, 0);

  return (
    <div className="container-fluid px-4 Driver">
      <div className="title px-1 mt-4 ">
        <h4>Route List</h4>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="card-body px-4">
          <div className="table-responsive">
            <table className="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th className="Customer_table">S No</th>

                  <th className="Customer_table">
                    Loading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("loadingPoint")}
                    />
                  </th>
                  <th className="Customer_table">
                    Unloading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("unloadingPoint")}
                    />
                  </th>
                  <th className="Customer_table">
                    Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("type")}
                    />
                  </th>
                  <th className="Customer_table">
                    Truck Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("truck_type_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Container Size
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("truck_capacity_id")}
                    />
                  </th>
                  {/* <th className="Customer_table">
                    Costing Price <p>({TotalCount})</p>
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("costing_price")}
                    />
                  </th> */}
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{vendor_route}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VendorRoute;
