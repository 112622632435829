import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Routeshow = (props) => {
  const [routeshow, setrouteshow] = useState({
    vendor: "",
    loading: "",
    unloading: "",
    truck_type_name: "",
    truck_capacity_name: "",
    costing_price: "",
    route_doc: "",
    status_name: "",
  });

  // ............................route-show epdi api..................................

  useEffect(() => {
    const route_show = props.match.params.id;
    const getRouteshow = async () => {
      const res = await axios.get(`/admin/route-show/${route_show}`);
      if (res.data.status === true) {
        setrouteshow(res.data.route);
      }
    };

    try {
      getRouteshow();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  // ...............................................................
  const formattedDate = new Date(routeshow.created_at).toLocaleDateString(
    "en-US"
  );
  const formattedTime = new Date(routeshow.created_at).toLocaleTimeString(
    "en-US"
  );

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Route Show</span>
          <Link
            to="/admin/route/routelist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-6 view-main">
              <div className="first-col">
                <h6>Type</h6>
                {/* <h6>Vendor Name</h6> */}
                <h6>Loading</h6>
                <h6>Unloading</h6>
                <h6>Vehicle Type</h6>
                <h6>Container Size</h6>
                <h6>Costing Price</h6>
                <h6>Created Date and Time</h6>
                <h6>Status</h6>
              </div>
              <div className="first-col-sibling">
                <small>{routeshow.type}</small>
                {/* <small>{routeshow.vendor}</small> */}
                <small>{routeshow.loading} </small>
                <small>{routeshow.unloading}</small>

                <small>{routeshow.truck_type_name}</small>
                <small>{routeshow.truck_capacity_name}</small>
                <small>{routeshow.costing_price}</small>
                <small>
                  {formattedDate} T {formattedTime}{" "}
                </small>
                <small>
                  {routeshow.status_name === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{routeshow.status_name}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{routeshow.status_name}</span>
                      </div>
                    </>
                  )}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Routeshow;
