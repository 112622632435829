import React from "react";
import home from "../../assets/admin/assets/img/admi.svg";
import warehouse from "../../assets/admin/assets/img/wearhouse.svg";
import customer from "../../assets/admin/assets/img/vendor.svg";
import driver from "../../assets/admin/assets/img/driver.svg";
import vendor from "../../assets/admin/assets/img/admin.svg";
import order from "../../assets/admin/assets/img/order.svg";
import { Link } from "react-router-dom";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MapIcon from "@mui/icons-material/Map";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

const Sidebar = () => {
  // let isActive = Link ;
  // let Active = isActive ? 'active' : '';

  return (
    <nav
      className="sb-sidenav accordion sb-sidenav-dark nav-bgco"
      id="sidenavAccordion"
    >
      <div className="sb-sidenav-menu">
        <div className="nav navi-bg">
          <Link className="nav-link  " to="/admin/dashboard">
            <div className="sb-nav-link-icon">
              <img src={warehouse} width="20px"></img>{" "}
            </div>
            <p>Dashboard</p>
          </Link>
          <Link className="nav-link " to="/admin/customer/Customerlist">
            <div className="sb-nav-link-icon">
              <img src={customer} width="20px"></img>
            </div>
            <p>Customer</p>
          </Link>
          <Link className="nav-link " to="/admin/pendingkyc/customer-kyc">
            <div className="sb-nav-link-icon">
              <SupervisorAccountIcon />
            </div>
            <p>Pending KYC</p>
          </Link>
          <Link className="nav-link " to="/admin/vendor/vendorlist">
            <div className="sb-nav-link-icon">
              <img src={vendor} width="20px"></img>
            </div>
            <p>Vendor</p>
          </Link>
          <Link className="nav-link " to="/admin/driver/driverlist">
            <div className="sb-nav-link-icon">
              <img src={driver} width="20px"></img>
            </div>
            <p> Driver</p>
          </Link>
          <Link className="nav-link " to="/admin/vehicle/vehiclelist">
            <div className="sb-nav-link-icon">
              <LocalShippingIcon />
            </div>
            <p>Vehicle</p>
          </Link>
          <Link className="nav-link " to="/admin/adminlist">
            <div className="sb-nav-link-icon">
              <img src={home}></img>
            </div>
            <p>Admin</p>
          </Link>
          <Link className="nav-link" to="/admin/order/all-reports">
            <div className="sb-nav-link-icon">
              <img src={order} width="20px"></img>
            </div>
            <p>All Reports</p>
          </Link>
          <Link className="nav-link " to="/admin/reports/order-report">
            <div className="sb-nav-link-icon">
              <PersonSearchIcon />
            </div>
            <p>Order Reports</p>
          </Link>
          <Link className="nav-link" to="/admin/reports/detailed-reportlist">
            <div className="sb-nav-link-icon">
              <PersonSearchIcon />
            </div>
            <p>Detailed Reports</p>
          </Link>
          <Link
            className="nav-link"
            to="/admin/paymentReport/payment-reportlist"
          >
            <div className="sb-nav-link-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-wallet2"
                viewBox="0 0 16 16"
              >
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
              </svg>
            </div>
            <p>Payment Reports</p>
          </Link>
          <Link className="nav-link " to="/admin/jobsearch">
            <div className="sb-nav-link-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
            <p>Job Search</p>
          </Link>

          <Link
            className="nav-link collapsed"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#collapseLayout"
            aria-expanded="false"
            aria-controls="collapseLayouts"
          >
            <div className="sb-nav-link-icon">
              <SettingsIcon />
            </div>
            <p>Master Data</p>
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="collapseLayout"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link " to="/admin/City/Citylist">
                <div className="sb-nav-link-icon">
                  <MapIcon />
                </div>
                <p>City</p>
              </Link>
              <Link className="nav-link " to="/admin/warehouse/warehouselist">
                <div className="sb-nav-link-icon">
                  <WarehouseIcon />
                </div>
                <p> Warehouse</p>
              </Link>
              <Link className="nav-link " to="/admin/route/routelist">
                <div className="sb-nav-link-icon">
                  <MapIcon />
                </div>
                <p> Route</p>
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
