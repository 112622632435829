import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Radio from "@mui/material/Radio";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Customerupdate = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const customer_show = props.match.params.id;
  const [driverview, setdriverview] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    gst_number: "",
    password: "",
    password_confirmation: "",
  });

  // .....................showPassword.........................
  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ....................selectedValue.................................
  const [selectedValue, setSelectedValue] = React.useState();

  useEffect(() => {
    setSelectedValue(driverview.status);
  }, [driverview]);

  const handleChangee = (event) => {
    setSelectedValue(parseInt(event.target.value));
    if (selectedValue === 1) {
      return "true";
    } else {
      return "false";
    }
  };

  // ...................Document........................
  const [gstdoc, setgstdoc] = useState("");
  const [pandoc, setpandoc] = useState("");
  const [aadhaardoc, setaadhaardoc] = useState("");

  function gstdocChange(event) {
    setgstdoc(event.target.files[0]);
  }

  function pandocChange(event) {
    setpandoc(event.target.files[0]);
  }
  function aadhaardocChange(event) {
    setaadhaardoc(event.target.files[0]);
  }

  // ........................handleInput............................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setdriverview({
          ...driverview,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
      case "state_id":
      case "city_id":
        setdriverview({
          ...driverview,
          [name]: value,
        });
        break;
      case "address":
        setdriverview({
          ...driverview,
          [name]: value,
        });
        break;
      case "contact_number":
        setdriverview({
          ...driverview,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      case "gst_number":
        setdriverview({
          ...driverview,
          [name]: value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 15),
        });
        break;
      default:
        setdriverview({
          ...driverview,
          [name]: value.replace(/[^a-zA-Z0-9@\#$.,\s]/g, ""),
        });

        break;
    }
  };

  // .......................validate.......................

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    const gstRegex = /^[a-zA-Z0-9]{15}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "First Name is required !";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required !";
    }

    if (!values.email) {
      errors.email = "Email ID is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }
    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }

    if (!values.company_name) {
      errors.company_name = "Company Name is required !";
    }

    if (!values.address) {
      errors.address = "Address is required !";
    }

    if (!values.contact_number) {
      errors.contact_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.contact_number)) {
      errors.contact_number = "Phone Number must be 10 digits !";
    }

    if (!values.gst_number) {
      errors.gst_number = "GST Number is required !";
    } else if (!gstRegex.test(values.gst_number)) {
      errors.gst_number = "GST Number must be 15 alphanumeric characters !";
    }

    if (!gstdoc) {
      errors.gstdoc = "GST Document is required !";
    }

    if (!pandoc) {
      errors.pandoc = "PAN Document is required !";
    }

    if (!aadhaardoc) {
      errors.aadhaardoc = "Aadhaar Document is required !";
    }

    if (!selectedValue) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  // ..........................edit customer api...............................
  useEffect(() => {
    const getCustomer = async () => {
      const res = await axios.get(`/admin/customer-show/${customer_show}`);
      if (res.data.status === true) {
        setdriverview(res.data.customer);
      }
    };

    try {
      getCustomer();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  // ..........................submit.................................

  const submitcustomer = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(driverview);
    setErrors(errors);
    setIsSubmit(true);
    if (
      driverview.password &&
      driverview.password.length > 0 &&
      driverview.password !== driverview.password_confirmation
    ) {
      swal("Passwords Miss match", "warning");
    } else {
      const formData = new FormData();
      formData.append("first_name", driverview.first_name);
      formData.append("last_name", driverview.last_name);
      formData.append("userid", driverview.id);
      formData.append("email", driverview.email);
      formData.append("address", driverview.address);
      formData.append("gst_number", driverview.gst_number);
      formData.append("company_name", driverview.company_name);
      formData.append("contact_number", driverview.contact_number);
      formData.append("status", selectedValue);
      formData.append("password", driverview.password);
      formData.append(
        "password_confirmation",
        driverview.password_confirmation
      );
      if (aadhaardoc != "") {
        formData.append("aadhaar_doc", aadhaardoc);
      }
      if (gstdoc != "") {
        formData.append("gst_doc", gstdoc);
      }
      if (pandoc != "") {
        formData.append("pan_doc", pandoc);
      }
      axios
        .post("/admin/customer-update", formData)
        .then((res) => {
          if (res.data.status === true) {
            setdriverview(res.data.customer);
            swal("Updated", res.data.message, "success");
            history.push("/admin/customer/customerlist");
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch(() => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ......................isSubmit...........................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  // ....................Documnet show.............................

  const getPreviewpan = () => {
    if (driverview.pan_doc != "") {
      return (
        <div className="pdt_child">
          <p>
            <a href={`${url}${driverview.pan_doc}`} target="_blank">
              <PictureAsPdfIcon />
            </a>
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  const getPreviewaadhaar = () => {
    if (driverview.aadhaar_doc != "") {
      return (
        <div className="pdt_child">
          <a href={`${url}${driverview.aadhaar_doc}`} target="_blank">
            <PictureAsPdfIcon />
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const getPreviewgst = () => {
    if (driverview.gst_doc != "") {
      return (
        <div className="pdt_child">
          <p>
            <a href={`${url}${driverview.gst_doc}`} target="_blank">
              <PictureAsPdfIcon />
            </a>
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Customer Update</span>

          <Link
            to="/admin/customer/customerlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form novalidate>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  First Name <span className="star_icon">*</span>
                </label>
                <input
                  name="first_name"
                  placeholder="First Name"
                  value={driverview.first_name}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.first_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Last Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  onChange={handleInput}
                  value={driverview.last_name}
                  className="form-control"
                />
                <p>{errors.last_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Company Name <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Company Name"
                  name="Company_name"
                  value={driverview.company_name}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.company_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Email ID <span className="star_icon">*</span>
                </label>
                <input
                  name="email"
                  placeholder="Email ID"
                  value={driverview.email}
                  onChange={handleInput}
                  className="form-control"
                  disabled
                />
                <p>{errors.email}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Phone Number <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Phone Number"
                  name="contact_number"
                  value={driverview.contact_number}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.contact_number}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Address <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="address"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={driverview.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  GST Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  name="gst_number"
                  placeholder="GST Number"
                  value={driverview.gst_number}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.gst_number}</p>{" "}
              </div>
              <div className="col-md-6 form-group mb-3 pdf">
                <label>GST Doument</label>
                <input
                  type="file"
                  placeholder="GST Doument"
                  name="gst_doc"
                  onChange={gstdocChange}
                  className="form-control"
                />
                {getPreviewgst()}
              </div>
              <div className="col-md-6 form-group mb-3 pdf">
                <label>PAN Document</label>
                <input
                  type="file"
                  placeholder="PAN Doument"
                  name="pan_doc"
                  onChange={pandocChange}
                  className="form-control"
                />
                {getPreviewpan()}
              </div>
              <div className="col-md-6 form-group mb-3 pdf">
                <label>Aadhaar Doument</label>
                <input
                  type="file"
                  placeholder="Aadhaar Doument"
                  name="aadhaar_doc"
                  onChange={aadhaardocChange}
                  className="form-control"
                />
                {getPreviewaadhaar()}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Password</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleInput}
                    value={driverview.password}
                    name="password"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowPassword}
                    className="password-toggle-btn"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Confirm Password</label>
                <div className="password-input">
                  <input
                    type={comfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleInput}
                    value={driverview.password_confirmation}
                    name="password_confirmation"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowcomfirmPassword}
                    className="password-toggle-btn"
                  >
                    {comfirmPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
              </div>
              <div className="col-md-6 form-group mt-3 mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === 1}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "true" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === 0}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "false" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  name="userid"
                  value={driverview.id}
                  onChange={handleInput}
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <button
                  onClick={submitcustomer}
                  className="btn btn-secondary float-end px-5 p-2"
                >
                  {isButtonDisabled ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Customerupdate;
