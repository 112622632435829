import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Radio from "@mui/material/Radio";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ErrorMessage } from "../../Customalert/Customalert";

const Customercreate = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [adddriver, setadddriver] = useState({
    first_name: "",
    last_name: "",
    address: "",
    contact_number: "",
    email: "",
    password: "",
    company_name: "",
    gst_doc: "",
    status: "",
    aadhaar_doc: "",
    gst_number: "",
    pan_doc: "",
  });

  // ..................show Password ........................
  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ....................Document................................

  const [gstdoc, setgstdoc] = useState("");
  const [pandoc, setpandoc] = useState("");
  const [aadhaardoc, setaadhaardoc] = useState("");

  function gstdocChange(event) {
    setgstdoc(event.target.files[0]);
  }

  function pandocChange(event) {
    setpandoc(event.target.files[0]);
  }

  function aadhaardocChange(event) {
    setaadhaardoc(event.target.files[0]);
  }

  // ...........................Selecte ............................

  const [selectedValue, setSelectedValue] = React.useState("1");

  const handleChangee = (event) => {
    setSelectedValue(event.target.value);
  };

  // .....................handleInput........................
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setadddriver({
          ...adddriver,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
      case "state_id":
      case "city_id":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "address":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "contact_number":
        setadddriver({
          ...adddriver,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      case "gst_number":
        setadddriver({
          ...adddriver,
          [name]: value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 15),
        });
        break;
      default:
        setadddriver({
          ...adddriver,
          [name]: value.replace(/[^a-zA-Z0-9@\#$.,\s]/g, ""),
        });

        break;
    }
  };

  // ............................validate................................

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    const gstRegex = /^[a-zA-Z0-9]{15}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "First Name is required !";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required !";
    }

    if (!values.email) {
      errors.email = "Email ID is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }

    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }

    if (!values.company_name) {
      errors.company_name = "Company Name is required !";
    }

    if (!values.address) {
      errors.address = "Address is required !";
    }

    if (!values.contact_number) {
      errors.contact_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.contact_number)) {
      errors.contact_number = "Phone Number must be 10 digits !";
    }

    if (!values.gst_number) {
      errors.gst_number = "GST Number is required !";
    } else if (!gstRegex.test(values.gst_number)) {
      errors.gst_number = "GST Number must be 15 alphanumeric characters !";
    }

    if (!gstdoc) {
      errors.gstdoc = "GST Document is required !";
    }

    if (!pandoc) {
      errors.pandoc = "PAN Document is required !";
    }

    if (!aadhaardoc) {
      errors.aadhaardoc = "Aadhaar Document is required !";
    }

    if (!selectedValue) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  // ..........................submit................................
  const submitdriver = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(adddriver);
    setErrors(errors);
    setIsSubmit(true);
    const formData = new FormData();
    formData.append("first_name", adddriver.first_name);
    formData.append("address", adddriver.address);
    formData.append("last_name", adddriver.last_name);
    formData.append("email", adddriver.email);
    formData.append("company_name", adddriver.company_name);
    formData.append("contact_number", adddriver.contact_number);
    formData.append("status", selectedValue);
    formData.append("password", adddriver.password);
    formData.append("password_confirmation", adddriver.password_confirmation);
    formData.append("gst_number", adddriver.gst_number);
    formData.append("gst_doc", gstdoc);
    if (gstdoc !== "") {
      formData.append("gst_doc", gstdoc.name);
    }
    formData.append("aadhaar_doc", aadhaardoc);
    if (aadhaardoc !== "") {
      formData.append("aadhaar_doc", aadhaardoc.name);
    }
    formData.append("pan_doc", pandoc);
    if (pandoc !== "") {
      formData.append("pan_doc", pandoc.name);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`/admin/customer-create`, formData, config)
      .then((res) => {
        if (res.data.status === true) {
          setadddriver(res.data.user);
          swal("Created", res.data.message, "success");
          history.push("/admin/customer/customerlist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ......................isSubmit........................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span"> Add New Customer</span>

          <Link
            to="/admin/customer/customerlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form novalidate autoComplete="off">
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  First Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  onChange={handleInput}
                  value={adddriver.first_name}
                  className="form-control"
                />
                <p>{errors.first_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Last Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  onChange={handleInput}
                  value={adddriver.last_name}
                  className="form-control"
                />
                <p>{errors.last_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Company Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Company Name"
                  name="company_name"
                  onChange={handleInput}
                  value={adddriver.company_name}
                  className="form-control"
                />
                <p>{errors.company_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Phone Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="contact_number"
                  onChange={handleInput}
                  value={adddriver.contact_number}
                  className="form-control"
                />
                {errors.contact_number && <p>{errors.contact_number}</p>}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Address <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={adddriver.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  GST Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  name="gst_number"
                  placeholder=" GST Number"
                  value={adddriver.gst_number}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.gst_number}</p>{" "}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  GST Doument <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="GST Doument"
                  name="gst_doc"
                  onChange={gstdocChange}
                  className="form-control"
                />
                <p>{errors.gstdoc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  PAN Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="PAN Doument"
                  name="pan_doc"
                  onChange={pandocChange}
                  className="form-control"
                />
                <p>{errors.pandoc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Aadhaar Doument <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="Aadhaar Doument"
                  name="aadhaar_doc"
                  onChange={aadhaardocChange}
                  className="form-control"
                />
                <p>{errors.aadhaardoc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Email ID <span className="star_icon">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Email ID"
                  name="email"
                  onChange={handleInput}
                  value={adddriver.email}
                  className="form-control"
                />
                <p>{errors.email}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Password <span className="star_icon">*</span>
                </label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                    onChange={handleInput}
                    value={adddriver.password}
                    name="password"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowPassword}
                    className="password-toggle-btn"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                <p>{errors.password}</p>
              </div>

              <div className="col-md-6 form-group mb-3">
                <label>
                  Confirm Password <span className="star_icon">*</span>
                </label>
                <div className="password-input">
                  <input
                    type={comfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleInput}
                    value={adddriver.password_confirmation}
                    name="password_confirmation"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowcomfirmPassword}
                    className="password-toggle-btn "
                  >
                    {comfirmPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                <p>{errors.password_confirmation}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === "1"}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "1" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === "0"}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "0" }}
                    />
                    In-active
                  </div>
                </div>
                <p>{errors.status}</p>
              </div>
              <div className="col-md-12">
                <button
                  onClick={submitdriver}
                  disabled={isButtonDisabled}
                  className="btn btn-secondary float-end px-5 p-2"
                >
                  {isButtonDisabled ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Customercreate;
