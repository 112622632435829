import axios from "axios";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import swal from "sweetalert";
import "../../../Components/admin/admincard.css";
import "../../admin/Driver/driver.scss";
import { Link } from "react-router-dom";
import edit from "../../../assets/admin/assets/img/edit.svg";
import "../../admin/Driver/driver.scss";
import { useAlert } from "react-alert";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import { ErrorMessage } from "../../Customalert/Customalert";

const Import = () => {
  const [importlist, setimportlist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  // ......................TablePagination..............................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ................................Table Sort..........................................

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...importlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [importlist, sort]);

  // ........................Table search....................................
  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.order_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.container_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vehicle_capacity
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.booking_status_id
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vendor_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.order_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.payment_status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // .........................import order apai get..........................

  useEffect(() => {
    const fetchimportData = async () => {
      try {
        const res = await axios.get("/admin/all-import-order-list");
        if (res.data.status === true) {
          setimportlist(res.data.ImportOrderList);
          setLoading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };
    fetchimportData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .........................import_list..............................

  let import_list = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    import_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>
                  <div className="pro-name">
                    <span>{item.order_id}/</span>
                    <br></br>
                    {item.type} -{item.vehicle_capacity}
                  </div>
                </td>
                <td>
                  <div className="pro-name">
                    <span> {item.customer_first_name}/</span>
                    {item.customer_last_name}/{item.customer_company_name}
                  </div>
                </td>
                <td>{item.vendor_first_name}</td>
                <td>
                  <div className="pro-name">
                    {item.driver_first_name}
                    {item.driver_first_name == "" ? (
                      <></>
                    ) : (
                      <>
                        / <span>{item.driver_last_name}</span>
                      </>
                    )}
                  </div>
                </td>
                <td>{item.vehicle_number}</td>
                <td>{item.container_number}</td>
                <td>{item.loading}</td>
                <td>{item.unloading}</td>
                <td>{item.order_amount}</td>
                <td>{item.payment_status}</td>
                <td>{item.order_date}</td>
                <td>{item.booking_status_id}</td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }
  const TotalCount = filteredData.reduce((total, item) => {
    return total + item.order_amount;
  }, 0);

  return (
    <>
      <div className="container-fluid px-4 Driver">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span">Import List</span>
            <Link
              to="/admin/dashboard"
              className="btn btn-primary admin float-end"
            >
              Go Back
            </Link>
          </h4>
        </div>
        <div className="card mt-4 mb-4  py-4">
          <div className="search-box px-4 cust-search px-3">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="card-body px-3 mt-3 mb-3">
            <div class="table-responsive">
              <table className="table table-bordered table-striped border-start border-end">
                <thead>
                  <tr className="head_color">
                    <th>S No</th>
                    <th className="Customer_table">
                      Job Number
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("order_id")}
                      />
                    </th>
                    <th className="emails Customer_table">
                      Customer Details
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("customer_first_name")}
                      />
                    </th>
                    <th className="Customer_table">
                      Vendor Name
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("vendor_first_name")}
                      />
                    </th>
                    <th className="Customer_table">
                      Driver Name
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("driver_first_name")}
                      />
                    </th>
                    <th className="Customer_table">
                      Vehicle Number
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("vehicle_number")}
                      />
                    </th>
                    <th className="Customer_table">
                      Container Number
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("container_number")}
                      />
                    </th>
                    <th className="Customer_table">
                      Loading Point
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("loading")}
                      />
                    </th>
                    <th className="Customer_table">
                      Unloading Point
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("unloading")}
                      />
                    </th>
                    <th className="Customer_table">
                      Order Amount <p>({TotalCount})</p>
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("order_amount")}
                      />
                    </th>
                    <th className="emails Customer_table">
                      Payment Status
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("payment_status")}
                      />
                    </th>
                    <th className="emails Customer_table">
                      Booking Date
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("order_date")}
                      />
                    </th>
                    <th className="emails Customer_table">
                      Booking Status
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("booking_status_id")}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>{import_list}</tbody>
              </table>
            </div>
            {sortedData.length > 5 ? (
              <>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default Import;
