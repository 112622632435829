import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import axios from "axios";
import "../../../Components/admin/Driver/driver.scss";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";

const Import = () => {
  const [fromValues, setFromValues] = useState(null);
  const [toValues, setToValues] = useState(null);
  // ...........................
  const [loading, setloading] = useState(true);
  const [driverlist, setdriverlist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDateChange = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    setFromValues(formattedDate);
  };

  const handleDateChanges = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    setToValues(formattedDate);
  };

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...driverlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [driverlist, sort]);

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.order_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.loading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.booking_status_id.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  useEffect(() => {
    axios.get(`/vendor/all-vendor-list`).then((res) => {
      if (res.data.status === true) {
        setdriverlist(res.data.VendorOrderList);
        setloading(false);
      }
    });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  const submitReports = (e) => {};

  let vendor_list = "";

  if (loading) {
  } else {
    vendor_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.order_id}</td>
                <td>{item.driver_first_name}</td>
                <td>{item.loading}</td>
                <td>{item.unloading}</td>
                <td>{item.vehicle_number}</td>
                <th>{item.order_date}</th>
                <td>{item.booking_status_id}</td>
              </tr>
            );
          })
      ) : (
        <tr>
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }
  return (
    <div className="container-fluid px-4 mb-4">
      <div className="title px-4 mt-4 ">
        <h4>
          <h4>Import List</h4>
        </h4>
      </div>
      <div className="card mt-4">
        <form>
          <div className="row filter mt-3 py-3 px-5">
            <div className="col-md-3">
              <label>Select From</label>
              <input
                type="date"
                name="Select From"
                value={fromValues}
                onChange={(e) => handleDateChange(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Select To</label>
              <input
                type="date"
                name="Select To"
                value={toValues}
                onChange={(e) => handleDateChanges(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <FormControl fullWidth>
                <div class="d-grid gap-2 d-md-block  mt-3 mb-2 Order_bnt">
                  <button onClick={submitReports} class="btn btn-success ">
                    Submit
                  </button>
                </div>
              </FormControl>
            </div>
          </div>
        </form>
        <div className="row px-5 p-0 mt-3">
          <div className="titile  mb-4 mt-4">
            <h4>Order List</h4>
          </div>
          <div className="card-body px-4">
            <div className="card">
              <div className="card-body  px-4">
                <div className="search-box py-4 cust-search px-3 mb-2">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped border-start border-end">
                    <thead>
                      <tr className="head_color">
                        <th className="Customer_table">S No</th>
                        <th className="Customer_table">
                          Vendor Id{" "}
                          <ImportExportIcon
                            className="Customer_table_child"
                            onClick={() => handleSort("order_id")}
                          />
                        </th>
                        <th className="Customer_table">
                          Driver Name{" "}
                          <ImportExportIcon
                            className="Customer_table_child"
                            onClick={() => handleSort("order_id")}
                          />
                        </th>
                        <th className="Customer_table">
                          Loading{" "}
                          <ImportExportIcon
                            className="Customer_table_child"
                            onClick={() => handleSort("order_id")}
                          />
                        </th>
                        <th className="Customer_table">
                          Unloading{" "}
                          <ImportExportIcon
                            className="Customer_table_child"
                            onClick={() => handleSort("order_id")}
                          />
                        </th>
                        <th className="Customer_table">
                          Vehicle Number{" "}
                          <ImportExportIcon
                            className="Customer_table_child"
                            onClick={() => handleSort("order_id")}
                          />
                        </th>
                        <th className="Customer_table">
                          Booking Date{" "}
                          <ImportExportIcon
                            className="Customer_table_child"
                            onClick={() => handleSort("order_id")}
                          />
                        </th>
                        <th className="Customer_table">
                          Booking Status Id{" "}
                          <ImportExportIcon
                            className="Customer_table_child"
                            onClick={() => handleSort("order_id")}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>{vendor_list}</tbody>
                  </table>
                </div>
                {sortedData.length > 1 ? (
                  <>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      count={filteredData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Import;
