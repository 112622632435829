import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import eye from "../../../assets/admin/assets/img/eye.svg";
import edit from "../../../assets/admin/assets/img/edit.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import { ErrorMessage } from "../../Customalert/Customalert";

const AdminList = () => {
  const [loading, setloading] = useState(true);
  const [adminlist, setadminlist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // .................TablePagination ........................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // .....................Table sort.............................

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...adminlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [adminlist, sort]);

  // .......................Table search.....................................

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.mobileno.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // .......................user-list api get...........................

  useEffect(() => {
    const fetchuserData = async () => {
      try {
        var res = await axios.get("/admin/user-list");
        if (res.data.status === true) {
          setadminlist(res.data.userlist);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };
    fetchuserData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .........................admin_user list...............................
  let admin_list = "";

  if (loading) {
    return (
      <Player
        autoplayuserPerpage
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    admin_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.name}</td>
                <td className="emails">{item.email}</td>
                <td>{item.mobileno}</td>
                <td>{item.status}</td>
                <td>
                  <div className="actions">
                    <Link to={`/admin/adminlist/admin-view/${item.id}`}>
                      <img src={eye} width="35px"></img>
                    </Link>
                    <Link to={`/admin/adminlist/admin-update/${item.id}`}>
                      <img src={edit} width="35px"></img>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr>
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  return (
    <div className="container-fluid px-4 Driver">
      <div className="title px-1 mt-4 ">
        <h4>
          <span className="titile_span">Admin List</span>
          <Link
            to="/admin/adminlist/admin-add"
            className="btn btn-primary admin float-end"
          >
            Add Admin
          </Link>
        </h4>
      </div>

      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="card-body px-4">
          <div class="table-responsive">
            <table class="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th>S No</th>
                  <th className="emails Customer_table">
                    Admin Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("name")}
                    />
                  </th>
                  <th className="emails Customer_table">
                    Email ID
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("email")}
                    />
                  </th>
                  <th className="Customer_table">
                    Phone Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("mobileno")}
                    />
                  </th>
                  <th className="Customer_table">
                    Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("status")}
                    />
                  </th>
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{admin_list}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdminList;
