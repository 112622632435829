import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useAlert } from "react-alert";
import Radio from "@mui/material/Radio";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import swal from "sweetalert";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const Vechileupdate = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [city, setcity] = useState([]);
  const alert = useAlert();
  const [vehicledoc, setvehicledoc] = useState([]);
  const { truckcapacity, trucktype, state } = useContext(UserContext);
  const history = useHistory();
  const [updatedriver, setupdatedriver] = useState([]);
  const [errors, setErrors] = useState({});
  const [vechileview, setvechileview] = useState({
    vehicle_manufacture: "",
    truck_type_id: "",
    truck_capacity_id: "",
    vehicle_number: "",
    vendor_id: "",
    insurance: "",
    driver_id: "",
    rcbook: "",
    localpermit: "",
    fc: "",
    roadtax: "",
    polution: "",
    nationalpermit: "",
    city_id: "",
    state_id: "",
    make_year: "",
    status: "",
    driver_id: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ....................selectedValue............................
  const [selectedValue, setSelectedValue] = React.useState();

  const handleChangee = (event) => {
    setSelectedValue(parseInt(event.target.value));
    if (selectedValue === 1) {
      return "true";
    } else {
      return "false";
    }
  };

  useEffect(() => {
    setSelectedValue(vechileview.status);
  }, [vechileview]);

  // ........file........
  const [insurancedoc, setinsurancedoc] = useState("");
  const [rcbookdoc, setrcbookdoc] = useState("");
  const [localpermit, setlocalpermit] = useState("");
  const [roadtax, setroadtax] = useState("");
  const [nationalpermit, setnationalpermit] = useState("");
  const [polutiondoc, setpolutiondoc] = useState("");
  const [fcdoc, setfc] = useState("");

  function insurancedocChange(event) {
    setinsurancedoc(event.target.files[0]);
  }

  function rcbookChange(event) {
    setrcbookdoc(event.target.files[0]);
  }

  function localChange(event) {
    setlocalpermit(event.target.files[0]);
  }

  function setroadtaxChange(event) {
    setroadtax(event.target.files[0]);
  }

  function setnationalchange(event) {
    setnationalpermit(event.target.files[0]);
  }

  function setpolutionChange(event) {
    setpolutiondoc(event.target.files[0]);
  }

  function setfcChange(event) {
    setfc(event.target.files[0]);
  }

  // .....................handleInput........................

  const currentDate = new Date();
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1
  );

  const mindate = minDate.toISOString().split("T")[0];

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setvechileview({
          ...vechileview,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
        setvechileview({
          ...vechileview,
          [name]: value,
        });
        break;
      case "state_id":
      case "city_id":
      case "driver_id":
      case "truck_capacity_id":
      case "truck_type_id":
      case "fc_exp":
      case "insurance_exp":
        setvechileview({
          ...vechileview,
          [name]: value,
        });
        break;
      case "make_year":
        setvechileview({
          ...vechileview,
          [name]: value.replace(/\D/g, "").slice(0, 4),
        });
        break;
      case "vehicle_number":
        setvechileview({
          ...vechileview,
          [name]: value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 13),
        });
        break;
      default:
        setvechileview({
          ...vechileview,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // .........................validate..........................

  const validate = (values) => {
    const errors = {};
    const Year = /^\d{4}$/;
    const vec = /^[a-zA-Z0-9]{10}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.vendor_id) {
      errors.vendor_id = "Vendor Name is required !";
    }

    if (!values.driver_id) {
      errors.driver_id = "Driver Name is required !";
    }

    if (!values.email) {
      errors.email = "Email ID is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }

    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password Confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }

    if (!values.company_name) {
      errors.company_name = "Company Name is required !";
    }

    if (!values.make_year) {
      errors.make_year = "Make Year is required !";
    } else if (!Year.test(values.make_year)) {
      errors.make_year = "Make Year Must be 4 digits !";
    }

    if (!values.vehicle_number) {
      errors.vehicle_number = "Vehicle Number is required !";
    } else if (!vec.test(values.vehicle_number)) {
      errors.vehicle_number =
        "Vehicle Number must be 10 alphanumeric characters !";
    }

    if (!values.truck_type_id) {
      errors.truck_type_id = "Vehicle Type is required !";
    }
    if (!values.vehicle_manufacture) {
      errors.vehicle_manufacture = "Vehicle Manufacturer is required !";
    }

    if (!values.truck_capacity_id) {
      errors.truck_capacity_id = "Vehicle Size is required !";
    }
    if (!values.insurance_exp) {
      errors.insurance_exp = "Insurance Expiry is date required !";
    }
    if (!values.fc_exp) {
      errors.fc_exp = "Fc Expiry date is required !";
    }

    if (!insurancedoc) {
      errors.insurancedoc = "Insurance Document is required !";
    }
    if (!fcdoc) {
      errors.fcdoc = "FC Document is required !";
    }
    if (!rcbookdoc) {
      errors.rcbookdoc = "Rc Book Document is required !";
    }
    if (!localpermit) {
      errors.localpermit = "Local Permit Document is required !";
    }

    if (!roadtax) {
      errors.roadtax = "Road Tax Document is required !";
    }
    if (!nationalpermit) {
      errors.nationalpermit = "National Permit Document is required !";
    }
    if (!polutiondoc) {
      errors.polutiondoc = "Pollution Certificate Document is required !";
    }

    if (!values.city_id) {
      errors.city_id = "City is required !";
    }

    if (!values.state_id) {
      errors.state_id = "State is required !";
    }

    if (!selectedValue) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  const submitvendor = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(vechileview);
    setErrors(errors);
    const formData = new FormData();
    formData.append("driver_id", vechileview.driver_id);
    formData.append("vehicle_manufacture", vechileview.vehicle_manufacture);
    formData.append("vehicle_number", vechileview.vehicle_number);
    formData.append("truck_type_id", vechileview.truck_type_id);
    formData.append("truck_capacity_id", vechileview.truck_capacity_id);
    formData.append("city_id", vechileview.city_id);
    formData.append("state_id", vechileview.state_id);
    formData.append("id", vechileview.id);
    formData.append("status", selectedValue);
    formData.append("make_year", vechileview.make_year);
    formData.append("insurance_exc", vechileview.insurance_exp);
    formData.append("fc_exc", vechileview.fc_exp);
    // Add insurance document to form data if it is not empty
    if (insurancedoc !== "") {
      formData.append("insurance", insurancedoc.name);
    }

    // Add other document names to form data if they are not empty
    if (rcbookdoc !== "") {
      formData.append("rcbook", rcbookdoc.name);
    }
    if (localpermit !== "") {
      formData.append("localpermit", localpermit.name);
    }
    if (roadtax !== "") {
      formData.append("roadtax", roadtax.name);
    }
    if (nationalpermit !== "") {
      formData.append("nationalpermit", nationalpermit.name);
    }
    if (polutiondoc !== "") {
      formData.append("polution", polutiondoc.name);
    }
    if (fcdoc !== "") {
      formData.append("fc", fcdoc.name);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`vendor/vehicle-update`, formData, config)
      .then((res) => {
        if (res.data.status === true) {
          setvechileview(res.data.vehicle);
          swal("Updated", res.data.message, "success");
          history.push("/vendor/vehiclelist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // .........................vechile_show get api.............................
  useEffect(() => {
    const vechile_show = props.match.params.id;
    axios
      .get(`/vendor/vendor-vehicle-show/${vechile_show}`)
      .then((res) => {
        if (res.data.status === true) {
          setvechileview(res.data.vehicle);
          setvehicledoc(res.data.documentdetails);
        }
      })
      .catch((error) => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  useEffect(() => {
    const vechile_id = props.match.params.id;
    axios
      .get(`/update-driver-list/${vechile_id}`)
      .then((res) => {
        if (res.data.status === true) {
          setupdatedriver(res.data.driver);
        }
      })
      .catch((error) => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  // ..........city list get api ...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.status === false) {
          swal("Error", data.status, "error");
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4 ">
        <h4>
          <span className="titile_span">Update Vehicle</span>
          <Link
            to="/vendor/vehiclelist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            {vechileview.driver_name_status == 1 ? (
              <>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Driver Name <span className="star_icon">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="driver_id"
                    value={vechileview.driver_id}
                    onChange={handleInput}
                    disabled
                  >
                    <option>
                      {vechileview.driver_first_name}
                      {vechileview.driver_last_name}
                    </option>
                  </select>
                  <p>{errors.driver_id}</p>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Driver Name <span className="star_icon">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="driver_id"
                    value={vechileview.driver_id}
                    onChange={handleInput}
                  >
                    {updatedriver.length === 0 ? (
                      <option value="">--No Driver Found--</option>
                    ) : (
                      <>
                        <option value="">--Select Driver--</option>
                        {updatedriver.map((itm) => (
                          <option value={itm.id} key={itm.id}>
                            {itm.first_name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  <p>{errors.driver_id}</p>
                </div>
              </>
            )}
            <div className="col-md-6 form-group mb-3">
              <label>
                Vehicle Manufacturer <span className="star_icon">*</span>
              </label>
              <input
                type="text"
                name="vehicle_manufacture"
                placeholder="Vehicle Manufacturer"
                onChange={handleInput}
                value={vechileview.vehicle_manufacture}
                className="form-control"
              />
              <p>{errors.vehicle_manufacture}</p>
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>
                Vehicle Number <span className="star_icon">*</span>
              </label>
              <input
                type="text"
                name="vehicle_number"
                placeholder="Vehicle Number"
                onChange={handleInput}
                value={vechileview.vehicle_number}
                className="form-control"
              />
              <p>{errors.vehicle_number}</p>
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>
                Vehicle Type <span className="star_icon">*</span>
              </label>
              <select
                className="form-control"
                name="truck_type_id"
                onChange={handleInput}
                value={vechileview.truck_type_id}
              >
                <option value="">--Select Vehicle Type--</option>
                {trucktype.map((itm) => {
                  return (
                    <option value={itm.id} key={itm.id}>
                      {itm.type_name}
                    </option>
                  );
                })}
              </select>
              <p>{errors.truck_type_id}</p>
            </div>

            <div className="col-md-6 form-group mb-3">
              <label>
                Vehicle Size <span className="star_icon">*</span>
              </label>
              <select
                className="form-control"
                name="truck_capacity_id"
                onChange={handleInput}
                value={vechileview.truck_capacity_id}
              >
                <option value="">--Select Vehicle Size--</option>
                {truckcapacity.map((itm) => {
                  return (
                    <option value={itm.id} key={itm.id}>
                      {itm.capactiy_name}
                    </option>
                  );
                })}
              </select>
              <p>{errors.truck_capacity_id}</p>
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>
                State <span className="star_icon">*</span>
              </label>
              <select
                className="form-control"
                name="state_id"
                onChange={handleInput}
                value={vechileview.state_id}
              >
                <option value="">--Select State--</option>
                {state.map((itm) => {
                  return (
                    <option value={itm.id} key={itm.id}>
                      {itm.name}
                    </option>
                  );
                })}
              </select>
              <p>{errors.state_id}</p>
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>
                City <span className="star_icon">*</span>
              </label>
              <select
                className="form-control"
                name="city_id"
                onChange={handleInput}
                value={vechileview.city_id}
              >
                <option value="">--Select City--</option>
                {city.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <p>{errors.city_id}</p>
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>
                Make Year <span className="star_icon">*</span>
              </label>
              <input
                type="text"
                name="make_year"
                placeholder="Make Year"
                value={vechileview.make_year}
                onChange={handleInput}
                className="form-control"
              />
              <p>{errors.make_year}</p>
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>Insurance Expiry date</label>
              <input
                type="date"
                name="insurance_exp"
                value={vechileview.insurance_exp}
                onChange={handleInput}
                className="form-control"
                min={mindate}
              />
              {/* <p>{errors.insurance_exp}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>Fc Expiry date</label>
              <input
                type="date"
                name="fc_exp"
                value={vechileview.fc_exp}
                onChange={handleInput}
                className="form-control"
                min={mindate}
              />
              {/* <p>{errors.fc_exp}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>Insurance Document</label>
              <input
                type="file"
                name="insurance"
                onChange={insurancedocChange}
                className="form-control"
              />
              {/* <p>{errors.insurancedoc}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>RC Book Document</label>
              <input
                type="file"
                name="rcbook"
                onChange={rcbookChange}
                className="form-control"
              />
              {/* <p>{errors.rcbookdoc}</p> */}
            </div>

            <div className="col-md-6 form-group mb-3">
              <label>Local Permit Document</label>
              <input
                type="file"
                name="localpermit"
                onChange={localChange}
                className="form-control"
              />
              {/* <p>{errors.localpermit}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>FC Document</label>
              <input
                type="file"
                name="fc"
                onChange={setfcChange}
                className="form-control"
              />
              {/* <p>{errors.fcdoc}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>Road Tax Document</label>
              <input
                type="file"
                name="roadtax"
                onChange={setroadtaxChange}
                className="form-control"
              />
              {/* <p>{errors.roadtax}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>Pollution Certificate Document</label>
              <input
                type="file"
                name="polution"
                onChange={setpolutionChange}
                className="form-control"
              />
              {/* <p>{errors.polutiondoc}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>National permit Document</label>
              <input
                type="file"
                name="nationalpermit"
                onChange={setnationalchange}
                className="form-control"
              />
              {/* <p>{errors.nationalpermit}</p> */}
            </div>
            <div className="col-md-6 form-group mb-3">
              <label>
                Status <span className="star_icon">*</span>
              </label>
              <div className="row">
                <div className="col-lg-3">
                  <Radio
                    checked={selectedValue === 1}
                    onChange={handleChangee}
                    value="1"
                    name="status"
                    inputProps={{ "aria-label": "true" }}
                  />
                  Active
                </div>
                <div className="col">
                  <Radio
                    checked={selectedValue === 0}
                    onChange={handleChangee}
                    value="0"
                    name="status"
                    inputProps={{ "aria-label": "false" }}
                  />
                  In-active
                </div>
              </div>
            </div>
            <div className="row  mt-3">
              <div className="col-md-12 form-group mb-4">
                <div class="table-responsive">
                  <table className="table table-striped border-start border-end">
                    <thead>
                      <tr className="head_color">
                        <th>Document Name</th>
                        <th>Document</th>
                        <th>Document Expiry</th>
                        <th>Updated Date</th>
                        <th>Document Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicledoc.length > 0 ? (
                        vehicledoc.map((item) => {
                          return (
                            <tr>
                              <td>{item.document_type_name}</td>
                              <td key={item.id}>
                                <a
                                  href={`${url}${item.document_path}`}
                                  target="_blank"
                                >
                                  <PictureAsPdfOutlinedIcon />
                                </a>{" "}
                              </td>
                              <td>{item.certificate_to}</td>
                              <td>{item.updated_at}</td>
                              <td>{item.documents}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <h6 className="px-4 mt-3">No Record Found</h6>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <button
                type="button"
                className="btn btn-secondary float-end px-5 p-2"
                onClick={submitvendor}
                disabled={isButtonDisabled}
              >
                {isButtonDisabled ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vechileupdate;
