import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useAlert } from "react-alert";
import Radio from "@mui/material/Radio";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Routecreate = () => {
  const alert = useAlert();
  const { trucktype, truckcapacity } = useContext(UserContext);
  const [loadingpoint, setloadingpoint] = useState([]);
  const [loadingid, setloadingid] = useState("");
  const [vendorname, setvendorname] = useState([]);
  const [unloading, setunloading] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const [addcustomer, setaddcustomer] = useState({
    // vendor_id: "",
    loading: "",
    unloading: "",
    type: "",
    truck_type_id: "",
    truck_capacity_id: "",
    costing_price: "",
    status: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ...........................................................
  const [selectedimport, setselectedimport] = React.useState("Import");

  const importhandleChange = (event) => {
    setselectedimport(event.target.value);
  };

  // .................selectedValue..............................
  const [selectedValue, setSelectedValue] = React.useState("1");

  const handleChangee = (event) => {
    setSelectedValue(event.target.value);
  };

  // ...................handleInput............................
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "loading":
      case "unloading":
      case "type":
      case "truck_type_id":
      case "truck_capacity_id":
      case "status":
        setaddcustomer({
          ...addcustomer,
          [name]: value,
        });
        break;
      case "costing_price":
        setaddcustomer({
          ...addcustomer,
          [name]: value.replace(/\D/g, ""),
        });
        break;
      default:
        setaddcustomer({
          ...addcustomer,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // .................handleloading...........................

  const handleloading = (e) => {
    e.persist();
    const loadingid = e.target.value;
    setloadingid(loadingid);
    setaddcustomer({
      ...addcustomer,
      [e.target.name]: e.target.value,
    });
  };

  // .........................validate....................................
  const validate = (values) => {
    const errors = {};
    if (!selectedimport) {
      errors.type = "type is required !";
    }
    // if (!values.vendor_id) {
    //   errors.vendor_id = "Vendor Name is required!";
    // }
    if (!values.loading) {
      errors.loading = "Loading Point is required !";
    }
    if (!values.unloading) {
      errors.unloading = "Unloading  Point is required !";
    }
    if (!values.truck_capacity_id) {
      errors.truck_capacity_id = "Container Size is required !";
    }
    if (!values.truck_type_id) {
      errors.truck_type_id = "Vehicle Type is required !";
    }

    if (!values.costing_price) {
      errors.costing_price = "Costing Price is required !";
    }

    if (!values.status) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  // ...................loadinglist api get................
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/loadinglist");
        if (res.data.status === true) {
          setloadingpoint(res.data.warehouse);
        }
      } catch (error) {
        // swal("Error", error.message, "error");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // ...........................vendorlist.....................................

  useEffect(() => {
    axios
      .get(`/vendorlist`)
      .then((res) => {
        if (res.data.status === true) {
          setvendorname(res.data.vendor);
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  // .....................unloadinglist........................

  useEffect(() => {
    if (loadingid) {
      axios.post(`/unloadinglist?loading=${loadingid}`).then((res) => {
        if (res.data.status === true) {
          setunloading(res.data.warehouse);
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      });
      const interval = setInterval(() => {
        axios.post(`/unloadinglist?loading=${loadingid}`).then((res) => {
          if (res.data.status === true) {
            setunloading(res.data.warehouse);
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [loadingid]);

  // .....................submit..................................

  const submitcustomer = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(addcustomer));
    setIsSubmit(true);

    const formdata = new FormData();
    // formdata.append("vendor_id", addcustomer.vendor_id);
    formdata.append("loading", addcustomer.loading);
    formdata.append("unloading", addcustomer.unloading);
    formdata.append("truck_type_id", addcustomer.truck_type_id);
    formdata.append("truck_capacity_id", addcustomer.truck_capacity_id);
    formdata.append("costing_price", addcustomer.costing_price);
    formdata.append("status", selectedValue);
    formdata.append("type", selectedimport);
    axios
      .post(`/admin/route-create`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setaddcustomer(res.data.route);
          swal("Created", res.data.message, "success");
          history.push("/admin/route/routelist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ..............................isSubmit.......................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Route Create</span>
          <Link
            to="/admin/route/routelist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-lg-12 form-group mb-3">
                <label>
                  Type <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-2">
                    <Radio
                      checked={selectedimport === "Import"}
                      onChange={importhandleChange}
                      value="Import"
                      name="type"
                      inputProps={{ "aria-label": "Import" }}
                    />
                    Import
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedimport === "Export"}
                      onChange={importhandleChange}
                      value="Export"
                      name="type"
                      inputProps={{ "aria-label": "Export" }}
                    />
                    Export
                  </div>
                </div>
                <p>{errors.type}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Loading Point <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="loading"
                  onChange={handleloading}
                  value={addcustomer.loading}
                >
                  {loadingpoint.length == 0 ? (
                    <>
                      <option value="">--Select Loading Point--</option>
                    </>
                  ) : (
                    <>
                      <option value="">--Select Loading Point--</option>
                      {loadingpoint.map((itm) => {
                        return (
                          <option value={itm.id} key={itm.id}>
                            {itm.address}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                <p>{errors.loading}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Unloading Point<span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="unloading"
                  onChange={handleInput}
                  value={addcustomer.unloading}
                >
                  {unloading.length == 0 ? (
                    <>
                      <option value="">--Select Unloading point--</option>
                    </>
                  ) : (
                    <>
                      <option value="">--Select Unloading point--</option>
                      {unloading.map((itm) => {
                        return (
                          <option value={itm.id} key={itm.id}>
                            {itm.address}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                <p>{errors.unloading}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vehicle Type <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="truck_type_id"
                  onChange={handleInput}
                  value={addcustomer.truck_type_id}
                >
                  <option value="">--Select Vehicle Type--</option>
                  {trucktype.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.type_name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.truck_type_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Container Size <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="truck_capacity_id"
                  onChange={handleInput}
                  value={addcustomer.truck_capacity_id}
                >
                  <option value="">--Select Container Size--</option>
                  {truckcapacity.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.capactiy_name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.truck_capacity_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Costing Price <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Costing price"
                  name="costing_price"
                  onChange={handleInput}
                  value={addcustomer.costing_price}
                  className="form-control"
                />
                <p>{errors.costing_price}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === "1"}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "1" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === "0"}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "0" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  className="btn btn-secondary float-end px-5 p-2"
                  onClick={submitcustomer}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Routecreate;
