import axios from "axios";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import edit from "../../../assets/admin/assets/img/edit.svg";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import TablePagination from "@mui/material/TablePagination";
import payment_icon from "../../../payment.png";
import date_icon from "../../../date.png";
import Order_img from "../../../Order.png";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";

const Allreport = () => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [reports_res, setreports_res] = useState([]);
  const [unloadList_res, setunloadList_res] = useState([]);
  const [deliveredlist_res, setdeliveredlist_res] = useState([]);
  const [journeylist_res, setjourneylist_res] = useState([]);
  const [Rejectedlist_res, setRejectedlist_res] = useState([]);
  const [Canceledist_res, setCanceledist_res] = useState([]);
  const [adminlist_res, setadminlist_res] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  // ..............reportsList........................
  const [reportsList, setReportsList] = useState([]);
  const [unloadList, setunloadList] = useState([]);
  const [deliveredlist, setdeliveredlist] = useState([]);
  const [journeylist, setjourneylist] = useState([]);
  const [Rejectedlist, setRejectedlist] = useState([]);
  const [Canceledist, setCanceledist] = useState([]);
  const [adminlist, setadminlist] = useState([]);

  // .................dialog box...........
  const [open4, setOpen4] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [admindate, setadmindate] = useState(null);
  const [selectedRow, setSelectedRow] = useState("");
  const [reference, setReference] = useState("");
  const [otherchanges, setotherchanges] = useState([]);
  const [alldocument, setalldocument] = useState([]);

  const handleOpen1 = (item) => {
    setOpen1(true);
    const formData = new FormData();
    formData.append("order_id", item.order_id);
    axios.post(`/admin/order_charge_list`, formData).then((res) => {
      setotherchanges(res.data.order_charge);
    });
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen3 = (item) => {
    setSelectedRow(item);
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen = (item) => {
    setSelectedRow(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen4 = (item) => {
    setOpen4(true);
    const formData = new FormData();
    formData.append("order_id", item.order_id);
    axios.post(`/admin/order_document`, formData).then((res) => {
      setalldocument(res.data.order_document);
    });
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  // ...............pagenation.................
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataunload, setfilteredDataunload] = useState([]);
  const [filteredDataDelivered, setfilteredDataDelivered] = useState([]);
  const [filteredDatajourney, setfilteredDatajourney] = useState([]);
  const [filteredDatarejected, setfilteredDatarejected] = useState([]);
  const [filteredDatacancel, setfilteredDatacancel] = useState([]);
  const [filteredDataadmin, setfilteredDataadmin] = useState([]);

  const [reportdate, setreportdate] = useState({
    fromValues: "",
    toValues: "",
    reports: "",
  });

  const [reportvalues, setreportvalues] = useState({
    ewaynum: "",
    ewaydates: "",
    orderdate: "",
    referencenumber: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //  .....................TablePagination ....................................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ..............................Table sort................................

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...reportsList].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [reportsList, sort]);

  const sortedDataunload = useMemo(() => {
    return [...unloadList].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [unloadList, sort]);

  const sortedDelivered = useMemo(() => {
    return [...deliveredlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [deliveredlist, sort]);

  const sortedjourney = useMemo(() => {
    return [...journeylist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [journeylist, sort]);

  const sortedrejected = useMemo(() => {
    return [...Rejectedlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [Rejectedlist, sort]);

  const sortedDatacancel = useMemo(() => {
    return [...Canceledist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [Canceledist, sort]);

  const sortedDataadmin = useMemo(() => {
    return [...adminlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [adminlist, sort]);

  // ............................ Table search.................................

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.container_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_mobile
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vendor_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vendor_last_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.date_and_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(newData);

    const newDataunload = sortedDataunload.filter(
      (item) =>
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.container_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_mobile
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vendor_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vendor_last_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.eway_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.date_and_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setfilteredDataunload(newDataunload);

    const newDataDelivered = sortedDelivered.filter(
      (item) =>
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.unloading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.delivered_date_and_time
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.total)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.empty_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setfilteredDataDelivered(newDataDelivered);

    const newDatajourney = sortedjourney.filter(
      (item) =>
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.unloading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(item.gst_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.trip_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.total)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.journey_complete_date_time
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setfilteredDatajourney(newDatajourney);

    const newDatarejected = sortedrejected.filter(
      (item) =>
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.unloading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.rejected_date_and_time
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.reject_message.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.reject_by.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(item.gst_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.trip_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.total)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setfilteredDatarejected(newDatarejected);

    const newDatacancel = sortedDatacancel.filter(
      (item) =>
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.canceled_date_and_time
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.cancel_reason.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(item.gst_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.trip_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.total)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setfilteredDatacancel(newDatacancel);

    const newDataadmin = sortedDataadmin.filter(
      (item) =>
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.unloading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_mobile
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.completed_date_and_time
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.empty_return_date
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.empty_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(item.total)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setfilteredDataadmin(newDataadmin);
  }, [
    searchQuery,
    sortedData,
    sortedDataunload,
    sortedDatacancel,
    sortedrejected,
    sortedjourney,
    sortedDelivered,
    sortedDataadmin,
  ]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .............................handlereport...................................

  const [mindate, setMindate] = useState("");

  const handlereport = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "fromValues":
        setreportdate({
          ...reportdate,
          [name]: new Date(value).toISOString().split("T")[0],
        });
        setMindate(new Date(value).toISOString().split("T")[0]);
        break;
      case "toValues":
        setreportdate({
          ...reportdate,
          [name]: new Date(value).toISOString().split("T")[0],
        });
        break;
      default:
        setreportdate({
          ...reportdate,
          [name]: value,
        });
        break;
    }
  };
  //  ........................handleInput................................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "ewaynum":
        setreportvalues({
          ...reportvalues,
          [name]: value,
        });
        break;
      case "ewaydates":
        setreportvalues({
          ...reportvalues,
          [name]: value,
        });
        break;
      case "orderdate":
        setadmindate(value);
        break;
      case "referencenumber":
        setReference(value);
        break;
      case "reports":
        setreportvalues({
          ...reportvalues,
          [name]: value,
        });
        break;
      default:
        setreportvalues({
          ...reportvalues,
          [name]: value,
        });
        break;
    }
  };

  //  ........................validate............................

  const validate = (values) => {
    const errors = {};
    if (!values.fromValues) {
      errors.fromValues = "From Date is required !";
    }
    if (!values.toValues) {
      errors.toValues = "To Date is required !";
    }
    if (!values.reports) {
      errors.reports = "Report Status is required !";
    }
    if (!values.ewaynum) {
      errors.ewaynum = "Eway Number is required !";
    }
    if (!values.ewaydates) {
      errors.ewaydates = "Eway Date  is required !";
    }

    return errors;
  };
  // .................submitReports..........................

  const submitReports = (e) => {
    e.preventDefault();
    const errors = validate(reportdate);
    setErrors(errors);
    setIsSubmit(true);
    setIsButtonDisabled(true);
    const formData = new FormData();
    formData.append("reports_name", reportdate.reports);
    formData.append("from_date", reportdate.fromValues);
    formData.append("to_date", reportdate.toValues);
    try {
      if (reportdate.reports === "load_reports") {
        axios
          .post(`/admin/loading_report`, formData)
          .then((res) => {
            setreports_res(res.data.status);
            if (res.data.status === true) {
              setReportsList(res.data.Order_report);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }

      if (reportdate.reports === "unload_reports") {
        axios
          .post(`/admin/unloading_report`, formData)
          .then((res) => {
            setunloadList_res(res.data.status);
            if (res.data.status === true) {
              setunloadList(res.data.Order_report);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }

      if (reportdate.reports === "delivered_reports") {
        axios
          .post(`/admin/delivered_report`, formData)
          .then((res) => {
            setdeliveredlist_res(res.data.status);
            if (res.data.status === true) {
              setdeliveredlist(res.data.delivered_report);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }

      if (reportdate.reports === "journey_reports") {
        axios
          .post(`/admin/journey_complete_report`, formData)
          .then((res) => {
            setjourneylist_res(res.data.status);
            if (res.data.status === true) {
              setjourneylist(res.data.journey_complete_report);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }

      if (reportdate.reports === "rejected_reports") {
        axios
          .post(`/admin/rejected_report`, formData)
          .then((res) => {
            setRejectedlist_res(res.data.status);
            if (res.data.status === true) {
              setRejectedlist(res.data.rejected_report);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }

      if (reportdate.reports === "canceled_reports") {
        axios
          .post(`/admin/cancel_report`, formData)
          .then((res) => {
            setCanceledist_res(res.data.status);
            if (res.data.status === true) {
              setCanceledist(res.data.cancel_report);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }

      if (reportdate.reports === "admin_reports") {
        axios
          .post(`/admin/admin_closing_report`, formData)
          .then((res) => {
            setadminlist_res(res.data.status);
            if (res.data.status === true) {
              setadminlist(res.data.completed_report);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  //  .........................isSubmit.................................
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  //  ...................eway-update............................
  const editStatus = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(reportvalues);
    setErrors(errors);
    const formData = new FormData();
    formData.append("eway_number", reportvalues.ewaynum);
    formData.append("eway_date", reportvalues.ewaydates);
    formData.append("order_id", selectedRow.order_id);
    axios
      .post(`/admin/eway-update`, formData)
      .then((res) => {
        if (res.data.status === true) {
          setOpen3(false);
          submitReports(e);
          setreportvalues({
            ewaynum: "",
            ewaydates: "",
          });
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ...................empty_date_update......................
  const editemptydate = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(reportvalues);
    setErrors(errors);
    const formData = new FormData();
    formData.append("empty_date", reportvalues.ewaydates);
    formData.append("order_id", selectedRow.order_id);
    axios
      .post(`/admin/empty_date_update`, formData)
      .then((res) => {
        if (res.data.status === true) {
          setOpen(false);
          submitReports(e);
          setreportvalues({
            ewaydates: "",
          });
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  //  ...............................admin_details_update.....................................
  const editadmin = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const formData = new FormData();
    formData.append("admin_ref_no", reference);
    formData.append("empty_return_date", admindate);
    formData.append("order_id", selectedRow.order_id);
    axios
      .post(`/admin/admin_details_update`, formData)
      .then((res) => {
        if (res.data.status === true) {
          setOpen3(false);
          submitReports(e);
          setreportvalues({
            referencenumber: "",
            orderdate: "",
          });
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ........................selectedRow...........................
  useEffect(() => {
    if (selectedRow) {
      setadmindate(selectedRow.empty_return_date || "");
      setReference(selectedRow.admin_ref_no || "");
    }
  }, [selectedRow]);

  // ..............................loadreports..........................................

  let loadreports =
    filteredData.length > 0 ? (
      filteredData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          const serialNo = page * rowsPerPage + index + 1;
          return (
            <tr key={item.id}>
              <td>{serialNo}</td>
              <td>
                <div className="pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_date_in}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>
              <td>
                <div className="pro-name">
                  <span> {item.customer_name}/</span>
                  {item.company_name} /{item.customer_mobile}
                </div>
              </td>
              <td>{item.vendor_first_name}</td>
              <td>
                <div className="pro-name">
                  <span>{item.driver_name}/</span>
                  {item.driver_mobile}
                </div>
              </td>

              <td>{item.container_number}</td>
              <td>{item.vehicle_number}</td>
              <td>{item.loading_location}</td>
              <td>{item.date_and_time}</td>
              <td>
                <div className="actions">
                  {item.eway_count == 0 ? (
                    <>
                      <div className="payment_icon">
                        <img
                          src={date_icon}
                          onClick={() => handleOpen3(item)}
                        ></img>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pro-name">
                        <span> {item.eway_date}/</span>
                        {item.eway_number}
                      </div>
                    </>
                  )}
                </div>
              </td>
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="13" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  const Unloadingreport =
    filteredDataunload.length > 0 ? (
      filteredDataunload
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <div className="pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_date_in}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>
              <td>
                <div className="pro-name">
                  <span> {item.customer_name}/</span>
                  {item.company_name} /{item.customer_mobile}
                </div>
              </td>
              <td>{item.vendor_first_name}</td>
              <td>
                <div className="pro-name">
                  <span>{item.driver_name}/</span>
                  {item.driver_mobile}
                </div>
              </td>

              <td>{item.container_number}</td>
              <td>{item.vehicle_number}</td>
              <td>{item.unloading_location}</td>
              <td>{item.date_and_time}</td>
              <td>
                <div className="actions">
                  {item.eway_count == 0 ? (
                    <>
                      <div className="payment_icon">
                        <img
                          src={date_icon}
                          onClick={() => handleOpen3(item)}
                        ></img>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pro-name">
                        <span> {item.eway_date}/</span>
                        {item.eway_number}
                      </div>
                    </>
                  )}
                </div>
              </td>
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="13" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  // .............................Deliveredreports.....................................

  let Deliveredreports =
    filteredDataDelivered.length > 0 ? (
      filteredDataDelivered
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <div className="pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_date_in}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>
              <td>
                <div className="pro-name">
                  <span> {item.customer_name}/</span>
                  {item.company_name} /{item.customer_mobile}
                </div>
              </td>
              <td>{item.loading_location}</td>
              <td>{item.unloading_location}</td>
              <td>{item.delivered_date_and_time}</td>
              <td>{item.vehicle_number}</td>
              <td>
                <div className="actions">
                  {item.empty_count == 0 ? (
                    <>
                      <div className="payment_icon">
                        <img
                          src={date_icon}
                          onClick={() => handleOpen(item)}
                        ></img>
                      </div>
                    </>
                  ) : (
                    <>{item.empty_date}</>
                  )}
                </div>
              </td>
              <td>{item.total}</td>
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="13" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  const deliveredTotalCount = filteredDataDelivered.reduce((total, item) => {
    return total + item.total;
  }, 0);

  // .............................Journeyreports.....................................

  let Journeyreports =
    filteredDatajourney.length > 0 ? (
      filteredDatajourney
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <div className="pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_date_in}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>
              <td>
                <div className="pro-name">
                  <span> {item.customer_name}/</span>
                  {item.company_name} /{item.customer_mobile}
                </div>
              </td>
              <td>{item.vendor_first_name}</td>
              <td>
                <div className="pro-name">
                  <span> {item.driver_name}/</span>
                  {item.driver_mobile}
                </div>
              </td>
              <td>{item.journey_complete_date_time}</td>
              <td>{item.trip_amount}</td>
              <td>{item.gst_amount}</td>
              <td>
                <div className="payment_icon">
                  <div className="actions">
                    <img src={payment_icon} onClick={() => handleOpen1(item)} />
                  </div>
                </div>
              </td>
              <td>{item.total}</td>
              <td>
                <div className="payment_icon">
                  <div className="actions">
                    <button
                      className="btn btn-secondary"
                      onClick={() => handleOpen4(item)}
                    >
                      View
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="12" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  const itemTotalCount = filteredDatajourney.reduce((total, item) => {
    return total + item.total;
  }, 0);

  const adminjourneyCount = filteredDatajourney.reduce((total, item) => {
    return total + item.trip_amount;
  }, 0);

  // .............................Admin_reports.....................................

  let Adminreports =
    filteredDataadmin.length > 0 ? (
      filteredDataadmin
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <div className="pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_date_in}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>
              <td>
                <div className="pro-name">
                  <span> {item.customer_name}/</span>
                  {item.company_name} /{item.customer_mobile}
                </div>
              </td>
              <td>{item.order_date}</td>
              <td>{item.empty_date}</td>
              <td>{item.empty_return_date}</td>
              <td>{item.admin_ref_no}</td>
              <td>{item.total}</td>
              <td>
                <div className="actions">
                  <img src={edit} onClick={() => handleOpen3(item)}></img>
                  <Dialog
                    open={open3}
                    keepMounted
                    id={item.id}
                    onClose={handleClose3}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "800px",
                        },
                      },
                    }}
                  >
                    <DialogTitle>Order Reports</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
                      <div className="card-body">
                        <form>
                          <div className="row px-2">
                            <div className="col-lg-6">
                              <label>Empty Return Date</label>
                              <input
                                style={{ width: "100%" }}
                                type="datetime-local"
                                name="orderdate"
                                className="form-control"
                                value={admindate}
                                onChange={handleInput}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label>Reference Number</label>
                              <input
                                type="text"
                                name="referencenumber"
                                style={{ width: "100%" }}
                                placeholder="Reference Number"
                                className="form-control"
                                value={reference}
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <DialogActions>
                        <Button
                          onClick={(e) => editadmin(e, item.order_id)}
                          disabled={isButtonDisabled}
                        >
                          {isButtonDisabled ? "Loading..." : "Submit"}
                        </Button>
                        <Button onClick={handleClose3}>Close</Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </div>
              </td>
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="12" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  const adminTotalCount = filteredDataadmin.reduce((total, item) => {
    return total + item.total;
  }, 0);

  // .............................Rejected Reports.....................................

  let Rejectedreports =
    filteredDatarejected.length > 0 ? (
      filteredDatarejected
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <div className="pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_date_in}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>
              <td>
                <div className="pro-name">
                  <span> {item.customer_name}/</span>
                  {item.company_name} /{item.customer_mobile}
                </div>
              </td>
              <td>{item.trip_amount}</td>
              <td>{item.gst_amount}</td>
              <td>{item.total}</td>
              <td>{item.reject_by}</td>
              <td>{item.rejected_date_and_time}</td>
              <td>{item.reject_message}</td>
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="12" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  const rejectTotalCount = filteredDatarejected.reduce((total, item) => {
    return total + item.total;
  }, 0);
  const rejecttriplCount = filteredDatarejected.reduce((total, item) => {
    return total + item.trip_amount;
  }, 0);
  // .............................canceled_reports.....................................

  let Canceledreports =
    filteredDatacancel.length > 0 ? (
      filteredDatacancel
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <div className="pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_date_in}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>
              <td>
                <div className="pro-name">
                  <span> {item.customer_name}/</span>
                  {item.company_name} /{item.customer_mobile}
                </div>
              </td>
              <td>{item.trip_amount}</td>
              <td>{item.gst_amount}</td>
              <td>{item.total}</td>
              <td>{item.canceled_date_and_time}</td>
              <td>{item.cancel_reason}</td>
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="12" className="py-3">
          No Record Found
        </td>
      </tr>
    );
  const cancelTotalCount = filteredDatacancel.reduce((total, item) => {
    return total + item.total;
  }, 0);

  const cancelTripCount = filteredDatacancel.reduce((total, item) => {
    return total + item.trip_amount;
  }, 0);

  return (
    <>
      <div className="container-fluid px-4 ">
        <div className="title px-1 mt-4 ">
          <h4>
            <span className="titile_span">Order Reports</span>
          </h4>
        </div>

        {/* ............Dialog.............. */}

        <Dialog
          open={open1}
          keepMounted
          onClose={handleClose1}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",
              },
            },
          }}
        >
          <DialogTitle>Other Amount</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <div className="card-body">
              <div class="table-responsive">
                <table
                  className="table table-bordered table-striped border-start border-end"
                  id="table-to-xls"
                >
                  <thead>
                    <tr className="head_color">
                      <th className="Customer_table">Description</th>
                      <th className="Customer_table">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(otherchanges) &&
                      otherchanges.map((elm) => (
                        <tr key={elm.id}>
                          <td>{elm.description}</td>
                          <td>{elm.amount}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <DialogActions>
              <Button onClick={handleClose1}>Close</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* ...................Edit Delivered Reports............................... */}
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
              },
            },
          }}
        >
          <DialogTitle>Edit Delivered Reports</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <div className="card-body">
              <form>
                <div className="row px-2">
                  <div className="col-lg-12">
                    <label>
                      Empty Return Date <span className="star_icon">*</span>
                    </label>
                    <input
                      style={{ width: "100%" }}
                      type="datetime-local"
                      name="ewaydates"
                      className="form-control"
                      value={reportvalues.ewaydates}
                      onChange={handleInput}
                    />
                    {/* <p>{errors.ewaydates}</p> */}
                  </div>
                </div>
              </form>
            </div>
            <DialogActions>
              <Button onClick={editemptydate} disabled={isButtonDisabled}>
                {isButtonDisabled ? "Loading..." : "Submit"}
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {/* ..........................Edit Unloading Report.................................... */}
        <Dialog
          open={open3}
          keepMounted
          onClose={handleClose3}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
              },
            },
          }}
        >
          <DialogTitle>Edit Unloading Report</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <div className="card-body">
              <form>
                <div className="row px-2">
                  <div className="col-lg-6">
                    <label>
                      E-way Bill Date <span className="star_icon">*</span>
                    </label>
                    <input
                      style={{ width: "100%" }}
                      type="datetime-local"
                      name="ewaydates"
                      className="form-control"
                      value={reportvalues.ewaydates}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>
                      E-way Number <span className="star_icon">*</span>
                    </label>
                    <input
                      type="text"
                      name="ewaynum"
                      width="100%"
                      placeholder="E-way Number"
                      className="form-control"
                      value={reportvalues.ewaynum}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </form>
            </div>
            <DialogActions>
              <Button onClick={editStatus} disabled={isButtonDisabled}>
                {" "}
                {isButtonDisabled ? "Loading..." : "Submit"}
              </Button>
              <Button onClick={handleClose3}>Close</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* ............................Edit loading Report................................... */}
        <Dialog
          open={open3}
          keepMounted
          onClose={handleClose3}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
              },
            },
          }}
        >
          <DialogTitle>Edit loading Report</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <div className="card-body">
              <form>
                <div className="row px-2">
                  <div className="col-lg-6">
                    <label>
                      E-way Bill Date <span className="star_icon">*</span>
                    </label>
                    <input
                      style={{ width: "100%" }}
                      type="datetime-local"
                      name="ewaydates"
                      className="form-control"
                      value={reportvalues.ewaydates}
                      onChange={handleInput}
                    />
                    {/* <p>{errors.ewaydates}</p> */}
                  </div>
                  <div className="col-lg-6">
                    <label>
                      E-way Number <span className="star_icon">*</span>
                    </label>
                    <input
                      type="text"
                      name="ewaynum"
                      width="100%"
                      placeholder="E-way Number"
                      className="form-control"
                      value={reportvalues.ewaynum}
                      onChange={handleInput}
                    />
                    {/* <p>{errors.ewaynum}</p> */}
                  </div>
                </div>
              </form>
            </div>
            <DialogActions>
              <Button onClick={editStatus} disabled={isButtonDisabled}>
                {isButtonDisabled ? "Loading..." : "Submit"}
              </Button>
              <Button onClick={handleClose3}>Close</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* ...............................ALL Documnet........................... */}
        <Dialog
          open={open4}
          keepMounted
          onClose={handleClose4}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
              },
            },
          }}
        >
          <DialogTitle>Attachments</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <div className="card-body">
              <div class="table-responsive">
                <table
                  className="table table-bordered table-striped border-start border-end"
                  id="table-to-xls"
                >
                  <thead>
                    <tr className="head_color">
                      <th className="Customer_table">Document Name</th>
                      <th className="Customer_table">Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(alldocument) &&
                      alldocument.map((elm) => (
                        <tr key={elm.id}>
                          <td>{elm.document_type}</td>
                          <td key={elm.id}>
                            <a
                              href={`${url}${elm.document_path}`}
                              target="_blank"
                            >
                              <PictureAsPdfOutlinedIcon />
                            </a>{" "}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <DialogActions>
              <Button onClick={handleClose4}>Close</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <div className="card mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            {/* .......................form...................... */}
            <form>
              <div className="row filter mt-3 py-3 px-5 Reports">
                <div className="col-md-3 form-group mb-3 ">
                  <label>
                    Select From <span className="star_icon">*</span>
                  </label>
                  <input
                    type="date"
                    name="fromValues"
                    value={reportdate.fromValues}
                    onChange={handlereport}
                    className="form-control"
                  />
                  <p>{errors.fromValues}</p>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <label>
                    Select To <span className="star_icon">*</span>
                  </label>
                  <input
                    type="date"
                    name="toValues"
                    value={reportdate.toValues}
                    onChange={handlereport}
                    className="form-control"
                    min={mindate}
                  />
                  <p>{errors.toValues}</p>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Report Status <span className="star_icon">*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label=" Select Status"
                      value={reportdate.reports}
                      name="reports"
                      onChange={handlereport}
                    >
                      <MenuItem value={"load_reports"}>Load Reports</MenuItem>
                      <MenuItem value={"delivered_reports"}>
                        Ready Delivery
                      </MenuItem>
                      <MenuItem value={"unload_reports"}>
                        Unload Reports
                      </MenuItem>
                      <MenuItem value={"journey_reports"}>
                        Journey Completed Reports
                      </MenuItem>
                      <MenuItem value={"admin_reports"}>
                        Admin Close Reports
                      </MenuItem>
                      <MenuItem value={"canceled_reports"}>
                        Cancelled Reports
                      </MenuItem>
                      <MenuItem value={"rejected_reports"}>
                        Rejected Reports
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <p>{errors.reports}</p>
                </div>
                <div className="col-md-3">
                  <FormControl fullWidth>
                    <div class="d-grid gap-2 d-md-block  mt-3 mb-2 Order_bnt">
                      <button
                        onClick={submitReports}
                        disabled={isButtonDisabled}
                        class="btn btn-success "
                      >
                        {isButtonDisabled ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </FormControl>
                </div>
              </div>
            </form>
          </div>
          {reportdate.reports == "" ? (
            <>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="report_img">
                    <img src={Order_img} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {reportdate.reports == "load_reports" ? (
            <>
              {reports_res === true ? (
                <>
                  <div className="row px-5 p-0 mt-3">
                    <div className="titile  mb-4 mt-4">
                      <h4>Load Reports</h4>
                    </div>

                    <div className="card mt-4 mb-4  py-4">
                      <div className="search-box px-4 cust-search px-3">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery || ""}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div className="card-body px-4">
                        <div class="table-responsive">
                          <table
                            className="table table-bordered table-striped border-start border-end"
                            id="table-to-xls"
                          >
                            <thead>
                              <tr className="head_color">
                                <th>S NO</th>
                                <th className="Customer_table">
                                  Job Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("job_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Customer Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("company_name")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Vendor Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("vendor_first_name")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Driver Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("driver_name")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Container Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("container_number")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Vehicle Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("vehicle_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Loading Point
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("loading_location")
                                    }
                                  />
                                </th>
                                <th className="emails Customer_table">
                                  Date and Time
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("order_date")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  E-way Bill Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("empty_count")}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>{loadreports}</tbody>
                          </table>
                        </div>
                        {sortedData.length > 5 ? (
                          <>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              component="div"
                              count={filteredData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="report_img">
                        <img src={Order_img} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/* .....................unload_reports.............................. */}
          {reportdate.reports == "unload_reports" ? (
            <>
              {unloadList_res === true ? (
                <>
                  <div className="row px-5 p-0 mt-3">
                    <div className="titile  mb-4 mt-4">
                      <h4>Unload Reports</h4>
                    </div>

                    <div className="card mt-4 mb-4  py-4">
                      <div className="search-box px-4 cust-search px-3">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery || ""}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div className="card-body px-4">
                        <div class="table-responsive">
                          <table
                            className="table table-bordered table-striped border-start border-end"
                            id="table-to-xls"
                          >
                            <thead>
                              <tr className="head_color">
                                <th>S NO</th>
                                <th className="Customer_table">
                                  Job Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("job_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Customer Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("company_name")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Vendor Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("vendor_first_name")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Driver Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("driver_name")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Container Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("container_number")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Vehicle Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("vehicle_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Unloading Point
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("unloading_location")
                                    }
                                  />
                                </th>
                                <th className="Customer_table emails">
                                  Date and Time
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("order_date")}
                                  />
                                </th>
                                <th className="Customer_table emails">
                                  E-way Bill Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("eway_count")}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>{Unloadingreport}</tbody>
                          </table>
                        </div>
                        {filteredDataunload.length > 5 ? (
                          <>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              component="div"
                              count={filteredDataunload.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="report_img">
                        <img src={Order_img} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/* .....................delivered_reports.............................. */}
          {reportdate.reports == "delivered_reports" ? (
            <>
              {deliveredlist_res === true ? (
                <>
                  <div className="row px-5 p-0 mt-3">
                    <div className="titile  mb-4 mt-4">
                      <h4>Ready delivery </h4>
                    </div>

                    <div className="card mt-4 mb-4  py-4">
                      <div className="search-box px-4 cust-search px-3">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery || ""}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div className="card-body px-4">
                        <div class="table-responsive">
                          <table
                            className="table table-bordered table-striped border-start border-end"
                            id="table-to-xls"
                          >
                            <thead>
                              <tr className="head_color">
                                <th>S NO</th>
                                <th className="Customer_table">
                                  Job Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("job_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Customer Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("company_name")}
                                  />
                                </th>

                                <th className="Customer_table">
                                  Loading Point
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("loading_location")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Unloading Point
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("unloading_location")
                                    }
                                  />
                                </th>
                                <th className="Customer_table emails">
                                  Date and Time
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("delivered_date_and_time")
                                    }
                                  />
                                </th>

                                <th className="Customer_table">
                                  Vehicle Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("vehicle_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Empty Return Date
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("empty_date")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Total <p>({deliveredTotalCount})</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("total")}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>{Deliveredreports}</tbody>
                          </table>
                        </div>
                        {filteredDataDelivered.length > 5 ? (
                          <>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              component="div"
                              count={filteredDataDelivered.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="report_img">
                        <img src={Order_img} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/* .....................journey_reports.............................. */}
          {reportdate.reports == "journey_reports" ? (
            <>
              {journeylist_res === true ? (
                <>
                  <div className="row px-5 p-0 mt-3">
                    <div className="titile  mb-4 mt-4">
                      <h4> Journey Completed Reports</h4>
                    </div>

                    <div className="card mt-4 mb-4  py-4">
                      <div className="search-box px-4 cust-search px-3">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery || ""}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>

                      <div className="card-body px-4">
                        <div className="card-body px-4">
                          <div class="table-responsive">
                            <table
                              className="table table-bordered table-striped border-start border-end"
                              id="table-to-xls"
                            >
                              <thead>
                                <tr className="head_color">
                                  <th>S NO</th>
                                  <th className="Customer_table">
                                    Job Number
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() => handleSort("job_number")}
                                    />
                                  </th>
                                  <th className="Customer_table">
                                    Customer Details
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() => handleSort("company_name")}
                                    />
                                  </th>

                                  <th className="Customer_table">
                                    Vendor Name
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() =>
                                        handleSort("vendor_first_name")
                                      }
                                    />
                                  </th>
                                  <th className="Customer_table">
                                    Driver Details
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() => handleSort("driver_name")}
                                    />
                                  </th>
                                  <th className="Customer_table emails">
                                    Empty Return Date and Time
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() =>
                                        handleSort("journey_complete_date_time")
                                      }
                                    />
                                  </th>
                                  <th className="Customer_table">
                                    Trip Amount <p>({adminjourneyCount})</p>
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() => handleSort("trip_amount")}
                                    />
                                  </th>
                                  <th className="Customer_table">
                                    GST <p>(12%)</p>
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() => handleSort("gst_amount")}
                                    />
                                  </th>
                                  <th className="Customer_table">
                                    Other Amount
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() =>
                                        handleSort("vehicle_number")
                                      }
                                    />
                                  </th>

                                  <th className="Customer_table">
                                    Total <p>({itemTotalCount})</p>
                                    <ImportExportIcon
                                      className="Customer_table_child"
                                      onClick={() => handleSort("total")}
                                    />
                                  </th>
                                  <th className="Customer_table">
                                    Attachment
                                    <ImportExportIcon className="Customer_table_child" />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{Journeyreports}</tbody>
                            </table>
                          </div>
                          {filteredDatajourney.length > 5 ? (
                            <>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                component="div"
                                count={filteredDatajourney.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="report_img">
                        <img src={Order_img} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/* .....................rejected_reports.............................. */}
          {reportdate.reports == "rejected_reports" ? (
            <>
              {Rejectedlist_res === true ? (
                <>
                  <div className="row px-5 p-0 mt-3">
                    <div className="titile  mb-4 mt-4">
                      <h4>Rejected Reports</h4>
                    </div>

                    <div className="card mt-4 mb-4  py-4">
                      <div className="search-box px-4 cust-search px-3">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery || ""}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div className="card-body px-4">
                        <div class="table-responsive">
                          <table
                            className="table table-bordered table-striped border-start border-end"
                            id="table-to-xls"
                          >
                            <thead>
                              <tr className="head_color">
                                <th>S NO</th>
                                <th className="Customer_table">
                                  Job Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("job_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Customer Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("company_name")}
                                  />
                                </th>

                                <th className="Customer_table">
                                  Trip Amount <p>({rejecttriplCount})</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("trip_amount")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  GST <p>(12%)</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("gst_amount")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Total<p>({rejectTotalCount})</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("total")}
                                  />
                                </th>

                                <th className="Customer_table">
                                  Rejected By
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("reject_by")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Rejected Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("rejected_date_and_time")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Remarks
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("reject_message")}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>{Rejectedreports}</tbody>
                          </table>
                        </div>
                        {filteredDatarejected.length > 5 ? (
                          <>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              component="div"
                              count={filteredDatarejected.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="report_img">
                        <img src={Order_img} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/* .....................canceled_reports.............................. */}
          {reportdate.reports == "canceled_reports" ? (
            <>
              {Canceledist_res === true ? (
                <>
                  <div className="row px-5 p-0 mt-3">
                    <div className="titile  mb-4 mt-4">
                      <h4>Cancelled Reports</h4>
                    </div>

                    <div className="card mt-4 mb-4  py-4">
                      <div className="search-box px-4 cust-search px-3">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery || ""}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div className="card-body px-4">
                        <div class="table-responsive">
                          <table
                            className="table table-bordered table-striped border-start border-end"
                            id="table-to-xls"
                          >
                            <thead>
                              <tr className="head_color">
                                <th>S NO</th>
                                <th className="Customer_table">
                                  Job Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("job_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Customer Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("company_name")}
                                  />
                                </th>

                                <th className="Customer_table">
                                  Trip Amount<p>({cancelTripCount})</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("trip_amount")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  GST <p>(12%)</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("gst_amount")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Total<p>({cancelTotalCount})</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("total")}
                                  />
                                </th>

                                <th className="Customer_table">
                                  Cancelled date and Time
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("canceled_date_and_time")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Cancel Reason
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("cancel_reason")}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>{Canceledreports}</tbody>
                          </table>
                        </div>
                        {filteredDatacancel.length > 5 ? (
                          <>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              component="div"
                              count={filteredDatacancel.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="report_img">
                        <img src={Order_img} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {/* .....................admin_reports.............................. */}
          {reportdate.reports == "admin_reports" ? (
            <>
              {adminlist_res === true ? (
                <>
                  <div className="row px-5 p-0 mt-3">
                    <div className="titile  mb-4 mt-4">
                      <h4>Admin Closing Report </h4>
                    </div>
                    <div className="card mt-4 mb-4  py-4">
                      <div className="search-box px-4 cust-search px-3">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery || ""}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div className="card-body px-4">
                        <div class="table-responsive">
                          <table
                            className="table table-bordered table-striped border-start border-end"
                            id="table-to-xls"
                          >
                            <thead>
                              <tr className="head_color">
                                <th>S NO</th>
                                <th className="Customer_table">
                                  Job Number
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("job_number")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Customer Details
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("company_name")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Date and Time
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("order_date_in")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Empty Plan
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("empty_date")}
                                  />
                                </th>
                                <th className="Customer_table">
                                  Empty Return
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() =>
                                      handleSort("empty_return_date")
                                    }
                                  />
                                </th>
                                <th className="Customer_table">
                                  Enter Reference No
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("admin_ref_no")}
                                  />
                                </th>{" "}
                                <th className="Customer_table">
                                  Total <p>({adminTotalCount})</p>
                                  <ImportExportIcon
                                    className="Customer_table_child"
                                    onClick={() => handleSort("total")}
                                  />
                                </th>
                                <th className="Customer_table">Action</th>
                              </tr>
                            </thead>
                            <tbody>{Adminreports}</tbody>
                          </table>
                        </div>
                        {filteredDataadmin.length > 5 ? (
                          <>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              component="div"
                              count={filteredDataadmin.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="report_img">
                        <img src={Order_img} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default Allreport;
