import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useAlert } from "react-alert";
import Radio from "@mui/material/Radio";
import "../../admin/Driver/driver.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ErrorMessage } from "../../Customalert/Customalert";

const AddVendor = () => {
  const alert = useAlert();
  const { state } = useContext(UserContext);
  const [city, setcity] = useState([]);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  let arr = Array.apply(null, { length: 11 }).map(Number.call, Number);

  const [adddriver, setadddriver] = useState({
    first_name: "",
    contact_number: "",
    secondary_number: "",
    address: "",
    email: "",
    password: "",
    password_confirmation: "",
    state_id: "",
    city_id: "",
    country_id: "",
    priority: "",
    commission_per: "",
    gst_doc: "",
    gst_number: "",
  });

  // ..........................showPassword.....................................

  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ...............................................
  const [selectedValue, setSelectedValue] = React.useState("1");

  const handleChangee = (event) => {
    setSelectedValue(event.target.value);
  };

  // .....................Document.............................
  const [gstdoc, setgstdoc] = useState("");
  const [pandoc, setpandoc] = useState("");

  function gstdocChange(event) {
    setgstdoc(event.target.files[0]);
  }

  function pandocChange(event) {
    setpandoc(event.target.files[0]);
  }

  // .......................handleInput..........................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setadddriver({
          ...adddriver,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "address":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "priority":
      case "state_id":
      case "city_id":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "contact_number":
      case "secondary_number":
        setadddriver({
          ...adddriver,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      case "commission_per":
        setadddriver({
          ...adddriver,
          [name]: value.replace(/\D/g, "").slice(0, 2),
        });
        break;
      case "gst_number":
        setadddriver({
          ...adddriver,
          [name]: value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15),
        });
        break;
      default:
        setadddriver({
          ...adddriver,
          [name]: value.replace(/[^a-zA-Z0-9@\#$.,\s]/g, ""),
        });
        break;
    }
  };

  // ....................validate..................................

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    const gstRegex = /^[a-zA-Z0-9]{15}$/;
    const commRegex = /^\d{2}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "First Name is required !";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required !";
    }

    if (!values.email) {
      errors.email = "Email ID is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }

    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password Confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }

    if (!values.company_name) {
      errors.company_name = "Company Name is required !";
    }

    if (!values.address) {
      errors.address = "Address is required !";
    }

    if (!values.contact_number) {
      errors.contact_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.contact_number)) {
      errors.contact_number = "Phone Number must be 10 digits !";
    }
    if (!values.secondary_number) {
      errors.secondary_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.secondary_number)) {
      errors.secondary_number = "Phone Number must be 10 digits !";
    }
    if (!values.gst_number) {
      errors.gst_number = "GST Number is required !";
    } else if (!gstRegex.test(values.gst_number)) {
      errors.gst_number = "GST Number must be 15 alphanumeric characters !";
    }
    if (!values.commission_per) {
      errors.commission_per = "Commission  is required !";
    } else if (!commRegex.test(values.commission_per)) {
      errors.commission_per = "Commission  must be 2 digits !";
    }
    if (!gstdoc) {
      errors.gstdoc = "GST Document is required !";
    }

    if (!pandoc) {
      errors.pandoc = "PAN Document is required !";
    }

    if (!values.city_id) {
      errors.city_id = "City is required !";
    }
    if (!values.priority) {
      errors.priority = "Priority is required !";
    }
    if (!values.state_id) {
      errors.state_id = "State is required !";
    }

    if (!selectedValue) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  // ...................submit..............................

  const submitdriver = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(adddriver);
    setErrors(errors);
    setIsSubmit(true);
    if (adddriver.password !== adddriver.password_confirmation) {
      swal("Passwords Miss Match", "warning");
    } else {
      const formData = new FormData();
      formData.append("first_name", adddriver.first_name);
      formData.append("contact_number", adddriver.contact_number);
      formData.append("secondary_number", adddriver.secondary_number);
      formData.append("email", adddriver.email);
      formData.append("password", adddriver.password);
      formData.append("password_confirmation", adddriver.password_confirmation);
      formData.append("state_id", adddriver.state_id);
      formData.append("city_id", adddriver.city_id);
      formData.append("status", selectedValue);
      formData.append("priority", adddriver.priority);
      formData.append("commission_per", adddriver.commission_per);
      formData.append("address", adddriver.address);
      formData.append("gst_number", adddriver.gst_number);
      formData.append("gst_doc", gstdoc);
      if (gstdoc != "") {
        formData.append("gst_doc", gstdoc.name);
      }
      formData.append("pan_doc", pandoc);
      if (pandoc != "") {
        formData.append("pan_doc", pandoc.name);
      }
      axios
        .post(`/admin/vendor-create`, formData)
        .then((res) => {
          if (res.data.status === true) {
            setadddriver(res.data.vendor);
            swal("Created", res.data.message, "success");
            history.push("/admin/vendor/vendorlist");
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch(() => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ......................isSubmit........................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  // ..........city list get api ...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.status === false) {
          swal("Error", data.status, "error");
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span"> Add New Vendor</span>

          <Link
            to="/admin/vendor/vendorlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vendor Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Vendor Name"
                  name="first_name"
                  onChange={handleInput}
                  value={adddriver.first_name}
                  className="form-control"
                />
                <p>{errors.first_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  State <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="state_id"
                  onChange={handleInput}
                  value={adddriver.state_id}
                >
                  <option value="">--Select State--</option>
                  {state.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.state_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  City <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleInput}
                  value={adddriver.city_id}
                >
                  <option value="">--Select City--</option>
                  {city.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.city_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Phone Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="contact_number"
                  onChange={handleInput}
                  value={adddriver.contact_number}
                  className="form-control"
                />
                {errors.contact_number && <p>{errors.contact_number}</p>}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Secondary Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Secondary Number"
                  name="secondary_number"
                  onChange={handleInput}
                  value={adddriver.secondary_number}
                  className="form-control"
                />
                {errors.secondary_number && <p>{errors.secondary_number}</p>}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Address <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={adddriver.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  GST Number <span className="star_icon">*</span>
                </label>
                <input
                  name="gst_number"
                  type="text"
                  placeholder="GST Number"
                  value={adddriver.gst_number}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.gst_number}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  GST Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="Gst Doument"
                  name="gst_doc"
                  onChange={gstdocChange}
                  className="form-control"
                />
                <p>{errors.gstdoc}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  PAN Document <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="PAN Doument"
                  name="pan_doc"
                  onChange={pandocChange}
                  className="form-control"
                />
                <p>{errors.pandoc}</p>
              </div>

              <div className="col-md-6 form-group mb-3">
                <label>
                  Commission <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Commission"
                  name="commission_per"
                  onChange={handleInput}
                  value={adddriver.commission_per}
                  className="form-control"
                />
                <p>{errors.commission_per}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Priority <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="priority"
                  onChange={handleInput}
                >
                  <option value="">--Select Priority--</option>
                  {arr.map((item) => {
                    return <option>{item}</option>;
                  })}
                </select>
                <p>{errors.priority}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Email ID <span className="star_icon">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Email ID"
                  name="email"
                  onChange={handleInput}
                  value={adddriver.email}
                  className="form-control"
                />
                <p>{errors.email}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Password <span className="star_icon">*</span>
                </label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleInput}
                    value={adddriver.password}
                    name="password"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowPassword}
                    className="password-toggle-btn "
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                <p>{errors.password}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Confirm Password <span className="star_icon">*</span>
                </label>
                <div className="password-input">
                  <input
                    type={comfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleInput}
                    value={adddriver.password_confirmation}
                    name="password_confirmation"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowcomfirmPassword}
                    className="password-toggle-btn "
                  >
                    {comfirmPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                <p>{errors.password_confirmation}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === "1"}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "1" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === "0"}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "0" }}
                    />
                    In-active
                  </div>
                </div>
                <p>{errors.status}</p>
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-secondary float-end px-5 p-2"
                  onClick={submitdriver}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVendor;
