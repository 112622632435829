import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useContext } from "react";
import { ErrorMessage } from "../../Customalert/Customalert";

const Citycreate = () => {
  const { state } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  const [addcity, setaddcity] = useState({
    name: "",
    state_id: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "state_id":
        setaddcity({
          ...addcity,
          [name]: value,
        });
        break;
      default:
        setaddcity({
          ...addcity,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // ....................validate.........................

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "City Name is required !";
    }
    if (!values.state_id) {
      errors.state_id = "State Name is required !";
    }
    return errors;
  };

  const submitdriver = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(addcity));
    setIsSubmit(true);
    if (addcity.name !== addcity.name) {
      swal("Passwords Miss Match", "warning");
    } else {
      const formData = new FormData();
      formData.append("name", addcity.name);
      formData.append("state_id", addcity.state_id);
      axios
        .post(`/city-create`, formData)
        .then((res) => {
          if (res.data.status === true) {
            setaddcity(res.data.city);
            swal("Created", res.data.message, "success");
            history.push("/admin/city/citylist");
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch(() => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ......................isSubmit......................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  return (
    <>
      <div className="container-fluid px-4">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span"> Add New City</span>
            <Link
              to="/admin/city/citylist"
              className="btn btn-primary admin float-end"
            >
              Go Back
            </Link>
          </h4>
        </div>
        <div className="card mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            <form>
              <div className="row px-5">
                <div className="col-md-6 form-group mb-3">
                  <label>
                    City Name <span className="star_icon">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="City Name"
                    name="name"
                    onChange={handleInput}
                    value={addcity.name}
                    className="form-control"
                  />
                  <p>{errors.name}</p>
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    State <span className="star_icon">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="state_id"
                    onChange={handleInput}
                    value={addcity.state_id}
                  >
                    <option value="">--Select State--</option>
                    {state.map((itm) => {
                      return (
                        <option value={itm.id} key={itm.id}>
                          {itm.name}
                        </option>
                      );
                    })}
                  </select>
                  <p>{errors.state_id}</p>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    class="btn btn-secondary float-end  px-5 p-2"
                    onClick={submitdriver}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Citycreate;
