import axios from "axios";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import edit from "../../../assets/admin/assets/img/edit.svg";
import "../../admin/Driver/driver.scss";
import { useAlert } from "react-alert";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import swal from "sweetalert";

const Cityshow = () => {
  const [citylist, setcitylist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  // .....................TablePagination ............................

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ...........................Table Sort.................................
  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...citylist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [citylist, sort]);

  //  .......................Table search................................
  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.state_id.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  //  ......................city-list api get.................................
  useEffect(() => {
    const fetchcityData = async () => {
      try {
        const res = await axios.get("/city-list");
        if (res.data.status === true) {
          setcitylist(res.data.citylist);
          setLoading(false);
        }
      } catch (error) {
        swal("Error", error.message, "error");
      }
    };

    fetchcityData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // ...........................city_list............................

  let city_list = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    city_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.name}</td>
                <td>{item.city_unique_id}</td>
                <td>{item.date_time}</td>
                <td>
                  <div className="actions">
                    <Link to={`/admin/city/city-update/${item.id}`}>
                      <img src={edit} />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  return (
    <div className="container-fluid px-4 Driver">
      <div className="title px-1 mt-4">
        <h4>
          <span className="titile_span"> City List</span>

          <Link
            to="/admin/city/city-add"
            className="btn btn-primary admin float-end"
          >
            Add City
          </Link>
        </h4>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="card-body px-4">
          <div class="table-responsive">
            <table className="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th>S No</th>
                  <th className="Customer_table">
                    City Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("name")}
                    />
                  </th>
                  <th className="emails Customer_table">
                    City ID
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("city_unique_id")}
                    />
                  </th>
                  <th className="emails Customer_table">
                    Updated Time
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("date_time")}
                    />
                  </th>
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{city_list}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Cityshow;
