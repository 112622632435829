import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./App";

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
  type: "success",
  containerStyle: {
    zIndex: 1040,
  },
};

const theme = createTheme();

const history = createBrowserHistory();

const rootElement = document.getElementById("root");

const renderApp = () => {
  ReactDOM.createRoot(rootElement).render(
    <StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </ThemeProvider>
        </Router>
      </AlertProvider>
    </StrictMode>
  );
};

export function register() {
  return new Promise((resolve, reject) => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            console.log("Service Worker registered:", registration);
            resolve(registration);
          })
          .catch((error) => {
            console.log("Service Worker registration failed:", error);
            reject(error);
          });
      });
    } else {
      console.log("Service Worker is not supported by the browser.");
      reject(new Error("Service Worker is not supported."));
    }
  });
}

register();

renderApp();
