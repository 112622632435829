import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const VendorRouteshow = (props) => {
  const [Routeshow, setRouteshow] = useState({
    vendor: "",
    loading: "",
    unloading: "",
    truck_type_name: "",
    truck_capacity_name: "",
    route_doc: "",
    status_name: "",
  });

  // ...................route-show edit api .........................

  useEffect(() => {
    const route_show = props.match.params.id;
    const getRouteshow = async () => {
      const res = await axios.get(`/admin/route-show/${route_show}`);
      if (res.data.status === true) {
        setRouteshow(res.data.route);
      }
    };

    try {
      getRouteshow();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Route Show</span>
          <Link
            to="/vendor/routelist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-6 view-main">
              <div className="first-col">
                <h6>Type</h6>
                <h6>Loading</h6>
                <h6>Unloading</h6>
                <h6>Vehicle Type</h6>
                <h6>Container Size</h6>
                <h6>Costing Price</h6>
                <h6>Status</h6>
              </div>
              <div className="first-col-sibling">
                <small>
                  {Routeshow.type !== "" ? <>{Routeshow.type}</> : <>N/A</>}
                </small>
                <small>
                  {Routeshow.loading !== "" ? (
                    <>{Routeshow.loading}</>
                  ) : (
                    <>N/A</>
                  )}
                </small>
                <small>
                  {Routeshow.unloading !== "" ? (
                    <>{Routeshow.unloading}</>
                  ) : (
                    <>N/A</>
                  )}
                </small>

                <small>
                  {Routeshow.truck_type_name !== "" ? (
                    <>{Routeshow.truck_type_name}</>
                  ) : (
                    <>N/A</>
                  )}
                </small>
                <small>
                  {Routeshow.truck_capacity_name !== "" ? (
                    <>{Routeshow.truck_capacity_name}</>
                  ) : (
                    <>N/A</>
                  )}
                </small>
                <small>
                  {Routeshow.costing_price !== "" ? (
                    <>{Routeshow.costing_price}</>
                  ) : (
                    <>N/A</>
                  )}
                </small>
                <small>
                  {Routeshow.status_name === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{Routeshow.status_name}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{Routeshow.status_name}</span>
                      </div>
                    </>
                  )}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendorRouteshow;
