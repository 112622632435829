import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../Usecontext/UserContext";
import Radio from "@mui/material/Radio";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Routeupdate = (props) => {
  const { truckcapacity, trucktype } = useContext(UserContext);
  const [loadingpoint, setloadingpoint] = useState([]);
  const history = useHistory();
  const [vendorname, setvendorname] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [unloading, setunloading] = useState([]);

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loadingid, setloadingid] = useState("");
  const [warehouseshow, setwarehouseshow] = useState({
    // vendor_first_name: "",
    loading_id: "",
    unloading_id: "",
    truck_type_id: "",
    truck_capacity_id: "",
    costing_price: "",
    route_doc: "",
    id: "",
    unloading: "",
    type: "",
  });

  // ..................selectedimport...............................

  const [selectedimport, setselectedimport] = React.useState();

  const importhandleChange = (event) => {
    setselectedimport(event.target.value);
  };

  // ..............................................
  const [price, setprice] = useState("");

  const handlepriceChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setprice(value);
  };

  // ...................selectedValue..................................
  const [selectedValue, setSelectedValue] = useState(0);

  const handleChangee = (event) => {
    setSelectedValue(parseInt(event.target.value));
    if (selectedValue === 1) {
      return "true";
    } else {
      return "false";
    }
  };

  useEffect(() => {
    setselectedimport(warehouseshow.type);
    setSelectedValue(warehouseshow.status);
    setprice(warehouseshow.costing_price);
  }, [warehouseshow]);

  // .......................handleInput................................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "loading_id":
      case "unloading_id":
      case "type":
      case "truck_type_id":
      case "truck_capacity_id":
      case "status":
        setwarehouseshow({
          ...warehouseshow,
          [name]: value,
        });
        break;
      default:
        setwarehouseshow({
          ...warehouseshow,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // ....................validate.............................

  const validate = (values) => {
    const errors = {};
    if (!selectedimport) {
      errors.type = "type is required !";
    }
    // if (!values.vendor_id) {
    //   errors.vendor_id = "Vendor Name is required!";
    // }
    if (!values.loading) {
      errors.loading = "Loading Point is required !";
    }
    if (!values.unloading) {
      errors.unloading = "Unloading Point is required !";
    }
    if (!values.truck_capacity_id) {
      errors.truck_capacity_id = "Container Size is required !";
    }
    if (!values.truck_type_id) {
      errors.truck_type_id = "Vehicle Type is required !";
    }

    if (!price) {
      errors.costing_price = "Costing price is required !";
    }

    if (!values.status) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  // .................loadinglist api get..................
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/loadinglist");
        if (res.data.status === true) {
          setloadingpoint(res.data.warehouse);
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // ...................route-show edit api.........................

  useEffect(() => {
    const route_id = props.match.params.id;
    axios
      .get(`/admin/route-show/${route_id}`)
      .then((res) => {
        if (res.data.status === true) {
          setwarehouseshow(res.data.route);
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  // .......................vendorlist.............................

  useEffect(() => {
    axios.get(`/vendorlist`).then((res) => {
      if (res.data.status === true) {
        setvendorname(res.data.vendor);
      }
    });
  }, []);

  // .................handleloading...............................

  const handleloading = (e) => {
    e.persist();
    const loadingid = e.target.value;
    setloadingid(loadingid);
    setwarehouseshow({
      ...warehouseshow,
      [e.target.name]: e.target.value,
    });
  };

  // ............................unloadinglist............................

  useEffect(() => {
    if (loadingid) {
      axios.post(`/unloadinglist?loading=${loadingid}`).then((res) => {
        if (res.data.status === true) {
          setunloading(res.data.warehouse);
        }
      });
    }
  }, [loadingid]);

  // ........................submit..................................
  const submitcustomer = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(warehouseshow));
    setIsSubmit(true);
    const formdata = new FormData();
    // formdata.append("vendor_id", warehouseshow.vendor_id);
    formdata.append("loading", warehouseshow.loading_id);
    formdata.append("unloading", warehouseshow.unloading_id);
    formdata.append("truck_type_id", warehouseshow.truck_type_id);
    formdata.append("truck_capacity_id", warehouseshow.truck_capacity_id);
    formdata.append("costing_price", price);
    formdata.append("status", selectedValue);
    formdata.append("type", selectedimport);
    formdata.append("id", warehouseshow.id);
    axios
      .post(`/admin/route-update`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setwarehouseshow(res.data.route);
          swal("Updated", res.data.message, "success");
          history.push("/admin/route/routelist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Route Update</span>
          <Link
            to="/admin/route/routelist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-lg-12 form-group mb-3">
                <label>Type</label>
                <div className="row">
                  <div className="col-lg-2">
                    <Radio
                      checked={selectedimport === "Import"}
                      onChange={importhandleChange}
                      value="Import"
                      name="type"
                      inputProps={{ "aria-label": "Import" }}
                    />
                    Import
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedimport === "Export"}
                      onChange={importhandleChange}
                      value="Export"
                      name="type"
                      inputProps={{ "aria-label": "Export" }}
                    />
                    Export
                  </div>
                </div>
                <p>{errors.type}</p>
              </div>
              {/* <div className="col-md-6 form-group mb-3">
                <label>Vendor Name</label>
                <select
                  className="form-control"
                  name="vendor_id"
                  onChange={handleInput}
                  value={warehouseshow.vendor_id}
                >
                  <option>--Select Vendor--</option>
                  {vendorname.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.first_name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.vendor_id}</p>
              </div> */}
              <div className="col-md-6 form-group mb-3">
                <label>Loading Point</label>
                <select
                  className="form-control"
                  name="loading_id"
                  onChange={handleloading}
                  value={warehouseshow.loading_id}
                  disabled
                >
                  {loadingpoint.length == 0 ? (
                    <>
                      <option value="">--Select Loading Point--</option>
                    </>
                  ) : (
                    <>
                      <option value="">--Select Loading Point--</option>
                      {loadingpoint.map((itm) => {
                        return (
                          <option value={itm.id} key={itm.id}>
                            {itm.address}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                <p>{errors.loading}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Unloading Point</label>

                <select
                  className="form-control"
                  name="unloading_id"
                  onChange={handleInput}
                  value={warehouseshow.unloading_id}
                  disabled
                >
                  {loadingpoint.length == 0 ? (
                    <>
                      <option value="">--Select Unloading Point--</option>
                    </>
                  ) : (
                    <>
                      <option value="">--Select Unloading Point--</option>
                      {loadingpoint.map((itm) => {
                        return (
                          <option value={itm.id} key={itm.id}>
                            {itm.address}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                <p>{errors.unloading}</p>
              </div>

              <div className="col-md-6 form-group mb-3">
                <label>Vehicle Type</label>
                <select
                  className="form-control"
                  name="truck_type_id"
                  onChange={handleInput}
                  value={warehouseshow.truck_type_id}
                  disabled
                >
                  <option value="">--Vehicle Type--</option>
                  {trucktype.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.type_name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.truck_type_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label> Container Size</label>

                <select
                  className="form-control"
                  name="truck_capacity_id"
                  onChange={handleInput}
                  value={warehouseshow.truck_capacity_id}
                  disabled
                >
                  <option value="">-- Container Size--</option>
                  {truckcapacity.map((itm) => {
                    return (
                      <>
                        <option value={itm.id} key={itm.id}>
                          {itm.capactiy_name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <p>{errors.truck_capacity_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Costing price <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Costing price"
                  name="costing_price"
                  onChange={handlepriceChange}
                  value={price}
                  className="form-control"
                />
                <p>{errors.costing_price}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Status</label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === 1}
                      onChange={handleChangee}
                      value={1}
                      name="status"
                      inputProps={{ "aria-label": "true" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === 0}
                      onChange={handleChangee}
                      value={0}
                      name="status"
                      inputProps={{ "aria-label": "false" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  name="id"
                  onChange={handleInput}
                  value={warehouseshow.id}
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <button
                  className="btn btn-secondary float-end px-5 p-2"
                  onClick={submitcustomer}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Routeupdate;
