import React from "react";
import Ongoingprocess from "./Ongoingprocess";
import Vendorcard from "./vendorCard/Vendorcard";
import "../../Components/admin/admincard.css";

const Dashboard = () => {
  return (
    <div className="row px-4 py-4 admin-dash">
      <div className="heads">
        <h6>Dashboard</h6>
      </div>
      <Vendorcard />
      <>
        <Ongoingprocess />
      </>
    </div>
  );
};

export default Dashboard;
