import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Admincreate = () => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Addadmin, setaddadmin] = useState({
    name: "",
    mobileno: "",
    email: "",
    id: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // .......................showPassword...............................

  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  //  .....................selectedValue..........................

  const [selectedValue, setSelectedValue] = React.useState("1");

  const handleChangee = (event) => {
    setSelectedValue(event.target.value);
  };

  // .........................handleInput..................................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setaddadmin({
          ...Addadmin,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
        setaddadmin({
          ...Addadmin,
          [name]: value,
        });
        break;
      case "mobileno":
        setaddadmin({
          ...Addadmin,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      case "status":
        setaddadmin({
          ...Addadmin,
          [name]: value,
        });
        break;
      default:
        setaddadmin({
          ...Addadmin,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // ............................validate...................................

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is required!";
    }

    if (!values.email) {
      errors.email = "Email ID is required!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address!";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password Confirmation is required";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match";
    }

    if (!values.mobileno) {
      errors.mobileno = "Phone Number is required!";
    } else if (!phoneRegex.test(values.mobileno)) {
      errors.mobileno = "Phone Number must be 10 digits";
    }

    if (!selectedValue) {
      errors.status = "Status is required";
    }
    return errors;
  };

  //  ..................submit.....................

  const submitadmin = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(Addadmin));
    setIsSubmit(true);
    const formdata = new FormData();
    formdata.append("name", Addadmin.name);
    formdata.append("mobileno", Addadmin.mobileno);
    formdata.append("email", Addadmin.email);
    formdata.append("status", selectedValue);
    formdata.append("password", Addadmin.password);
    formdata.append("password_confirmation", Addadmin.password_confirmation);
    axios
      .post(`/admin/user-create`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setaddadmin(res.data.userlist);
          swal("Created", res.data.message, "success");
          history.push("/admin/adminlist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ......................isSubmit........................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  return (
    <>
      <div className="container-fluid px-4">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span"> Create Admin</span>

            <Link
              to="/admin/adminlist"
              className="btn btn-primary admin float-end"
            >
              Go Back
            </Link>
          </h4>
        </div>
        <div className="card mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            <form>
              <div className="row px-5">
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Name <span className="star_icon">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    className="form-control"
                    onChange={handleInput}
                    value={Addadmin.name}
                  />
                  <p>{errors.name}</p>
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Phone Number <span className="star_icon">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    name="mobileno"
                    onChange={handleInput}
                    value={Addadmin.mobileno}
                    className="form-control"
                  />
                  <p>{errors.mobileno}</p>
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Email ID <span className="star_icon">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Email ID"
                    name="email"
                    className="form-control"
                    onChange={handleInput}
                    value={Addadmin.email}
                  />
                  <p>{errors.email}</p>
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Password <span className="star_icon">*</span>
                  </label>
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      className="form-control"
                      onChange={handleInput}
                      value={Addadmin.password}
                    />
                    <a
                      onClick={toggleShowPassword}
                      className="password-toggle-btn "
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                    </a>
                  </div>
                  <p>{errors.password}</p>
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Confirm Password <span className="star_icon">*</span>
                  </label>
                  <div className="password-input">
                    <input
                      type={comfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="password_confirmation"
                      className="form-control"
                      onChange={handleInput}
                      value={Addadmin.password_confirmation}
                    />
                    <a
                      onClick={toggleShowcomfirmPassword}
                      className="password-toggle-btn "
                    >
                      {comfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </a>
                  </div>
                  <p>{errors.password_confirmation}</p>
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>
                    Status <span className="star_icon">*</span>
                  </label>
                  <div className="row">
                    <div className="col-lg-3">
                      <Radio
                        checked={selectedValue === "1"}
                        onChange={handleChangee}
                        value="1"
                        name="status"
                        inputProps={{ "aria-label": "1" }}
                      />
                      Active
                    </div>
                    <div className="col">
                      <Radio
                        checked={selectedValue === "0"}
                        onChange={handleChangee}
                        value="0"
                        name="status"
                        inputProps={{ "aria-label": "0" }}
                      />
                      In-active
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-secondary float-end px-5 p-2"
                    onClick={submitadmin}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Admincreate;
