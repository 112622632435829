import axios from "axios";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import eye from "../../../assets/admin/assets/img/eye.svg";
import edit from "../../../assets/admin/assets/img/edit.svg";
import "../../admin/Driver/driver.scss";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import "../../../Components/admin/admincard.css";
import { ErrorMessage } from "../../Customalert/Customalert";

const Customer = () => {
  const [loading, setloading] = useState(true);
  const [customerlist, setcustomberlist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // ...................TablePagination ................................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // .......................Table sort.....................................
  const handleSort = (key) => {
    const regex = /[./]/g;
    const containsSlashOrDot = key.match(regex);
    if (containsSlashOrDot) {
      const direction =
        sort.key === key && sort.direction === "asc" ? "desc" : "asc";
      setSort({ key, direction });
    } else {
    }
  };

  const sortedData = useMemo(() => {
    return [...customerlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [customerlist, sort]);

  // ........................Table seach.....................................
  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.customer_sid.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.contact_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.kyc_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // .........................customer-list post api.........................

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const res = await axios.get("/admin/customer-list");
        if (res.data.status === true) {
          setcustomberlist(res.data.customerlist);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchCustomerData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .....................Table List..........................

  let customer = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    customer =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td className="emails">{serialNo}</td>
                <td className="emails">{item.customer_sid}</td>
                <td>
                  <div className="pro-name">
                    <span>{item.first_name}/</span>
                    {item.last_name}/{item.company_name}
                  </div>
                </td>
                <td className="emails">{item.email}</td>
                <td>{item.contact_number}</td>
                <td className="Kyc_table">
                  {item.kyc_status == "Approved" ? (
                    <p className="Kyc_p">{item.kyc_status}</p>
                  ) : item.kyc_status == "Pending" ? (
                    <p className="Kyc_Pending">{item.kyc_status}</p>
                  ) : item.kyc_status == "Rejected" ? (
                    <p className="Kyc_reject">{item.kyc_status}</p>
                  ) : item.kyc_status == "Not upload" ? (
                    <p className="Kyc_upload">{item.kyc_status}</p>
                  ) : null}
                </td>
                <td className="Kyc_table">
                  {item.status === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{item.status}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{item.status}</span>
                      </div>
                    </>
                  )}
                </td>
                <td>
                  <div className="actions">
                    <Link to={`/admin/customer/customer-view/${item.id}`}>
                      <img src={eye}></img>
                    </Link>
                    <Link to={`/admin/customer/customer-update/${item.id}`}>
                      <img src={edit}></img>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  return (
    <div className="container-fluid px-4 Driver">
      <div className="title px-1 mt-4">
        <h4>
          <span className="titile_span">Customer List</span>
          <Link
            to="/admin/customer/customer-add"
            className="btn btn-primary admin float-end"
          >
            Add Customer
          </Link>
        </h4>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              table="table-to-xls"
              filename="Customer"
              sheet="Total-Order"
              buttonText="Export Excel"
              className="btn btn-success admin"
            />
          </div>
        </div>
        <div className="card-body px-3 mt-3 mb-3">
          <div class="table-responsive">
            <table
              className="table table-bordered table-striped border-start border-end"
              id="table-to-xls"
            >
              <thead>
                <tr className="head_color">
                  <th>S NO</th>
                  <th className="Customer_table">
                    Customer ID
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("customer_sid")}
                    />
                  </th>
                  <th className="Customer_table">
                    Customer Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("customer_sid")}
                    />
                  </th>
                  <th className="Customer_table emails">
                    Email ID
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("email")}
                    />
                  </th>
                  <th className="Customer_table">
                    Phone Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("contact_number")}
                    />
                  </th>
                  <th className="Customer_table">
                    KYC Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("kyc_status")}
                    />
                  </th>
                  <th className="Customer_table">
                    Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("status")}
                    />
                  </th>
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{customer}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Customer;
