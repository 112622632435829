import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import swal from "sweetalert";
import { ErrorMessage } from "../../Components/Customalert/Customalert";

const Editvendoruser = () => {
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const user_id = localStorage.getItem("vendor_id");
  const [editupdate, seteditupdate] = useState({
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // .....................showPassword....................................
  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  // ...................profile edit api get.......................

  useEffect(() => {
    axios
      .get(`/vendor/profile/${user_id}`)
      .then((res) => {
        if (res.data.status === true) {
          seteditupdate(res.data.vendor);
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  // .....................handleInput..........................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        seteditupdate({
          ...editupdate,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "contact_number":
        seteditupdate({
          ...editupdate,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      default:
        seteditupdate({
          ...editupdate,
          [name]: value,
        });
        break;
    }
  };

  // ......................validate................................

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    if (!values.contact_number) {
      errors.contact_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.contact_number)) {
      errors.contact_number = "Phone Number must be 10 digits !";
    }
    return errors;
  };

  const submitvendor = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(editupdate));
    setIsSubmit(true);
    if (
      editupdate.password &&
      editupdate.password.length > 0 &&
      editupdate.password !== editupdate.password_confirmation
    ) {
      swal("Passwords Miss match", "warning");
    } else {
      const formData = new FormData();
      formData.append("userid", user_id);
      formData.append("first_name", editupdate.first_name);
      formData.append("last_name", editupdate.last_name);
      formData.append("contact_number", editupdate.contact_number);
      formData.append("password", editupdate.password);
      formData.append(
        "password_confirmation",
        editupdate.password_confirmation
      );
      axios
        .post(`/vendor/vendor_edit_profile`, formData)
        .then((res) => {
          if (res.data.status === true) {
            swal("success", res.data.message, "success").then(() => {
              history.push("/vendor/user");
            });
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch((error) => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // .................................isSubmit.............................
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  return (
    <section className="user">
      <div className="container-fluid px-4">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span">Edit Profile</span>
            <Link to="/vendor/user" className="btn btn-primary admin float-end">
              Go Back
            </Link>
          </h4>
        </div>
        <div className="card mt-4">
          <div className="card-body">
            <form>
              <div className="row px-5">
                <div className="col-md-6 form-group mb-4">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={editupdate.first_name}
                    onChange={handleInput}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 form-group mb-4">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={editupdate.last_name}
                    onChange={handleInput}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 form-group mb-4">
                  <label>Email ID</label>
                  <input
                    type="text"
                    placeholder="Email ID"
                    disabled
                    name="email"
                    value={editupdate.email}
                    onChange={handleInput}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 form-group mb-4">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    name="contact_number"
                    value={editupdate.contact_number}
                    onChange={handleInput}
                    className="form-control"
                  />
                  {errors.contact_number && <p>{errors.contact_number}</p>}
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>Password</label>
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      onChange={handleInput}
                      name="password"
                      value={editupdate.password}
                      className="form-control"
                    />
                    <a
                      onClick={toggleShowPassword}
                      className="password-toggle-btn "
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                    </a>
                  </div>
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label>Confirm Password</label>
                  <div className="password-input">
                    <input
                      type={comfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      onChange={handleInput}
                      value={editupdate.password_confirmation}
                      name="password_confirmation"
                      className="form-control"
                    />
                    <a
                      onClick={toggleShowcomfirmPassword}
                      className="password-toggle-btn "
                    >
                      {comfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </a>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    className="btn btn-secondary float-end px-5 p-2"
                    onClick={submitvendor}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Editvendoruser;
