import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useAlert } from "react-alert";
import Radio from "@mui/material/Radio";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../../admin/admincard.css";
import swal from "sweetalert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ErrorMessage } from "../../Customalert/Customalert";

const UpdateVendor = (props) => {
  const { state } = useContext(UserContext);
  const [city, setcity] = useState([]);
  const alert = useAlert();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const url = process.env.REACT_APP_API_KEY_link_url;
  let arr = Array.apply(null, { length: 11 }).map(Number.call, Number);
  const vendor_update = props.match.params.id;

  const [updatevendor, setupdatevendor] = useState({
    first_name: "",
    contact_number: "",
    secondary_number: "",
    address: "",
    email: "",
    password: "",
    password_confirmation: "",
    country_id: "",
    priority: "",
    commission_per: "",
    gst_doc: "",
    gst_number: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ...................showPassword....................

  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  // .......................selectedValue..............................

  const [selectedValue, setSelectedValue] = React.useState();

  const handleChangee = (event) => {
    setSelectedValue(parseInt(event.target.value));
    if (selectedValue === 1) {
      return "true";
    } else {
      return "false";
    }
  };

  useEffect(() => {
    setSelectedValue(updatevendor.status);
  }, [updatevendor]);

  // ..........................Docments...................................

  const [gstdoc, setgstdoc] = useState("");
  const [pandoc, setpandoc] = useState("");

  function gstdocChange(event) {
    setgstdoc(event.target.files[0]);
  }

  function pandocChange(event) {
    setpandoc(event.target.files[0]);
  }

  //  .....................handleInput..........................
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setupdatevendor({
          ...updatevendor,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
        setupdatevendor({
          ...updatevendor,
          [name]: value,
        });
        break;
      case "priority":
      case "state_id":
      case "city_id":
        setupdatevendor({
          ...updatevendor,
          [name]: value,
        });
        break;
      case "address":
        setupdatevendor({
          ...updatevendor,
          [name]: value,
        });
        break;
      case "contact_number":
      case "secondary_number":
        setupdatevendor({
          ...updatevendor,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      case "commission_per":
        setupdatevendor({
          ...updatevendor,
          [name]: value.replace(/\D/g, "").slice(0, 2),
        });
        break;
      case "gst_number":
        setupdatevendor({
          ...updatevendor,
          [name]: value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15),
        });
        break;
      default:
        setupdatevendor({
          ...updatevendor,
          [name]: value.replace(/[^a-zA-Z0-9@\#$.,\s]/g, ""),
        });
        break;
    }
  };

  // ........................validate............................

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    const gstRegex = /^[a-zA-Z0-9]{15}$/;
    const commRegex = /^\d{2}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "First Name is required !";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required !";
    }

    if (!values.email) {
      errors.email = "Email id is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }

    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password Confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }

    if (!values.company_name) {
      errors.company_name = "Company Name is required !";
    }

    if (!values.address) {
      errors.address = "Address is required !";
    }

    if (!values.contact_number) {
      errors.contact_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.contact_number)) {
      errors.contact_number = "Phone Number must be 10 digits !";
    }
    if (!values.secondary_number) {
      errors.secondary_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.secondary_number)) {
      errors.secondary_number = "Phone Number must be 10 digits !";
    }
    if (!values.gst_number) {
      errors.gst_number = "GST Number is required !";
    } else if (!gstRegex.test(values.gst_number)) {
      errors.gst_number = "GST Number must be 15 alphanumeric characters !";
    }
    if (!values.commission_per) {
      errors.commission_per = "Commission  is required !";
    } else if (!commRegex.test(values.commission_per)) {
      errors.commission_per = "Commission  must be 2 digits !";
    }
    if (!gstdoc) {
      errors.gstdoc = "GST Document is required !";
    }

    if (!pandoc) {
      errors.pandoc = "PAN Document is required !";
    }

    if (!values.city_id) {
      errors.city_id = "City is required !";
    }
    if (!values.priority) {
      errors.priority = "Priority is required !";
    }
    if (!values.state_id) {
      errors.state_id = "State is required !";
    }

    if (!selectedValue) {
      errors.status = "Status is required !";
    }
    return errors;
  };

  // .......................edit vendor-show api ................................

  useEffect(() => {
    const getCustomer = async () => {
      const res = await axios.get(`/admin/vendor-show/${vendor_update}`);
      if (res.data.status === true) {
        setupdatevendor(res.data.vendor);
      }
    };

    try {
      getCustomer();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  // .....................submit.........................

  const submitvendor = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(updatevendor));
    setIsSubmit(true);
    if (
      updatevendor.password &&
      updatevendor.password.length > 0 &&
      updatevendor.password !== updatevendor.password_confirmation
    ) {
      swal("Passwords Miss Match", "warning");
    } else {
      const formData = new FormData();
      formData.append("first_name", updatevendor.first_name);
      formData.append("contact_number", updatevendor.contact_number);
      formData.append("secondary_number", updatevendor.secondary_number);
      formData.append("email", updatevendor.email);
      formData.append("password", updatevendor.password);
      formData.append(
        "password_confirmation",
        updatevendor.password_confirmation
      );
      formData.append("status", selectedValue);
      formData.append("city_id", updatevendor.city_id);
      formData.append("state_id", updatevendor.state_id);
      formData.append("userid", updatevendor.id);
      formData.append("priority", updatevendor.priority);
      formData.append("commission_per", updatevendor.commission_per);
      formData.append("gst_number", updatevendor.gst_number);
      formData.append("address", updatevendor.address);
      formData.append("gst_doc", gstdoc);

      if (gstdoc != "") {
        formData.append("gst_doc", gstdoc.name);
      }
      formData.append("pan_doc", pandoc);
      if (pandoc != "") {
        formData.append("pan_doc", pandoc.name);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`/admin/vendor-update`, formData, config)
        .then((res) => {
          if (res.data.status === true) {
            setupdatevendor(res.data.vendor);
            swal("Updated", res.data.message, "success");
            history.push("/admin/vendor/vendorlist");
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch(() => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // .................isSubmit..........................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  // ..........city list get api ...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.status === false) {
          swal("Error", data.status, "error");
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // ........................PDF................................

  const getPreviewpan = () => {
    if (updatevendor.gst_doc != "") {
      return (
        <div className="pdt_child">
          <p>
            <a href={`${url}${updatevendor.gst_doc}`} target="_blank">
              <PictureAsPdfIcon />
            </a>
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  const getPreviewgst = () => {
    if (updatevendor.pan_doc != "") {
      return (
        <div className="pdt_child">
          <p>
            <a href={`${url}${updatevendor.pan_doc}`} target="_blank">
              <PictureAsPdfIcon />
            </a>
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Update Vendor</span>
          <Link
            to="/admin/vendor/vendorlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vendor Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  placeholder="Vendor Name"
                  name="first_name"
                  onChange={handleInput}
                  value={updatevendor.first_name}
                  className="form-control"
                />
                <p>{errors.first_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  State <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="state_id"
                  onChange={handleInput}
                  value={updatevendor.state_id}
                >
                  <option value="">--Select State--</option>
                  {state.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.state_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  City <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleInput}
                  value={updatevendor.city_id}
                >
                  <option value="">--Select City--</option>
                  {city.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.city_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Phone Number <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Phone Number"
                  name="contact_number"
                  onChange={handleInput}
                  value={updatevendor.contact_number}
                  className="form-control"
                />
                <p>{errors.contact_number}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Secondary Number <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Secondary Number"
                  name="secondary_number"
                  onChange={handleInput}
                  value={updatevendor.secondary_number}
                  className="form-control"
                />
                <p>{errors.secondary_number}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Address <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={updatevendor.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  GST Number <span className="star_icon">*</span>
                </label>
                <input
                  name="gst_number"
                  type="text"
                  placeholder="GST Number"
                  value={updatevendor.gst_number}
                  onChange={handleInput}
                  className="form-control"
                />
                <p>{errors.gst_number}</p>
              </div>

              <div className="col-md-6 form-group mb-3 pdf">
                <label>GST Document </label>
                <input
                  type="file"
                  placeholder="Gst Doument"
                  name="gst_doc"
                  onChange={gstdocChange}
                  className="form-control"
                />
                {getPreviewgst()}
                {/* <p>{errors.gstdoc}</p> */}
              </div>
              <div className="col-md-6 form-group mb-3 pdf">
                <label>PAN Document</label>
                <input
                  type="file"
                  placeholder="Gst Doument"
                  name="pan_doc"
                  onChange={pandocChange}
                  className="form-control"
                />
                {getPreviewpan()}
                {/* <p>{errors.pandoc}</p> */}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Priority <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  value={updatevendor.priority}
                  name="priority"
                  onChange={handleInput}
                  placeholder="Priority"
                >
                  <option value="">--Select Priority--</option>
                  {arr.map((item) => {
                    return <option>{item}</option>;
                  })}
                </select>
                <p>{errors.priority}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Commission <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Commission"
                  name="commission_per"
                  onChange={handleInput}
                  value={updatevendor.commission_per}
                  className="form-control"
                />
                <p>{errors.commission_per}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Email ID <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Email ID"
                  disabled
                  name="email"
                  onChange={handleInput}
                  value={updatevendor.email}
                  className="form-control"
                />
                <p>{errors.email}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>password </label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleInput}
                    value={updatevendor.password}
                    name="password"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowPassword}
                    className="password-toggle-btn "
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                {/* <p>{errors.password}</p> */}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Confirm password</label>
                <div className="password-input">
                  <input
                    type={comfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleInput}
                    value={updatevendor.password_confirmation}
                    name="password_confirmation"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowcomfirmPassword}
                    className="password-toggle-btn "
                  >
                    {comfirmPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                {/* <p>{errors.password_confirmation}</p> */}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === 1}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "true" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === 0}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "false" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  name="id"
                  onChange={handleInput}
                  value={updatevendor.id}
                  className="form-control"
                />
              </div>
              <div className="col-md-12 mt-4">
                <button
                  type="submit"
                  className="btn btn-secondary float-end px-5 p-2"
                  onClick={submitvendor}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateVendor;
