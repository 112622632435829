import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ErrorMessage } from "../../Customalert/Customalert";

const Customerview = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const history = useHistory();
  const [view, setview] = useState(false);
  const [approve, setapprove] = useState(false);
  const [driverview, setdriverview] = useState({
    customer_sid: "",
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    gst_number: "",
    kyc_status: "",
    city: "",
    status_name: "",
    company_name: "",
    profile_photo_path: "",
    contact_number: "",
    pan_doc: "",
    aadhaar_doc: "",
    gst_doc: "",
    id: "",
  });
  // .......................kyc_status Number...............................

  const [approval, setapproval] = useState({
    kyc_status: "2",
  });
  const [Reject, setreject] = useState({
    kyc_status: "1",
  });

  // ...................isButtonDisabled.........................

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [rejectDisabled, setRejectDisabled] = useState(false);

  // ..........................customer-show  get api..............................

  useEffect(() => {
    const customer_show = props.match.params.id;
    const fetchCustomerData = async () => {
      const res = await axios.get(`/admin/customer-show/${customer_show}`);
      if (res.data.status === true) {
        setdriverview(res.data.customer);
        if (res.data.customer.kyc_status !== "Not Yet Uploaded") {
          setview(true);
        } else {
          setview(false);
        }
        if (res.data.customer.kyc_status == "Pending") {
          setapprove(true);
        } else if (res.data.customer.kyc_status == "Not Yet Uploaded") {
          setapprove(true);
        } else {
          setapprove(false);
        }
      }
    };

    try {
      fetchCustomerData();
    } catch (error) {
      ErrorMessage({ message: "Something wrong !" });
    }
  }, []);

  const submitadmin = async () => {
    setIsButtonDisabled(true);
    const formdata = new FormData();
    formdata.append("id", driverview.id);
    formdata.append("kyc_status", approval.kyc_status);
    try {
      const response = await axios.post(`/admin/kyc-approve`, formdata);
      if (response.data.status === true) {
        history.push("/admin/customer");
        swal("Approved", response.data.message, "success");
      } else if (response.data.status === false) {
        swal("Error", response.data.message, "error");
      }
    } catch (error) {
      ErrorMessage({ message: "Something wrong !" });
    }
    setIsButtonDisabled(false);
  };

  const rejectcustomer = () => {
    setRejectDisabled(true);
    swal({
      text: "Reason for Rejection:",
      content: "input",
      attributes: {
        maxlength: 100,
      },
      buttons: {
        cancel: true,
        confirm: {
          text: "Submit",
          closeModal: false,
        },
      },
    }).then((result) => {
      const formdata = new FormData();
      formdata.append("id", driverview.id);
      formdata.append("kyc_reject_message", result);
      try {
        axios.post(`/admin/kyc-reject`, formdata).then((res) => {
          if (res.data.status === true) {
            history.push("/admin/customer");
            swal("Rejected", res.data.message, "success");
          } else if (res.data.status === false) {
            swal("Error", res.data.message, "error");
          }
        });
      } catch (error) {
        ErrorMessage({ message: "Something wrong !" });
      }
      setRejectDisabled(false);
    });
  };

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">
            Customer View : {driverview.customer_sid}
          </span>
          <Link
            to="/admin/customer/customerlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-12 view-main">
              <div className="first-col">
                <h6>First Name</h6>
                <h6>Last Name</h6>
                <h6>Address</h6>
                <h6>Email ID</h6>
                <h6>Phone Number</h6>
                <h6>GST Number</h6>
                <h6>Company Name</h6>
                <h6>Status</h6>
              </div>
              <div className="first-col-sibling">
                <small> {driverview.first_name} </small>
                <small> {driverview.last_name} </small>
                <small>
                  {driverview.address ? driverview.address : "null"}
                </small>
                <small> {driverview.email} </small>
                <small> {driverview.contact_number} </small>
                <small>
                  {driverview.gst_number ? driverview.gst_number : "null"}
                </small>
                <small> {driverview.company_name} </small>
                <small>
                  {driverview.status_name === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{driverview.status_name}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{driverview.status_name}</span>
                      </div>
                    </>
                  )}
                </small>
              </div>
              <div className="second-col">
                <h6>KYC Status</h6>
                <h6>PAN Document</h6>
                <h6>Aadhaar Document</h6>
                <h6>GST Document</h6>
                <h6>
                  {driverview.kyc_status == "Reject" ? (
                    <>KYC Rejected Reason</>
                  ) : (
                    <></>
                  )}
                </h6>
              </div>
              <div className="first-col-sibling">
                <small>
                  {driverview.kyc_status == "Approved" ? (
                    <p className="Kyc_p">{driverview.kyc_status}</p>
                  ) : driverview.kyc_status == "Pending" ? (
                    <p className="Kyc_Pending">{driverview.kyc_status}</p>
                  ) : driverview.kyc_status == "Reject" ? (
                    <p className="Kyc_reject">{driverview.kyc_status}</p>
                  ) : driverview.kyc_status === "Not Yet Uploaded" ? (
                    <p className="Kyc_upload">{driverview.kyc_status}</p>
                  ) : null}
                </small>
                {view ? (
                  <>
                    <small>
                      <a href={`${url}${driverview.pan_doc}`} target="_blank">
                        <PictureAsPdfIcon />
                      </a>
                    </small>
                    <small>
                      <a
                        href={`${url}${driverview.aadhaar_doc}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PictureAsPdfIcon />
                      </a>
                    </small>

                    <small>
                      <a
                        href={`${url}${driverview.gst_doc}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PictureAsPdfIcon />
                      </a>
                    </small>
                  </>
                ) : (
                  <>
                    <small>None</small>
                    <small>None</small>
                    <small>None</small>
                  </>
                )}

                {driverview.kyc_status == "Reject" ? (
                  <>
                    <small className="message_text">
                      {" "}
                      {driverview.kyc_reject_message}
                    </small>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* .........................approve button........................... */}
            {approve ? (
              <>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    value={Reject.kyc_reject_message}
                    disabled={rejectDisabled}
                    onClick={rejectcustomer}
                    className="btn btn-danger"
                  >
                    {rejectDisabled ? "Loading..." : "Reject"}
                  </button>
                  <button
                    onClick={submitadmin}
                    value={approval.kyc_status}
                    disabled={isButtonDisabled}
                    className="btn btn-success"
                  >
                    {isButtonDisabled ? "Loading..." : "Approve"}
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customerview;
