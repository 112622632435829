import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import "../../../Components/admin/Driver/driver.scss";
import eye from "../../../assets/vendor/assets/img/eye.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const VendorOrder = () => {
  const [loading, setloading] = useState(true);
  const [orderlist, setorderlist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [fromValues, setFromValues] = useState(null);
  const [toValues, setToValues] = useState(null);

  // ..................TablePagination.........................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ......................Table sort...............................
  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...orderlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [orderlist, sort]);

  // ...................Table search ....................................
  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.order_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.loading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(item.vendor_earning)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.container_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.order_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.booking_status_id.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // ....................all-vendor-list...........................

  useEffect(() => {
    const fetchvendorData = async () => {
      try {
        const res = await axios.get("/vendor/all-vendor-list");
        if (res.data.status === true) {
          setorderlist(res.data.VendorOrderList);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchvendorData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .....................fromValues.............................
  const handleDateChange = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    setFromValues(formattedDate);
  };

  // ........................toValues..................................

  const handleDateChanges = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    setToValues(formattedDate);
  };

  // .....................submitReports......................
  const submitReports = (e) => {};

  // ..........................order_list...................................

  let order_list = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    order_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.order_id}</td>
                <td>
                  <div className="pro-name">
                    {item.driver_first_name}
                    {item.driver_first_name == "" ? (
                      <></>
                    ) : (
                      <>
                        / <span>{item.driver_last_name}</span>
                      </>
                    )}
                  </div>
                </td>
                <td>{item.loading}</td>
                <td>{item.unloading}</td>
                <td>
                  {item.type} -{item.truck_capacity_name}
                </td>
                <td>{item.vehicle_number}</td>
                <td>{item.container_number}</td>
                <td>{item.order_amount}</td>
                <th>{item.order_date}</th>
                <td>{item.booking_status_id}</td>
                <td>{item.payment_status}</td>
                <td>{item.vendor_earning}</td>

                <td>
                  <div className="actions">
                    <Link to={`/vendor/orderview/${item.id}`}>
                      <img src={eye} />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr>
          <td colSpan="15" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  const TotalCount = filteredData.reduce((total, item) => {
    return total + item.order_amount;
  }, 0);

  const TotalearningCount = filteredData.reduce((total, item) => {
    const earning = parseFloat(item.vendor_earning);
    return isNaN(earning) ? total : total + earning;
  }, 0);

  return (
    <div className="container-fluid px-4 Driver">
      <div className="title px-1 mt-4 ">
        <h4>Order List</h4>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="card-body  px-4">
          <div className="table-responsive">
            <table className="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th className="Customer_table">S No</th>
                  <th className="Customer_table">
                    Job Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Driver Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Loading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("loading")}
                    />
                  </th>
                  <th className="Customer_table">
                    Unloading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("unloading")}
                    />
                  </th>
                  <th className="Customer_table">
                    Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("type")}
                    />
                  </th>
                  <th className="Customer_table">
                    Vehicle Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vehicle_number")}
                    />
                  </th>
                  <th className="Customer_table">
                    Container Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("container_number")}
                    />
                  </th>
                  <th className="Customer_table">
                    Order Amount <p>({TotalCount})</p>
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_amount")}
                    />
                  </th>
                  <th className="Customer_table">
                    Booking Date
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_date")}
                    />
                  </th>
                  <th className="Customer_table">
                    Booking Status ID
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("booking_status_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Payment Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("payment_status")}
                    />
                  </th>
                  <th className="Customer_table">
                    Total Earning <p>({TotalearningCount})</p>
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vendor_earning")}
                    />
                  </th>
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{order_list}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VendorOrder;
