import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ErrorMessage } from "../../Components/Customalert/Customalert";

const User = () => {
  const [user, setuser] = useState([]);
  const user_id = localStorage.getItem("vendor_id");

  // ....................profile api get........................
  useEffect(() => {
    axios
      .get(`/vendor/profile/${user_id}`)
      .then((res) => {
        if (res.data.status === true) {
          setuser(res.data.vendor);
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  return (
    <section className="user">
      <div className="container-fluid px-4">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span">User Profile</span>
            <Link
              to="/vendor/edituser"
              className="btn btn-primary admin float-end"
            >
              Edit Profile
            </Link>
          </h4>
        </div>
        <div className="card mt-4 mb-5">
          <div className="card-body">
            <div className="row px-5">
              <div className="col-md-6 form-group mb-4">
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={user.first_name}
                  disabled
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-4">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  disabled
                  name="last_name"
                  value={user.last_name}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-4">
                <label>Email</label>
                <input
                  type="text"
                  disabled
                  placeholder="Email"
                  name="email"
                  value={user.email}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-4">
                <label>Phone Number</label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="contact_number"
                  disabled
                  value={user.contact_number}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default User;
