import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../User/User.scss";
import axios from "axios";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const User = () => {
  const [user, setuser] = useState([]);
  const user_id = localStorage.getItem("id");

  // ........................profile api get...................................

  useEffect(() => {
    axios
      .get(`/admin/profile/${user_id}`)
      .then((res) => {
        if (res.data.status === true) {
          setuser(res.data.user);
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  return (
    <section className="user">
      <div className="container-fluid px-4">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span">User Profile</span>
            <Link
              to="/admin/edituser"
              className="btn btn-secondary btn-bg float-end"
            >
              Edit Profile
            </Link>
          </h4>
        </div>
        <div className="card mt-4 mb-5">
          <div className="card-body px-3 mt-3 mb-3">
            <div className="row px-5">
              <div className="col-md-6 form-group mb-4">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  name="Name"
                  disabled
                  value={user.name}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-4">
                <label>Email ID</label>
                <input
                  type="text"
                  placeholder="Email ID"
                  name="email"
                  value={user.email}
                  disabled
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-4">
                <label>Phone Number</label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="mobileno"
                  disabled
                  value={user.mobileno}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default User;
