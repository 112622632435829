import React from "react";
import Admincard from "./Card-view/admincard";
import "./admincard.css";
import Approval from "./Card-view/Approval";
import FinalCompletion from "./Card-view/FinalCompletion";
import Exportorder from "./Card-view/Exportorder";

const Dashboard = () => {
  return (
    <div className="row px-4 py-4 admin-dash">
      <div className="heads">
        <h6>Dashboard</h6>
      </div>
      <Admincard />
      <>
        <Approval />
      </>
      <>
        <Exportorder />
      </>
      <>{/* <FinalCompletion /> */}</>
    </div>
  );
};

export default Dashboard;
