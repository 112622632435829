import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import axios from "axios";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import payment_img from "../../../payment_img.png";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Paymentreport = () => {
  const [loading, setloading] = useState(true);
  const [Paymentreports, setPaymentreports] = useState([]);
  const [vendorlist, setVendorList] = useState([]);
  const [customerlist, setCustomerList] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [respons_list, setrespons_list] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [sussessmessage, setsussessmessage] = useState("");
  const [Payreportvalues, setPayreportvalues] = useState({
    fromValues: "",
    toValues: "",
    Vendor_id: "",
    Customer_id: "",
  });
  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // .....................TablePagination..................................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ........................Table sort...............................
  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...Paymentreports].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [Paymentreports, sort]);

  //  ......................Table search...................................
  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.admin_ref_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customer_gst.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(item.gst_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.others)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.freight_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.total)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_mobile
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .............................handleInput.....................................
  const [mindate, setMindate] = useState("");

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "fromValues":
        setPayreportvalues({
          ...Payreportvalues,
          [name]: new Date(value).toISOString().split("T")[0],
        });
        setMindate(new Date(value).toISOString().split("T")[0]);
        break;
      case "toValues":
        setPayreportvalues({
          ...Payreportvalues,
          [name]: new Date(value).toISOString().split("T")[0],
        });
        break;
      case "Vendor_id":
      case "Customer_id":
        setPayreportvalues({
          ...Payreportvalues,
          [name]: value,
        });
        break;
      default:
        setPayreportvalues({
          ...Payreportvalues,
          [name]: value,
        });
        break;
    }
  };

  //  .........................validate.................................

  const validate = (values) => {
    const errors = {};
    if (!values.Vendor_id) {
      errors.Vendor_id = "Vendor Name is required !";
    }
    if (!values.Customer_id) {
      errors.Customer_id = "Customer Name is required !";
    }
    if (!values.fromValues) {
      errors.fromValues = "From Date is required !";
    }
    if (!values.toValues) {
      errors.toValues = "To Date is required !";
    }

    return errors;
  };

  //  .......................vendor_list api get................................
  useEffect(() => {
    const fetchVendorList = async () => {
      try {
        const response = await axios.get("/admin/vendor_list");
        const { status, vendor_list, message } = response.data;

        if (status) {
          setVendorList(vendor_list);
        } else if (response.data.status === false) {
          swal({
            title: "Error",
            text: response.data.message,
            icon: "warning",
          });
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchVendorList();
  }, []);

  //  .......................customer_list api get................................
  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const response = await axios.get("/admin/customer_list");
        const { status, customer_list, message } = response.data;

        if (status) {
          setCustomerList(customer_list);
        } else {
          swal({
            title: "Error",
            text: response.data.message,
            icon: "warning",
          });
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchCustomerList();
  }, []);
  // ..................submitReports.............
  const submitReports = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(Payreportvalues);
    setErrors(errors);
    setIsSubmit(true);
    const formData = new FormData();
    formData.append("from_date", Payreportvalues.fromValues);
    formData.append("to_date", Payreportvalues.toValues);
    formData.append("vendor_id", Payreportvalues.Vendor_id);
    formData.append("customer_id", Payreportvalues.Customer_id);

    try {
      axios.post(`/admin/payment_report`, formData).then((res) => {
        setrespons_list(res.data.status);
        if (res.data.status === true) {
          setPaymentreports(res.data.payment_report);
        } else if (res.data.status == false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      });
      setloading(false);
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ..................................isSubmit............................
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  //  .........................Payment list.........................
  let Paymentlist = "";

  if (loading) {
  } else {
    Paymentlist =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>
                  <div className="pro-name">
                    <span>{item.job_number}/</span>
                    {item.order_date_in}/<br></br>
                    {item.type} -{item.truck_capacity_name}
                  </div>
                </td>
                <td>
                  <div className="pro-name">
                    <span>{item.customer_name}/</span>
                    {item.company_name}
                    <br></br>
                    {item.company_address}/{item.customer_mobile}/
                    {item.customer_gst}
                  </div>
                </td>
                <td>{item.admin_ref_no}</td>
                <td>{item.received_date_and_time}</td>
                <td>{item.freight_amount}</td>
                <td>{item.gst_amount}</td>
                <td>{item.others}</td>
                <td>{item.total}</td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  const TotalCount = filteredData.reduce((total, item) => {
    return total + item.total;
  }, 0);

  const TotalFreightCount = filteredData.reduce((total, item) => {
    return total + item.freight_amount;
  }, 0);

  const TotalOtherCount = filteredData.reduce((total, item) => {
    return total + item.others;
  }, 0);

  return (
    <>
      <div className="container-fluid px-4">
        <div className="title px-1 mt-4 ">
          <h4>Payment Reports List</h4>
        </div>
        <div className="card mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            <form>
              <div className="row px-5">
                <div className="col-md-3 form-group mb-3">
                  <label>
                    From Date <span className="star_icon">*</span>
                  </label>
                  <input
                    type="date"
                    name="fromValues"
                    value={Payreportvalues.fromValues}
                    onChange={handleInput}
                    className="form-control"
                  />
                  <p>{errors.fromValues}</p>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <label>
                    To Date <span className="star_icon">*</span>
                  </label>
                  <input
                    type="date"
                    name="toValues"
                    value={Payreportvalues.toValues}
                    onChange={handleInput}
                    min={mindate}
                    className="form-control"
                  />
                  <p>{errors.toValues}</p>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <label>
                    Customer List <span className="star_icon">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="Customer_id"
                    value={Payreportvalues.Customer_id}
                    onChange={handleInput}
                  >
                    <option value="">--Select Customer--</option>
                    <option value={"All"}>All</option>
                    {customerlist.map((itm) => {
                      return (
                        <option value={itm.id} key={itm.id}>
                          {itm.first_name}
                        </option>
                      );
                    })}
                  </select>
                  <p>{errors.Customer_id}</p>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <label>
                    Vendor List <span className="star_icon">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="Vendor_id"
                    value={Payreportvalues.Vendor_id}
                    onChange={handleInput}
                  >
                    <option value="">--Select Vendor--</option>
                    <option value={"All"}>All</option>
                    {vendorlist.map((itm) => {
                      return (
                        <option value={itm.id} key={itm.id}>
                          {itm.first_name}
                        </option>
                      );
                    })}
                  </select>
                  <p>{errors.Vendor_id}</p>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3 ">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={submitReports}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? "Loading..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <>
            {respons_list === true ? (
              <>
                {" "}
                <div className="row px-5 p-0 mt-3">
                  <div className="card mt-4 mb-4  py-4">
                    <div className="search-box px-4 cust-search px-3">
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    <div className="card-body px-4">
                      <div class="table-responsive">
                        <table className="table table-bordered table-striped border-start border-end">
                          <thead>
                            <tr className="head_color">
                              <th>S No</th>
                              <th className="Customer_table">
                                Job Details
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() => handleSort("job_number")}
                                />
                              </th>
                              <th className="emails Customer_table">
                                Customer Details
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() => handleSort("company_name")}
                                />
                              </th>
                              <th className="Customer_table">
                                Refernce Number (UPR)
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() => handleSort("admin_ref_no")}
                                />
                              </th>
                              <th className="emails Customer_table">
                                Received Date
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() =>
                                    handleSort("received_date_and_time")
                                  }
                                />
                              </th>
                              <th className="Customer_table">
                                Freight Amount (RS)<p>({TotalFreightCount})</p>
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() => handleSort("freight_amount")}
                                />
                              </th>
                              <th className="Customer_table">
                                GST <p>(12%)</p>
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() => handleSort("gst_amount")}
                                />
                              </th>
                              <th className="Customer_table">
                                Other Charges <p>({TotalOtherCount})</p>
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() => handleSort("others")}
                                />
                              </th>
                              <th className="Customer_table">
                                Total <p>({TotalCount})</p>
                                <ImportExportIcon
                                  className="Customer_table_child"
                                  onClick={() => handleSort("total")}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>{Paymentlist}</tbody>
                        </table>
                      </div>
                      {sortedData.length > 1 ? (
                        <>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="report_img">
                      <img src={payment_img} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};
export default Paymentreport;
