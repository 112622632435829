import axios from "axios";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import Order_img from "../../../Order.png";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";
// ............select filed...................
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    // style: {
    //   maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    // },
  },
};

const Allorderreport = () => {
  const [allreportlist, setallreportlist] = useState([]);
  const [reports_res, setreports_res] = useState([]);
  const [reports, setReports] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [errors, setErrors] = useState({});
  const [reportvalues, setreportvalues] = useState({
    fromValues: "",
    toValues: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // .................Select list.......................

  const names = [
    "Customer Details",
    "Vendor Details",
    "Driver Details",
    "Container Number",
    "Vehicle Number",
    "Loading Point",
    "Unloading Point",
    "Empty Return Point",
    "Empty Return Plan",
    "E-way Bill Details",
    "Trip Amount",
    "GST",
    "Others",
    "Total",
    "Job Order Details",
  ];

  // ......................Select condtion................................

  const searchCustomer = "Customer Details";
  const searchVendor = "Vendor Details";
  const searchDriver = "Driver Details";
  const searchContainer = "Container Number";
  const searchvehicle = "Vehicle Number";
  const searchloading = "Loading Point";
  const searchunoading = "Unloading Point";
  const searchEmptyReturn = "Empty Return Point";
  const searchEmptyReturnPlan = "Empty Return Plan";
  const searchEway = "E-way Bill Details";
  const searchtrip = "Trip Amount";
  const searchgst = "GST";
  const searchother = "Others";
  const searchtotal = "Total";
  const searchorderdetail = "Job Order Details";

  // ........................handleInput reportvalues ............................
  const [mindate, setMindate] = useState("");

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "fromValues":
        setreportvalues({
          ...reportvalues,
          [name]: new Date(value).toISOString().split("T")[0],
        });
        setMindate(new Date(value).toISOString().split("T")[0]);
        break;
      case "toValues":
        setreportvalues({
          ...reportvalues,
          [name]: new Date(value).toISOString().split("T")[0],
        });
        break;
      default:
        setreportvalues({
          ...reportvalues,
          [name]: value,
        });
        break;
    }
  };

  // .....................reports handleChange.....................

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("All") && reports.length === names.length) {
      setReports([]);
    } else if (value.includes("All")) {
      setReports(names);
    } else {
      setReports(value);
    }
  };

  // .....................reports validate....................

  const validate = (values) => {
    const errors = {};

    if (!values.fromValues) {
      errors.fromValues = "From Date is required !";
    }
    if (!values.toValues) {
      errors.toValues = "To Date is required !";
    }
    return errors;
  };

  // ........................TablePagination........................................

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // .................................Table sort..........................................
  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...allreportlist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [allreportlist, sort]);

  // ..................Table search.........................

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.job_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vendor_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.driver_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.container_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.loading_location
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.empty_return_plan
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.empty_returned.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.eway_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.eway_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.job_order_status
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.order_created_date
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.gst_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.trip_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.other_amount)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.total)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .......................final_all_report...........................

  const submitReports = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(reportvalues);
    setErrors(errors);
    const formData = new FormData();
    formData.append("from_date", reportvalues.fromValues);
    formData.append("to_date", reportvalues.toValues);

    try {
      axios
        .post(`admin/final_all_report`, formData)
        .then((res) => {
          setreports_res(res.data.status);
          if (res.data.status === true) {
            setallreportlist(res.data.all_report);
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch(() => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ........................allreports list...........................
  let allreports =
    filteredData.length > 0 ? (
      filteredData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          const serialNo = page * rowsPerPage + index + 1;
          return (
            <tr key={item.id}>
              <td>{serialNo}</td>
              <td>
                <div className="Customer_table pro-name">
                  <span>{item.job_number}/</span>
                  <br></br>
                  {item.order_created_date}
                  <br></br>
                  {item.type} - {item.truck_capacity_name}
                </div>
              </td>

              {reports.includes(searchCustomer) ? (
                <td>
                  <div className="pro-name">
                    <span> {item.customer_name}/</span>
                    {item.company_name} /{item.customer_mobile}
                  </div>
                </td>
              ) : null}

              {reports.includes(searchVendor) ? (
                <td>{item.vendor_first_name}</td>
              ) : null}

              {reports.includes(searchDriver) ? (
                <td>
                  <div className="pro-name">
                    <span>{item.driver_name}/</span>
                    {item.driver_mobile}
                  </div>
                </td>
              ) : null}

              {reports.includes(searchContainer) ? (
                <td>{item.container_number}</td>
              ) : null}

              {reports.includes(searchvehicle) ? (
                <td>{item.vehicle_number}</td>
              ) : null}

              {reports.includes(searchloading) ? (
                <td>{item.loading_location}</td>
              ) : null}

              {reports.includes(searchunoading) ? (
                <td>{item.unloading_location}</td>
              ) : null}

              {reports.includes(searchEmptyReturnPlan) ? (
                <td>{item.empty_return_plan}</td>
              ) : null}

              {reports.includes(searchEmptyReturn) ? (
                <td>{item.empty_returned}</td>
              ) : null}

              {reports.includes(searchEway) ? (
                <td>
                  <div className="pro-name">
                    <span>{item.eway_date}/</span> {item.eway_number}
                  </div>
                </td>
              ) : null}

              {reports.includes(searchtrip) ? (
                <td>{item.trip_amount}</td>
              ) : null}

              {reports.includes(searchgst) ? <td>{item.gst_amount}</td> : <></>}

              {reports.includes(searchother) ? (
                <td>
                  {item.other_amount}
                  {/* <div className="payment_icon">
                    <div className="actions">
                      <img
                        src={payment_icon}
                        onClick={() => handleOpen1(item)}
                      />
                    </div>
                  </div> */}
                </td>
              ) : null}

              {reports.includes(searchtotal) ? <td>{item.total}</td> : <></>}

              {reports.includes(searchorderdetail) ? (
                <td>
                  <div className="pro-name">
                    <span>{item.job_order_status}/</span>
                    {item.job_order_updated_date}
                  </div>
                </td>
              ) : null}
            </tr>
          );
        })
    ) : (
      <tr key="no-record">
        <td colSpan="18" className="py-3">
          No Record Found
        </td>
      </tr>
    );

  // ........................table count...........................

  const TotalCount = filteredData.reduce((total, item) => {
    return total + item.total;
  }, 0);

  const otherTotalCount = filteredData.reduce((total, item) => {
    return total + item.other_amount;
  }, 0);

  const thipTotalCount = filteredData.reduce((total, item) => {
    return total + item.trip_amount;
  }, 0);

  const GstCount = filteredData.reduce((total, item) => {
    return total + item.gst_amount;
  }, 0);

  return (
    <>
      <div className="container-fluid px-4">
        <div className="title px-1 mt-4 ">
          <h4>Detailed Reports list</h4>
        </div>

        <div className="card mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            <form>
              <div className="row filter mt-3 py-3 px-5 Reports">
                <div className="col-md-3 form-group mb-3">
                  <label>
                    Select From <span className="star_icon">*</span>
                  </label>
                  <input
                    type="date"
                    name="fromValues"
                    value={reportvalues.fromValues}
                    onChange={handleInput}
                    className="form-control"
                  />
                  <p>{errors.fromValues}</p>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <label>
                    Select To <span className="star_icon">*</span>
                  </label>
                  <input
                    type="date"
                    name="toValues"
                    value={reportvalues.toValues}
                    onChange={handleInput}
                    className="form-control"
                    min={mindate}
                  />
                  <p>{errors.toValues}</p>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Select Fields <span className="star_icon">*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={
                        reports.length === names.length
                          ? ["All", ...names]
                          : reports
                      }
                      onChange={handleChange}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key="All" value="All">
                        <Checkbox checked={reports.length === names.length} />
                        <ListItemText primary="All" />
                      </MenuItem>
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={reports.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-3 form-group mb-3">
                  <div class="d-grid gap-2 d-md-block  mt-3 mb-2 Order_bnt">
                    <button
                      onClick={submitReports}
                      disabled={isButtonDisabled}
                      className="btn btn-success "
                    >
                      {isButtonDisabled ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {reports_res === true ? (
            <div className="row px-5 p-0 mt-3">
              <div className="card mt-4 mb-4  py-4">
                <div className="search-box px-4 cust-search px-3">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="card-body px-4">
                  <div class="table-responsive">
                    <table
                      className="table table-bordered table-striped border-start border-end"
                      id="table-to-xls"
                    >
                      <thead>
                        <tr className="head_color">
                          <th>S NO</th>
                          <th className="Customer_table">
                            Job Number
                            <ImportExportIcon
                              className="Customer_table_child"
                              onClick={() => handleSort("job_number")}
                            />
                          </th>

                          {reports.includes(searchCustomer) ? (
                            <th className="Customer_table">
                              Customer Details
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("customer_name")}
                              />
                            </th>
                          ) : null}
                          {reports.includes(searchVendor) ? (
                            <th className="Customer_table">
                              Vendor Details
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("vendor_first_name")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchDriver) ? (
                            <th className="Customer_table">
                              Driver Details
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("driver_name")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchContainer) ? (
                            <th className="Customer_table">
                              Container Number
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("container_number")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchvehicle) ? (
                            <th className="Customer_table">
                              Vehicle Number
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("vehicle_number")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchloading) ? (
                            <th className="Customer_table">
                              Loading Point
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("loading_location")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchunoading) ? (
                            <th className="emails Customer_table">
                              Unloading Point
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("unloading_location")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchEmptyReturnPlan) ? (
                            <th className="Customer_table">
                              Empty Return Plan
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("empty_return_plan")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchEmptyReturn) ? (
                            <th className="Customer_table">
                              Empty Returned
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("empty_returned")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchEway) ? (
                            <th className="Customer_table">
                              E-Way Bill Details
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("eway_number")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchtrip) ? (
                            <th className="Customer_table">
                              Trip Amount <p>({thipTotalCount})</p>
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("trip_amount")}
                              />
                            </th>
                          ) : null}
                          {reports.includes(searchgst) ? (
                            <th className="Customer_table">
                              GST <p>(12%) {GstCount}</p>
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("gst_amountx")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchother) ? (
                            <th className="Customer_table">
                              Other <p>({otherTotalCount})</p>{" "}
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("other_amount")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchtotal) ? (
                            <th className="Customer_table">
                              Total <p>({TotalCount})</p>
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("total")}
                              />
                            </th>
                          ) : null}

                          {reports.includes(searchorderdetail) ? (
                            <th className="Customer_table">
                              Job Order Details
                              <ImportExportIcon
                                className="Customer_table_child"
                                onClick={() => handleSort("job_order_status")}
                              />
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>{allreports}</tbody>
                    </table>
                  </div>
                  {sortedData.length > 1 ? (
                    <>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="report_img">
                    <img src={Order_img} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Allorderreport;
