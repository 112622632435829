import axios from "axios";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import eye from "../../../assets/admin/assets/img/eye.svg";
import "../../../Components/admin/order/order.scss";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Ordermanagement = () => {
  const [loading, setloading] = useState(true);
  const [approval, setapproval] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // ................TablePagination.......................

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ............................Table Sort...............................

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...approval].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [approval, sort]);

  // .................Table search.....................................

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.order_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customer_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vendor_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.driver_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.loading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.container_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.vehicle_number)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.order_amount)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.order_date)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.payment_status)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.booking_status_id)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // .......................all-order-list api get..........................

  useEffect(() => {
    const fetchorderData = async () => {
      try {
        const response = await axios.post(`/admin/all-order-list`);
        if (response.data.status === true) {
          setapproval(response.data.OrderList);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };
    fetchorderData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .............................orders list ............................

  let orders_list = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    orders_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.order_id}</td>
                <td>
                  <div className="pro-name"> {item.customer_first_name}/</div>
                  <span>{item.customer_last_name}</span>
                </td>
                <td>
                  <div className="pro-name">{item.vendor_first_name}</div>
                </td>
                <td>
                  <div className="pro-name">
                    {item.driver_first_name}
                    {item.driver_first_name == "" ? (
                      <></>
                    ) : (
                      <>
                        / <span>{item.driver_last_name}</span>
                      </>
                    )}
                  </div>
                </td>
                <td>{item.loading}</td>
                <td>{item.unloading}</td>
                <td>
                  {item.type} -{item.vehicle_capacity}
                </td>
                <td>{item.vehicle_number}</td>

                <td>{item.container_number}</td>

                <td>{item.order_amount}</td>
                <td>{item.order_date}</td>
                <td>{item.booking_status_id}</td>
                <td>{item.payment_status}</td>
                <td>
                  <Link to={`/admin/order/approval-view/${item.id}`}>
                    <img src={eye} width="35px"></img>
                  </Link>
                </td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="14" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  // .................table TotalCount....................

  const TotalCount = filteredData.reduce((total, item) => {
    return total + item.order_amount;
  }, 0);

  return (
    <div className="container-fluid px-4 Driver">
      <div className="title px-1 mt-4 ">
        <span className="titile_span">
          <h4>All Reports</h4>
        </span>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              table="table-to-xls"
              filename="tablexls"
              sheet="Total-Order"
              buttonText="Export Excel"
              className="btn btn-success admin"
            />
          </div>
        </div>
        <div className="card-body px-3 mt-3 mb-3">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped border-start border-end"
              id="table-to-xls"
            >
              <thead>
                <tr className="head_color">
                  <th>S No</th>
                  <th className="Customer_table">
                    Job Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Customer Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("customer_first_name")}
                    />
                  </th>
                  <th className="Customer_table">
                    Vendor Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vendor_first_name")}
                    />
                  </th>
                  <th className="Customer_table">
                    Driver Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("driver_first_name")}
                    />
                  </th>
                  <th className="Customer_table">
                    Loading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("loading")}
                    />
                  </th>
                  <th className="Customer_table">
                    Unloading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("unloading")}
                    />
                  </th>
                  <th className="Customer_table">
                    Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("type")}
                    />
                  </th>
                  <th className="Customer_table">
                    Vehicle Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vehicle_number")}
                    />
                  </th>
                  <th className="Customer_table">
                    Container Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("container_number")}
                    />
                  </th>
                  <th className="Customer_table emails">
                    Order Amount<p>({TotalCount})</p>
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_amount")}
                    />
                  </th>
                  <th className="Customer_table">
                    Booking Date
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("order_date")}
                    />
                  </th>
                  <th className="Customer_table">
                    Booking Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("booking_status_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Payment Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("payment_status")}
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{orders_list}</tbody>
            </table>
          </div>
          {filteredData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Ordermanagement;
