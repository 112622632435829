import Profile from "../Components/admin/Profile";
import Dashboard from "../Components/admin/Dashboard";
import User from "../Components/admin/User/User";
import Edituser from "../Components/admin/User/Edituser";
import Customer from "../Components/admin/Customer/Cutomer";
import Customercreate from "../Components/admin/Customer/Customer-add";
import Customerupdate from "../Components/admin/Customer/Customer-update";
import Customerview from "../Components/admin/Customer/Customer-view";
import Customerkyc from "../Components/admin/Pendingkyc/Customer-kyc";
import Vendor from "../Components/admin/Vendor/Vendor";
import AddVendor from "../Components/admin/Vendor/Vendor-add";
import UpdateVendor from "../Components/admin/Vendor/Update-vendor";
import VendorView from "../Components/admin/Vendor/Vendor-view";
import VendorAllorder from "../Components/admin/Vendor/Vendor-allorder";
import Vehicle from "../Components/admin/Vehicle/Vehicle";
import Vehicleadd from "../Components/admin/Vehicle/Vehicle-add";
import Vehicleupdate from "../Components/admin/Vehicle/Vehicle-update";
import Vehicleview from "../Components/admin/Vehicle/Vehicle-view";
import Driver from "../Components/admin/Driver/Driver";
import Driveradd from "../Components/admin/Driver/Driver-add";
import Driverupdate from "../Components/admin/Driver/Driver-update";
import Driverview from "../Components/admin/Driver/Driver-view";
import AdminList from "../Components/admin/Adminlist/Admin";
import Admincreate from "../Components/admin/Adminlist/Admin-add";
import Updateadmin from "../Components/admin/Adminlist/Admin-update";
import Viewadmin from "../Components/admin/Adminlist/Admin-view";
import Ordermanagement from "../Components/admin/order/Order";
import Approvalview from "../Components/admin/Card-view/approval-view";
import Allreport from "../Components/admin/Reports/All-report";
import Paymentreport from "../Components/admin/PaymentReport/payment-report";
import JobSearch from "../Components/admin/JobSearch/Jobsearch";
import Citycreate from "../Components/admin/City/City-add";
import City from "../Components/admin/City/City";
import Cityupdate from "../Components/admin/City/City-update";
import Warehouse from "../Components/admin/Warehouse/warehouse";
import Warehousecreate from "../Components/admin/Warehouse/Warehouse-add";
import Warehouseupdate from "../Components/admin/Warehouse/Warehouse-update";
import Warehouseshow from "../Components/admin/Warehouse/Warehouse-show";
import Routecreate from "../Components/admin/Route/Route-add";
import RouteList from "../Components/admin/Route/Route";
import Routeupdate from "../Components/admin/Route/Route-update";
import Routeshow from "../Components/admin/Route/Route-view";
import Allorderreport from "../Components/admin/Reports/Detailed-reports";
import Import from "../Components/admin/Card-view/Import";
import Export from "../Components/admin/Card-view/Export";

const routes = [
  {
    path: "/admin/dashboard",
    exact: true,
    name: "dashboard",
    component: Dashboard,
  },
  { path: "/admin/user", exact: true, name: "user", component: User },
  {
    path: "/admin/edituser",
    exact: true,
    name: "edituser",
    component: Edituser,
  },

  { path: "/admin/profile", exact: true, name: "profile", component: Profile },

  {
    path: "/admin/customer/Customerlist",
    exact: true,
    name: "Customer",
    component: Customer,
  },
  {
    path: "/admin/customer/customer-add",
    exact: true,
    name: "Customercreate",
    component: Customercreate,
  },
  {
    path: "/admin/customer/customer-update/:id",
    exact: true,
    name: "Customerupdate",
    component: Customerupdate,
  },
  {
    path: "/admin/customer/customer-view/:id",
    exact: true,
    name: "Customerview",
    component: Customerview,
  },
  {
    path: "/admin/pendingkyc/customer-kyc",
    exact: true,
    name: "Customerkyc",
    component: Customerkyc,
  },
  {
    path: "/admin/vendor/vendorlist",
    exact: true,
    name: "Vendor",
    component: Vendor,
  },
  {
    path: "/admin/vendor/vendor-add",
    exact: true,
    name: "AddVendor",
    component: AddVendor,
  },
  {
    path: "/admin/vendor/vendor-update/:id",
    exact: true,
    name: "UpdateVendor",
    component: UpdateVendor,
  },
  {
    path: "/admin/vendor/vendor-view/:id",
    exact: true,
    name: "VendorView",
    component: VendorView,
  },
  {
    path: "/admin/vendor/vendor-allorder/:id",
    exact: true,
    name: "VendorAllorder",
    component: VendorAllorder,
  },

  {
    path: "/admin/vehicle/vehiclelist",
    exact: true,
    name: "Vehicle",
    component: Vehicle,
  },

  {
    path: "/admin/vehicle/vehicle-add",
    exact: true,
    name: "Vehicleadd",
    component: Vehicleadd,
  },

  {
    path: "/admin/vehicle/vehicle-update/:id",
    exact: true,
    name: "Vehicleupdate",
    component: Vehicleupdate,
  },

  {
    path: "/admin/vehicle/vehicle-view/:id",
    exact: true,
    name: "Vehicleview",
    component: Vehicleview,
  },

  {
    path: "/admin/driver/driverlist",
    exact: true,
    name: "Driver",
    component: Driver,
  },
  {
    path: "/admin/driver/driver-add",
    exact: true,
    name: "Driveradd",
    component: Driveradd,
  },
  {
    path: "/admin/driver/driver-update/:id",
    exact: true,
    name: "Driverupdate",
    component: Driverupdate,
  },
  {
    path: "/admin/driver/driver-view/:id",
    exact: true,
    name: "Driverview",
    component: Driverview,
  },

  {
    path: "/admin/adminlist",
    exact: true,
    name: "AdminList",
    component: AdminList,
  },
  {
    path: "/admin/adminlist/admin-add",
    exact: true,
    name: "Admincreate",
    component: Admincreate,
  },
  {
    path: "/admin/adminlist/admin-update/:id",
    exact: true,
    name: "Updateadmin",
    component: Updateadmin,
  },
  {
    path: "/admin/adminlist/admin-view/:id",
    exact: true,
    name: "Viewadmin",
    component: Viewadmin,
  },
  {
    path: "/admin/order/all-reports",
    exact: true,
    name: "Ordermanagement",
    component: Ordermanagement,
  },
  {
    path: "/admin/order/approval-view/:id",
    exact: true,
    name: "Approvalview",
    component: Approvalview,
  },

  {
    path: "/admin/card-view/import-list",
    exact: true,
    name: "Import",
    component: Import,
  },

  {
    path: "/admin/card-view/export-list",
    exact: true,
    name: "Export",
    component: Export,
  },

  {
    path: "/admin/reports/order-report",
    exact: true,
    name: "Allreport",
    component: Allreport,
  },
  {
    path: "/admin/reports/detailed-reportlist",
    exact: true,
    name: "Allorderreport",
    component: Allorderreport,
  },
  {
    path: "/admin/paymentreport/payment-reportlist",
    exact: true,
    name: "Paymentreport",
    component: Paymentreport,
  },
  {
    path: "/admin/jobsearch",
    exact: true,
    name: "JobSearch",
    component: JobSearch,
  },
  {
    path: "/admin/city/city-add",
    exact: true,
    name: "Citycreate",
    component: Citycreate,
  },
  {
    path: "/admin/city/citylist",
    exact: true,
    name: "City",
    component: City,
  },
  {
    path: "/admin/city/city-update/:id",
    exact: true,
    name: "Cityupdate",
    component: Cityupdate,
  },

  {
    path: "/admin/warehouse/warehouselist",
    exact: true,
    name: "Warehouse",
    component: Warehouse,
  },
  {
    path: "/admin/warehouse/warehouse-add",
    exact: true,
    name: "Warehousecreate",
    component: Warehousecreate,
  },
  {
    path: "/admin/warehouse/warehouse-update/:id",
    exact: true,
    name: "Warehouseupdate",
    component: Warehouseupdate,
  },
  {
    path: "/admin/warehouse/warehouse-show/:id",
    exact: true,
    name: "Warehouseshow",
    component: Warehouseshow,
  },
  {
    path: "/admin/route/routelist",
    exact: true,
    name: "RouteList",
    component: RouteList,
  },
  {
    path: "/admin/route/route-add",
    exact: true,
    name: "Routecreate",
    component: Routecreate,
  },
  {
    path: "/admin/route/route-update/:id",
    exact: true,
    name: "Routeupdate",
    component: Routeupdate,
  },
  {
    path: "/admin/route/route-view/:id",
    exact: true,
    name: "Routeshow",
    component: Routeshow,
  },
];

export default routes;
