import React, { useState, useEffect } from "react";
import "../admincard.css";
import axios from "axios";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Vehicle from "../../../assets/admin/assets/img/Container.svg";
import vendor from "../../../assets/admin/assets/img/admin.svg";
import driver from "../../../assets/admin/assets/img/driver.svg";
import total_earning from "../../../assets/admin/assets/img/total_earing.svg";
import admin from "../../../assets/admin/assets/img/vendor.svg";
import today_earning from "../../../assets/admin/assets/img/todayearning.svg";
import Total_orders from "../../../assets/admin/assets/img/Total_orders.svg";
import Import from "../../../assets/admin/assets/img/Import.svg";
import Export from "../../../assets/admin/assets/img/Export.svg";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Admincard = () => {
  const [card, setcard] = useState([]);

  useEffect(() => {
    axios
      .get(`/total-count`)
      .then((res) => {
        if (res.data.status === true) {
          setcard(res.data);
        }
      })
      .catch((err) => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  return (
    <>
      <div className="col-md-4 mb-3  cards-design">
        <Link to="/admin/customer/Customerlist">
          <div className="card">
            <div className="title">
              <h5>Total Customers</h5>
            </div>
            <div className="icons">
              <img src={admin} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.customercount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3 cards-design">
        <Link to="/admin/vendor/vendorlist">
          <div className="card ">
            <div className="title">
              <h5>Total Vendors</h5>
            </div>
            <div className="icons">
              <img src={vendor} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.vendorcount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3  cards-design">
        <Link to="/admin/vehicle/vehiclelist">
          <div className="card">
            <div className="title">
              <h5>Total Vehicles</h5>
            </div>
            <div className="icons">
              <img src={Vehicle} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.vehiclecount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3  cards-design">
        <Link to="/admin/driver/driverlist">
          <div className="card">
            <div className="title">
              <h5>Total Drivers</h5>
            </div>
            <div className="icons">
              <img src={driver} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.drivercount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3  cards-design">
        <Link to="/admin/PaymentReport/payment-reportlist">
          <div className="card">
            <div className="title">
              <h5>Total Earnings</h5>
            </div>
            <div className="icons">
              <img src={total_earning} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>
                <CurrencyRupeeIcon />
                {card.totalearning}
              </p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3 cards-design">
        <Link to="/admin/PaymentReport/payment-reportlist">
          <div className="card">
            <div className="title">
              <h5>Today Earnings</h5>
            </div>
            <div className="icons">
              <img src={today_earning} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>
                <CurrencyRupeeIcon />
                {card.todayearning}
              </p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3  cards-design">
        <Link to="/admin/order/all-reports">
          <div className="card">
            <div className="title">
              <h5>Total Orders</h5>
            </div>
            <div className="icons">
              <img src={Total_orders} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.totalOrdercount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3  cards-design">
        <Link to="/admin/card-view/import-list">
          <div className="card">
            <div className="title">
              <h5>Imports</h5>
            </div>
            <div className="icons">
              <img src={Import} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p> {card.importcount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3  cards-design">
        <Link to="/admin/card-view/export-list">
          <div className="card">
            <div className="title">
              <h5>Exports</h5>
            </div>
            <div className="icons">
              <img src={Export} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.exportcount}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Admincard;
