import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import "../Vehicle/AddVechile.scss";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import { UserContext } from "../../../Usecontext/UserContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UploadFile from "@mui/icons-material/UploadFile";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import newdriver_icon from "../../../newdriver.gif";
import { ErrorMessage } from "../../Customalert/Customalert";
import Check from "../../../check-lg.svg";

// ................Dialog..............

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Approvalview = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const { customerpdf, customer_pdf, customer_path } = useContext(UserContext);
  const order_id = props.match.params.id;
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();
  const [loading, setloading] = useState(true);
  const [invoice, setinvoice] = useState({});
  const [chat, setchat] = useState([]);
  const [hide, sethide] = useState(true);
  const [journey, setjourney] = useState(true);
  const [loaded, setloaded] = useState(true);
  const [unloaded, setunloaded] = useState(true);
  const [delivery, setdelivery] = useState(true);
  const [approval, setapproval] = useState(true);
  const [payment, setpayment] = useState([]);
  const [driver, setdriver] = useState([]);
  const [legercount, setlegercount] = useState([]);
  const [journy_complet, setjourny_complet] = useState(false);
  const [vendorname, setvendorname] = useState([]);
  const [vendorid, setvendorid] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [newdriver, setnewdriver] = useState([]);

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [order, setorder] = useState({
    id: "",
    loading: "",
    unloading: "",
    mobile: "",
    delivery_address: "",
    order_amount: "",
    type: "",
  });

  const [orderchangelist, setorderchangelist] = useState({
    description: "",
    amount: "",
  });

  const [vechile, setvechile] = useState({
    vendor_name: "",
    length: "",
    width: "",
    vehicle_number: "",
    type_name: "",
  });

  const [customer, setcustomber] = useState({
    first_name: "",
    emailid: "",
    last_name: "",
    company_name: "",
  });

  const [tracking, settracking] = useState({
    created_at: "",
    order_status: "",
  });

  const [orderupdate, setorderupdate] = useState({
    container_number: "",
    seal_number: "",
    vendor_id: "",
    vehicle_id: "",
  });

  const [adddocument, setadddocument] = useState({
    container_number: "",
    seal_number: "",
    description: "",
    amount: "",
    id: "",
  });

  // ............driver_change..............
  const [drivername, setdrivername] = useState([]);
  const [olddriver, setolddriver] = useState([]);
  const [comments, setcomments] = useState([]);
  const [driverlists, setdriverlists] = useState([]);

  // ..................diglog box..............

  const [open3, setOpen3] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  // ...................order-show document ............................

  const [document, setdocument] = useState([]);
  const [bookdocument, setbookdocument] = useState([]);
  const [containerdocument, setcontainerdocument] = useState([]);
  const [sealdocument, setsealdocument] = useState([]);
  const [invoicedocument, setinvoicedocument] = useState([]);
  const [packingdocument, setpackingdocument] = useState([]);
  const [ewaydocument, setewaydocument] = useState([]);
  const [annoxuredocument, setannoxuredocument] = useState([]);
  const [checkdocdocument, setcheckdocument] = useState([]);
  const [formdocdocument, setformdocument] = useState([]);
  const [eirdocument, seteirdocument] = useState([]);
  const [Otherdocument, setOtherdocument] = useState([]);

  // ..........useRef douments..................
  const containerInput = useRef(null);
  const sealInput = useRef(null);
  const invoiceInput = useRef(null);
  const PackingInput = useRef(null);
  const EwayInput = useRef(null);
  const AnnoxurInput = useRef(null);
  const CheckInput = useRef(null);
  const FormInput = useRef(null);
  const EIRInput = useRef(null);
  const OthersInput = useRef(null);

  // ................Document onchange....................
  const [containerPhoto, setcontainerPhoto] = useState("");
  const [SealPhoto, setSealPhoto] = useState("");
  const [invoicedoc, setinvoicedoc] = useState("");
  const [packingdoc, setpackingdoc] = useState("");
  const [ewaydoc, setewaydoc] = useState("");
  const [annoxuredoc, setannoxuredoc] = useState("");
  const [checkdoc, setcheckdoc] = useState("");
  const [formdoc, setformdoc] = useState("");
  const [eirdoc, seteirdoc] = useState("");
  const [othersdoc, setothersdoc] = useState("");

  function ContainerdocChange(event) {
    setcontainerPhoto(event.target.files[0]);
  }

  function SealdocChange(event) {
    setSealPhoto(event.target.files[0]);
  }

  function InvoicedocChange(event) {
    setinvoicedoc(event.target.files[0]);
  }

  function PackingdocChange(event) {
    setpackingdoc(event.target.files[0]);
  }

  function EwaydocChange(event) {
    setewaydoc(event.target.files[0]);
  }

  function AnnoxuredocChange(event) {
    setannoxuredoc(event.target.files[0]);
  }

  function CheckdocChange(event) {
    setcheckdoc(event.target.files[0]);
  }

  function FormdocChange(event) {
    setformdoc(event.target.files[0]);
  }

  function EIRdocChange(event) {
    seteirdoc(event.target.files[0]);
  }

  function OthersdocChange(event) {
    setothersdoc(event.target.files[0]);
  }

  // ...............handleInput....................
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "container_number":
        setorderupdate({
          ...orderupdate,
          [name]: value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 11),
        });
        break;
      case "vehicle_id":
      case "vendor_id":
        setorderupdate({
          ...orderupdate,
          [name]: value,
        });
        break;

      default:
        setorderupdate({
          ...orderupdate,
          [e.target.name]: e.target.value,
        });
    }
  };

  // ......................Chat input box..............................
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  //  ...............................handledocument...........................................

  const handledocument = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "container_number":
        setadddocument({
          ...adddocument,
          [name]: value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 11),
        });
        break;
      case "amount":
        setadddocument({
          ...adddocument,
          [name]: value.replace(/[^a-zA-Z0-9\s]/g, ""),
        });
        break;
      case "id":
        setadddocument({
          ...adddocument,
          [name]: value,
        });
        break;

      default:
        setadddocument({
          ...adddocument,
          [name]: value,
        });

        break;
    }
  };

  // ....................validate............................

  const validate = (values) => {
    const errors = {};
    if (!values.id) {
      errors.id = "Select the Driver !";
    }
    if (!values.description) {
      errors.description = "Description is required !";
    }
    if (!values.amount) {
      errors.amount = " Amount  is required !";
    }
    if (!values.vendor_id) {
      errors.vendor_id = " Vendor Name  is required !";
    }
    if (!values.vehicle_id) {
      errors.vehicle_id = " Vehicle Number  is required !";
    }
    return errors;
  };

  // ....................handlevendor...........................
  const handlevendor = (e) => {
    e.persist();
    const vendorid = e.target.value;
    setvendorid(vendorid);
    setorderupdate({
      ...orderupdate,
      [e.target.name]: e.target.value,
    });
  };

  // .........................vendor_vehicle_list api post........................

  useEffect(() => {
    const fetchData = async () => {
      if (vendorid) {
        const formData = new FormData();
        formData.append("order_id", order_id);
        formData.append("vendor_id", vendorid);
        try {
          const res = await axios.post(`/admin/vendor_vehicle_list`, formData);
          if (res.data.status === true) {
            setdrivername(res.data.vehicle_list);
          }
        } catch (error) {
          ErrorMessage({ message: "Something Went Wrong !" });
        }
      }
    };

    fetchData();
  }, [vendorid]);

  //  ...................vendorlist api get.......................
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/vendorlist`);
        if (res.data.status === true) {
          setvendorname(res.data.vendor);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchData();
  }, []);

  // .........................chat...............................
  useEffect(() => {
    const fetchData = async () => {
      try {
        const order_id = props.match.params.id;
        const res = await axios.get(`/admin/order-chat/${order_id}`);
        if (res.data.status === true) {
          setcomments(res.data.orderchatDetail);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchData();
  }, []);

  // ..................chatSubmit......................

  const chatSubmit = (event) => {
    const order_id = props.match.params.id;
    event.preventDefault();
    const formData = new FormData();
    formData.append("message", inputValue);
    formData.append("order_id", order_id);
    axios
      .post(`/admin/order-chat-store`, formData)
      .then((res) => {
        if (res.data.status === true) {
          setInputValue(" ");
          axios.get(`/admin/order-chat/${order_id}`).then((result) => {
            setcomments(result.data.orderchatDetail);
          });
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  };

  // .......................journy-start.................................

  const handldriver = () => {
    setIsButtonDisabled(true);
    const payment_det = props.match.params.id;
    swal({
      title: "Are you sure?",
      text: "Do you want to start the journey?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`/admin/driver-journy-start/${payment_det}`)
          .then((res) => {
            if (res.data.status === true) {
              swal("Journey Started", res.data.message, "success");
              history.push("/admin/order/all-reports");
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              }).then(() => {
                history.push("/admin/order/all-reports");
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      } else {
        setIsButtonDisabled(false);
      }
    });
  };

  // .......................order-loaded................................

  const handleloaded = () => {
    setIsButtonDisabled(true);
    const loaded_id = props.match.params.id;
    swal({
      title: "Are you sure?",
      text: "Do you want the load to be picked?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`/admin/order-loaded/${loaded_id}`)
          .then((res) => {
            if (res.data.status === true) {
              swal("Loaded Picked Completed", res.data.message, "success");
              history.push("/admin/order/all-reports");
              setIsButtonDisabled(false);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              }).then(() => {
                history.push("/admin/order/all-reports");
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      } else {
        setIsButtonDisabled(false);
      }
    });
  };
  // ...........................delivered.........................

  const handldelivert = () => {
    setIsButtonDisabled(true);
    const delivered = props.match.params.id;
    swal({
      title: "Are you sure?",
      text: "Do you want to load to be Delivered?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`/admin/order-delivered/${delivered}`)
          .then((res) => {
            if (res.data.status === true) {
              swal("Order Delivered", res.data.message, "success");
              history.push("/admin/order/all-reports");
              setIsButtonDisabled(false);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              }).then(() => {
                history.push("/admin/order/all-reports");
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      } else {
        setIsButtonDisabled(false);
      }
    });
  };
  // .......................order-unloaded.......................
  const handleunloaded = () => {
    setIsButtonDisabled(true);
    const unloaded = props.match.params.id;
    swal({
      title: "Are you sure?",
      text: "Do you want the load to be unloaded?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`/admin/order-unloaded/${unloaded}`)
          .then((res) => {
            if (res.data.status === true) {
              swal("Unloading Completed", res.data.message, "success");
              history.push("/admin/order/all-reports");
              setIsButtonDisabled(false);
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              }).then(() => {
                history.push("/admin/order/all-reports");
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      } else {
        setIsButtonDisabled(false);
      }
    });
  };

  // .......................journy-completed................................

  const handlejourny = () => {
    setIsButtonDisabled(true);
    const journey = props.match.params.id;
    swal({
      title: "Are you sure?",
      text: "Do you want to complete the journey?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`/admin/driver-journy-completed/${journey}`)
          .then((res) => {
            if (res.data.status === true) {
              swal("Journey Completed", res.data.message, "success").then(
                () => {
                  history.push("/admin/order/all-reports");
                  setIsButtonDisabled(false);
                }
              );
            } else if (res.data.status === false) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "warning",
              }).then(() => {
                history.push("/admin/order/all-reports");
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      } else {
        setIsButtonDisabled(false);
      }
    });
  };
  // ..................ordercharge-approve.........................

  const handleorder = () => {
    const payment_det = props.match.params.id;
    axios.post(`/admin/ordercharge-approve/${payment_det}`).then((res) => {
      if (res.data.status === true) {
        swal("Approved", res.data.message, "success");
        history.push("/admin/order/all-reports");
      } else if (res.data.status === false) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "warning",
        });
      }
    });
  };

  // ......................tracking-list............................

  useEffect(() => {
    const formData = new FormData();
    formData.append("order_id", order_id);
    axios.post(`/admin/tracking-list`, formData).then((res) => {
      if (res.data.status === true) {
        settracking(res.data.order_track_list);
      }
    });
  }, []);

  // .................ordercharge-create............................

  const submitorder = (e) => {
    const view_approval = props.match.params.id;
    e.preventDefault();
    setErrors(validate(adddocument));
    const formData = new FormData();
    formData.append("description", adddocument.description);
    formData.append("amount", adddocument.amount);
    formData.append("order_id", order.id);
    axios.post(`/admin/ordercharge-create`, formData).then((res) => {
      if (res.data.status === true) {
        axios.get(`/admin/order-show/${view_approval}`).then((result) => {
          if (result.data.status === true) {
            setorderchangelist(result.data.ordercharge_detail);
            setadddocument({
              description: "",
              amount: "",
            });
          }
        });
      }
    });
  };

  // .....................ordercharge-delete....................

  const removeUser = (id) => {
    const view_approval = props.match.params.id;
    axios.post(`/admin/ordercharge-delete/${id}`).then((res) => {
      if (res.data.status === true) {
        axios.get(`/admin/order-show/${view_approval}`).then((result) => {
          if (result.data.status === true) {
            setorderchangelist(result.data.ordercharge_detail);
          }
        });
      }
    });
  };

  // ...................order-show api get........................

  useEffect(() => {
    const view_approval = props.match.params.id;
    const getItems = async () => {
      const result = await axios.get(`/admin/order-show/${view_approval}`);
      if (result.data.status === true) {
        setorder(result.data.order_details);
        setadddocument(result.data.order_details);
        if (result.data.order_details.booking_status_id == 1) {
          sethide(true);
        } else {
          sethide(false);
        }
        if (result.data.order_details.booking_status_id == 3) {
          setjourney(true);
        } else {
          setjourney(false);
        }
        if (result.data.order_details.booking_status_id == 4) {
          setloaded(true);
        } else {
          setloaded(false);
        }
        if (result.data.order_details.booking_status_id == 5) {
          setunloaded(true);
        } else {
          setunloaded(false);
        }
        if (result.data.order_details.booking_status_id == 8) {
          setdelivery(true);
        } else {
          setdelivery(false);
        }
        if (
          (((result.data.order_details.booking_status_id == 9 &&
            result.data.order_details.driver_status == 1) ||
            result.data.order_details.booking_status_id == 6) &&
            result.data.order_details.driver_status) == 1
        ) {
          setjourny_complet(true);
        } else {
          setjourny_complet(false);
        }

        if (
          result.data.order_details.booking_status_id == 9 &&
          !result.data.enable_button
        ) {
          setapproval(true);
        } else {
          setapproval(false);
        }
        setolddriver(result.data.old_driver_details);
        setvechile(result.data.vehicle_details);
        setcustomber(result.data.Customer_details);
        setorderchangelist(result.data.ordercharge_detail);
        setpayment(result.data.payment_details);
        setlegercount(result.data.leger_count);
        setdriver(result.data.driver_detail);
        setinvoice(result.data.customer_pdf);
        setdocument(result.data.document_details);
        setbookdocument(result.data.booking_data);
        setcontainerdocument(result.data.container_data);
        setsealdocument(result.data.seal_data);
        setinvoicedocument(result.data.invoice_data);
        setpackingdocument(result.data.packing_data);
        setannoxuredocument(result.data.annoxure_data);
        setcheckdocument(result.data.check_data);
        setewaydocument(result.data.eway_data);
        setformdocument(result.data.form13_data);
        seteirdocument(result.data.eir_data);
        setOtherdocument(result.data.others_data);
        setloading(false);
      } else if (result.data.status === false) {
        swal({
          title: "Error",
          text: result.data.message,
          icon: "warning",
        });
      }
    };
    getItems();
  }, [props.match.params.id]);

  // ....................order-approve api post......................

  const handlesubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(orderupdate);
    setErrors(errors);
    setIsSubmit(true);
    swal({
      title: "Are you sure?",
      text: "Do you want to to approve this order?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        const formData = new FormData();
        formData.append("order_id", order_id);
        formData.append("vendor_id", orderupdate.vendor_id);
        formData.append("vehicle_id", orderupdate.vehicle_id);
        if (order.type === "Export") {
          formData.append("container_number", orderupdate.container_number);
          formData.append("seal_number", orderupdate.seal_number);
          formData.append("container_doc", containerPhoto);
          if (containerPhoto !== "") {
            formData.append("container_doc", containerPhoto.name);
          }
          formData.append("seal_doc", SealPhoto);
          if (SealPhoto !== "") {
            formData.append("seal_doc", SealPhoto.name);
          }
          formData.append("invoice", invoicedoc);
          if (invoicedoc !== "") {
            formData.append("invoice", invoicedoc.name);
          }

          formData.append("packing_list", packingdoc);
          if (packingdoc !== "") {
            formData.append("packing_list", packingdoc.name);
          }

          formData.append("eway_bill", ewaydoc);
          if (ewaydoc !== "") {
            formData.append("eway_bill", ewaydoc.name);
          }

          formData.append("annoxure", annoxuredoc);
          if (annoxuredoc !== "") {
            formData.append("annoxure", annoxuredoc.name);
          }

          formData.append("checklist", checkdoc);
          if (checkdoc !== "") {
            formData.append("checklist", checkdoc.name);
          }

          formData.append("eir_copy", eirdoc);
          if (eirdoc !== "") {
            formData.append("eir_copy", eirdoc.name);
          }

          formData.append("form13", formdoc);
          if (formdoc !== "") {
            formData.append("form13", formdoc.name);
          }

          formData.append("others", othersdoc);
          if (othersdoc !== "") {
            formData.append("others", othersdoc.name);
          }
        }

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios
          .post(`/admin/order-approve`, formData, config)
          .then((res) => {
            if (res.data.status === true) {
              swal("Order Approved", res.data.message, "success");
              history.push("/admin/order/all-reports");
            } else if (res.data.status === false) {
              swal({
                title: "Cancelled",
                text: res.data.message,
                icon: "warning",
                buttons: {
                  cancel: {
                    text: "OK",
                    value: "cancel",
                    visible: true,
                    closeModal: true,
                  },
                },
              }).then((value) => {
                if (value === "cancel") {
                  history.push("/admin/order/all-reports");
                }
              });
            }
          })
          .catch(() => {
            ErrorMessage({ message: "Something Went Wrong !" });
          });
      }
    });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);
  };

  // ......................order reject...................................

  const handlereject = () => {
    swal({
      text: "Reason for Rejection:",
      content: "input",
      buttons: {
        cancel: true,
        confirm: {
          text: "Submit",
          closeModal: false,
        },
      },
    })
      .then((value) => {
        if (value) {
          const view_approval = props.match.params.id;
          const formData = new FormData();
          formData.append("order_reject_message", value);
          axios
            .post(`/admin/order-reject/${view_approval}`, formData)
            .then((res) => {
              if (res.data.status === true) {
                swal("Rejected", res.data.message, "success");
                history.push("/admin/order/all-reports");
              } else if (res.data.status === false) {
                swal({
                  title: "Cancelled",
                  text: res.data.message,
                  icon: "warning",
                  buttons: {
                    cancel: {
                      text: "OK",
                      value: "cancel",
                      visible: true,
                      closeModal: true,
                    },
                  },
                }).then((value) => {
                  if (value === "cancel") {
                    history.push("/admin/order/all-reports");
                  }
                });
              }
            });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  };
  // .........................admin_document_update api post....................................

  const Chanagesumbit = () => {
    const view_approval = props.match.params.id;
    setIsButtonDisabled(true);
    const order_id = props.match.params.id;
    const formData = new FormData();
    formData.append("order_id", order_id);
    formData.append("container_number", adddocument.container_number);
    formData.append("seal_number", adddocument.seal_number);
    formData.append("container_doc[]", containerPhoto);
    if (containerPhoto !== "") {
      formData.append("container_doc[]", containerPhoto.name);
    }
    formData.append("seal_doc[]", SealPhoto);
    if (SealPhoto !== "") {
      formData.append("seal_doc[]", SealPhoto.name);
    }
    formData.append("invoice[]", invoicedoc);
    if (invoicedoc !== "") {
      formData.append("invoice[]", invoicedoc.name);
    }

    formData.append("packing[]", packingdoc);
    if (packingdoc !== "") {
      formData.append("packing[]", packingdoc.name);
    }

    formData.append("eway[]", ewaydoc);
    if (ewaydoc !== "") {
      formData.append("eway[]", ewaydoc.name);
    }

    formData.append("annoxure[]", annoxuredoc);
    if (annoxuredoc !== "") {
      formData.append("annoxure[]", annoxuredoc.name);
    }

    formData.append("checklist[]", checkdoc);
    if (checkdoc !== "") {
      formData.append("checklist[]", checkdoc.name);
    }

    formData.append("eir_copy[]", eirdoc);
    if (eirdoc !== "") {
      formData.append("eir_copy[]", eirdoc.name);
    }

    formData.append("form13[]", formdoc);
    if (formdoc !== "") {
      formData.append("form13[]", formdoc.name);
    }

    formData.append("others[]", othersdoc);
    if (othersdoc !== "") {
      formData.append("others[]", othersdoc.name);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post("/admin/admin_document_update", formData, config)
      .then((res) => {
        if (res.data.status === true) {
          swal("Updated", res.data.message, "success");
          history.push("/admin/order/all-reports");
          setIsButtonDisabled(false);
          axios.get(`/admin/order-show/${view_approval}`).then((result) => {
            if (result.data.status === true) {
              setdocument(result.data.document_details);
              setbookdocument(result.data.booking_data);
              setcontainerdocument(result.data.container_data);
              setsealdocument(result.data.seal_data);
              setinvoicedocument(result.data.invoice_data);
              setpackingdocument(result.data.packing_data);
              setannoxuredocument(result.data.annoxure_data);
              setcheckdocument(result.data.check_data);
              setewaydocument(result.data.eway_data);
              setformdocument(result.data.form13_data);
              seteirdocument(result.data.eir_data);
              setOtherdocument(result.data.others_data);
            }
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  };

  // ................admin_document_approve................
  const [approvebnt, setapprovebnt] = useState([]);

  const docapprove = () => {
    const order_id = props.match.params.id;
    const formData = new FormData();
    formData.append("order_id", order_id);
    formData.append("document_id", 2);
    axios
      .post("/admin/admin_document_approve", formData)
      .then((res) => {
        if (res.data.status === true) {
          swal("Approved", res.data.message, "success");
          setapprovebnt(res.data.status);
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  };

  // .....................admin_document_approve api post.............................

  const [clickbnt, setclickbnt] = useState([]);

  const checklistapprove = () => {
    const order_id = props.match.params.id;
    const formData = new FormData();
    formData.append("order_id", order_id);
    formData.append("document_id", 1);
    axios
      .post("/admin/admin_document_approve", formData)
      .then((res) => {
        if (res.data.status === true) {
          swal("Approved", res.data.message, "success");
          setclickbnt(res.data.status);
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  };

  // .....................editStatus.............................

  const editStatus = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(adddocument));
    setIsSubmit(true);
    const view_approval = props.match.params.id;
    const formdata = new FormData();
    formdata.append("driver_id", adddocument.id);
    formdata.append("order_id", order.id);
    axios
      .post(`/admin/order_driver_change`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setnewdriver(res.data.driver_details);
          axios.get(`/admin/order-show/${view_approval}`).then((result) => {
            setolddriver(result.data.ordercharge_detail);
          });
          setIsButtonDisabled(false);
          setOpen3(false);
          handleClickOpen();
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  };

  // ................changedriver.....................

  const changedriver = (vendorId) => {
    const formdata = new FormData();
    formdata.append("vendor_id", vendorId);
    return axios
      .post(`/admin/change_driver_list`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setdriverlists(res.data.driver_list);
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  };

  // ........................changedriver............................

  useEffect(() => {
    if (typeof order.vendor_id !== "undefined") {
      changedriver(order.vendor_id);
    }
  }, [order]);

  // ......................order_change.....................
  let order_change = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    order_change = orderchangelist.map((item) => {
      return (
        <>
          <div className="col-md-5 form-group mb-3">
            <input
              type="text"
              name="description"
              value={item.description}
              className="form-control"
            />
          </div>
          <div className="col-md-5 form-group mb-3">
            <input
              type="text"
              name="description"
              value={item.amount}
              className="form-control"
            />
          </div>
          {order.approve_charges_id !== 1 ? (
            <>
              <div className="col-md-2 form-group mb-3">
                <button
                  className="btn btn-danger remove_bnt"
                  onClick={() => removeUser(item.id)}
                >
                  Remove
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    });
  }

  // ..............BootstrapDialogTitle............

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    const history = useHistory();

    const handleClose = () => {
      onClose();
      history.push("/admin/order/all-reports");
    };

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <>
      <section className="Addvechile">
        <div className="container-fluid px-4">
          <div className="titile px-4 mb-5 mt-4">
            <h4>
              <span className="titile_span">Order Approval View</span>
              <Link
                to="/admin/order/all-reports"
                className="btn btn-primary admin float-end"
              >
                Go Back
              </Link>
            </h4>
          </div>

          {/* ..............................BootstrapDialog........................................ */}
          <div>
            {/* <button onClick={handleClickOpen}>open</button> */}
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "400px",
                    position: "absolute",
                    right: "10px",
                    top: " 40px",
                  },
                },
              }}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                Driver Changed Successfully.
              </BootstrapDialogTitle>
              <DialogContent>
                {newdriver.map((item) => (
                  <div key={item.id}>
                    <div className="Driver_old">
                      <p>
                        Driver :
                        <span className="old_name">{item.old_driver}</span>
                        <img src={newdriver_icon} className="new_icon" />
                        <span className="new_name">{item.new_driver}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </DialogContent>
            </BootstrapDialog>
          </div>

          {/* ......................Approval View............................. */}
          <div className="card mt-4">
            <div className="card-body  mt-3 mb-3">
              <ul class="nav nav-pills mb-3 px-2" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    ORDER DETAILS
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-tracking-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tracking"
                    type="button"
                    role="tab"
                    aria-controls="pills-tracking"
                    aria-selected="false"
                  >
                    ORDER TRACKING
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    VEHICLE DETAILS
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-vechile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-vechile"
                    type="button"
                    role="tab"
                    aria-controls="pills-vechile"
                    aria-selected="false"
                  >
                    CUSTOMER DETAILS
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-order-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-order"
                    type="button"
                    role="tab"
                    aria-controls="pills-order"
                    aria-selected="false"
                  >
                    ORDER CHARGES DETAILS
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-phide-payment-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-payment"
                    type="button"
                    role="tab"
                    aria-controls="pills-payment"
                    aria-selected="false"
                  >
                    PAYMENT DETAILS
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-driver-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-driver"
                    type="button"
                    role="tab"
                    aria-controls="pills-driver"
                    aria-selected="false"
                  >
                    DRIVER DETAILS
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-comments-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-comments"
                    type="button"
                    role="tab"
                    aria-controls="pills-comments"
                    aria-selected="false"
                  >
                    COMMENTS
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                {/* ...................order details.............................. */}
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row px-5">
                    {/* ............... Edit Driver.................... */}
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      {legercount !== 1 ? (
                        <>
                          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button
                              class="btn btn-secondary me-md-2"
                              type="button"
                              onClick={handleOpen3}
                            >
                              Edit Driver
                            </button>
                            <Dialog
                              open={open3}
                              keepMounted
                              onClose={handleClose3}
                              aria-describedby="alert-dialog-slide-description"
                              sx={{
                                "& .MuiDialog-container": {
                                  "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "1000px",
                                  },
                                },
                              }}
                            >
                              <DialogTitle>Edit Driver</DialogTitle>
                              <DialogContent>
                                <div className="card-body">
                                  <div className="card-body">
                                    <form>
                                      <div className="row px-5">
                                        <div className="col-md-6 form-group mb-3">
                                          <label>Booking Status</label>
                                          <input
                                            type="text"
                                            disabled
                                            placeholder="Booking Status"
                                            name="first_name"
                                            className="form-control"
                                            value={order.booking_status_name}
                                          />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                          <label>Vendor Name</label>
                                          <input
                                            type="text"
                                            disabled
                                            placeholder="Vendor Name"
                                            name="first_name"
                                            className="form-control"
                                            value={order.vendor_name}
                                          />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                          <label>Vehicle Name</label>
                                          <input
                                            type="text"
                                            disabled
                                            placeholder="Vechile Name"
                                            name="first_name"
                                            className="form-control"
                                            value={vechile.vehicle_manufacture}
                                          />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                          <label>Vehicle Number</label>
                                          <input
                                            type="text"
                                            disabled
                                            placeholder="Vehicle Number"
                                            name="first_name"
                                            className="form-control"
                                            value={vechile.vehicle_number}
                                          />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                          <label>Vehicle Type</label>
                                          <input
                                            type="text"
                                            disabled
                                            placeholder="Vehicle Type"
                                            name="first_name"
                                            className="form-control"
                                            value={vechile.type_name}
                                          />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                          <label>
                                            Change Driver
                                            <span className="star_icon">*</span>
                                          </label>
                                          <select
                                            className="form-control"
                                            name="id"
                                            onChange={handledocument}
                                            value={adddocument.id}
                                          >
                                            <option value="">
                                              --Select Driver--
                                            </option>
                                            {driverlists.length > 0 &&
                                              driverlists.map((item) => {
                                                return (
                                                  <option
                                                    value={item.id}
                                                    key={item.id}
                                                  >
                                                    {item.first_name}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                          <p>{errors.id}</p>
                                        </div>
                                      </div>
                                      <DialogActions>
                                        <Button
                                          onClick={editStatus}
                                          disabled={isButtonDisabled}
                                        >
                                          {isButtonDisabled
                                            ? "Loading..."
                                            : "Change"}
                                        </Button>
                                        <Button onClick={handleClose3}>
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </form>
                                  </div>
                                </div>
                              </DialogContent>
                            </Dialog>
                          </div>
                        </>
                      ) : (
                        <>
                          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button class="btn btn-secondary me-md-2">
                              Edit Driver
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-8 view-main mb-5">
                      <div className="first-col">
                        <h6>Job Number</h6>
                        <h6>Loading</h6>
                        <h6>Unloading</h6>
                        <h6>Mobile Number</h6>
                        <h6>Secondary Number</h6>
                        <h6>Delivery Address</h6>
                        <h6>Order Amount</h6>
                        <h6>Type</h6>
                        <h6>Vehicle Type</h6>
                        <h6>Order Date</h6>
                      </div>
                      <div className="first-col-sibling">
                        <small>
                          {order.order_id}
                          <span className="otp_css"> (OTP-{order.otp})</span>
                        </small>
                        <small>{order.loading} </small>
                        <small>{order.unloading}</small>
                        <small>{order.mobile}</small>
                        <small>{order.alter_mobile}</small>
                        <small>{order.delivery_address}</small>
                        <small>{order.order_amount} </small>
                        <small>{order.type} </small>
                        <small>
                          {order.truck_capacity_name} {order.truck_type_name}
                        </small>
                        <small>{order.created_at} </small>
                      </div>
                    </div>
                  </div>

                  {/* .........................Export....................... */}
                  {order.type == "Export" ? (
                    <>
                      {hide ? (
                        <>
                          <div className="row px-5">
                            <div className="col-12 form-group mb-4 table_coll">
                              <div className="table-responsive">
                                <table className="table table-striped border-start border-end">
                                  <thead>
                                    <tr className="head_color">
                                      <th>Document Name</th>
                                      <th>Uploaded By</th>
                                      <th>Document</th>
                                      {order.booking_status_id !== 6 ? (
                                        <>
                                          <th>Actions</th>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {bookdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>{item.document_name}</td>
                                          <td>{item.shared_by}</td>
                                          <td key={item.id}>
                                            <a
                                              href={`${url}${item.document_path}`}
                                              target="_blank"
                                            >
                                              <PictureAsPdfIcon />
                                            </a>
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td></td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {containerdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Container doc</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {containerPhoto == "" ? (
                                                  <>
                                                    <input
                                                      ref={containerInput}
                                                      onChange={
                                                        ContainerdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        containerInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={containerInput}
                                                      onChange={
                                                        ContainerdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        containerInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {sealdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Seal Photo</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {SealPhoto == "" ? (
                                                  <>
                                                    <input
                                                      ref={sealInput}
                                                      onChange={SealdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        sealInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={sealInput}
                                                      onChange={SealdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        sealInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {invoicedocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Invoice</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {invoicedoc == "" ? (
                                                  <>
                                                    {" "}
                                                    <input
                                                      ref={invoiceInput}
                                                      onChange={
                                                        InvoicedocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        invoiceInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={invoiceInput}
                                                      onChange={
                                                        InvoicedocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        invoiceInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {packingdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Packing List</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {packingdoc == "" ? (
                                                  <>
                                                    <input
                                                      ref={PackingInput}
                                                      onChange={
                                                        PackingdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        PackingInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={PackingInput}
                                                      onChange={
                                                        PackingdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        PackingInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {ewaydocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Eway Bill</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {ewaydoc == "" ? (
                                                  <>
                                                    <input
                                                      ref={EwayInput}
                                                      onChange={EwaydocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EwayInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={EwayInput}
                                                      onChange={EwaydocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EwayInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {annoxuredocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Annexure-c</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                {item.document_path !== "" ? (
                                                  <>
                                                    <a
                                                      href={`${url}${item.document_path}`}
                                                      target="_blank"
                                                    >
                                                      <PictureAsPdfIcon />
                                                    </a>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {annoxuredoc == "" ? (
                                                  <>
                                                    <input
                                                      ref={AnnoxurInput}
                                                      onChange={
                                                        AnnoxuredocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        AnnoxurInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={AnnoxurInput}
                                                      onChange={
                                                        AnnoxuredocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        AnnoxurInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {checkdocdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Check List</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="approve UploadFile">
                                                {checkdoc == "" ? (
                                                  <>
                                                    {clickbnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.checklist_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  checklistapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <input
                                                      ref={CheckInput}
                                                      onChange={CheckdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        CheckInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    {clickbnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.checklist_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  checklistapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <input
                                                      ref={CheckInput}
                                                      onChange={CheckdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        CheckInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {formdocdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Form-13</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="approve UploadFile">
                                                {formdoc == "" ? (
                                                  <>
                                                    {approvebnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.form13_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  docapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                        <input
                                                          ref={FormInput}
                                                          onChange={
                                                            FormdocChange
                                                          }
                                                          type="file"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        />
                                                        <button
                                                          className="docment_box"
                                                          onClick={() =>
                                                            FormInput.current.click()
                                                          }
                                                        >
                                                          <UploadFile /> Upload
                                                          File
                                                        </button>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    {approvebnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.form13_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  docapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                        <input
                                                          ref={FormInput}
                                                          onChange={
                                                            FormdocChange
                                                          }
                                                          type="file"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        />
                                                        <button
                                                          className="docment_box"
                                                          onClick={() =>
                                                            FormInput.current.click()
                                                          }
                                                        >
                                                          <UploadFile /> Upload
                                                          File
                                                        </button>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {eirdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>EIR Copy</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {eirdoc == "" ? (
                                                  <>
                                                    <input
                                                      ref={EIRInput}
                                                      onChange={EIRdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EIRInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={EIRInput}
                                                      onChange={EIRdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EIRInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {Otherdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Others</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {othersdoc == "" ? (
                                                  <>
                                                    <input
                                                      ref={OthersInput}
                                                      onChange={OthersdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        OthersInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={OthersInput}
                                                      onChange={OthersdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        OthersInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}

                                    {/* ............................................ */}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row px-5">
                            <div className="col-12 form-group mb-4 table_coll">
                              <div class="table-responsive">
                                <table class="table table-striped border-start border-end">
                                  <thead>
                                    <tr className="head_color">
                                      <th>Document Name</th>
                                      <th>Uploaded By</th>
                                      <th>Document</th>
                                      {order.booking_status_id !== 6 ? (
                                        <>
                                          <th>Actions</th>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {bookdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>{item.document_name}</td>
                                          <td>{item.shared_by}</td>
                                          <td key={item.id}>
                                            <a
                                              href={`${url}${item.document_path}`}
                                              target="_blank"
                                            >
                                              <PictureAsPdfIcon />
                                            </a>
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td></td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {containerdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Container doc</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {containerPhoto == "" ? (
                                                  <>
                                                    <input
                                                      ref={containerInput}
                                                      onChange={
                                                        ContainerdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        containerInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={containerInput}
                                                      onChange={
                                                        ContainerdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        containerInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {sealdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Seal Photo</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {SealPhoto == "" ? (
                                                  <>
                                                    {" "}
                                                    <input
                                                      ref={sealInput}
                                                      onChange={SealdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        sealInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={sealInput}
                                                      onChange={SealdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        sealInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {invoicedocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Invoice</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {invoicedoc == "" ? (
                                                  <>
                                                    <input
                                                      ref={invoiceInput}
                                                      onChange={
                                                        InvoicedocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        invoiceInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={invoiceInput}
                                                      onChange={
                                                        InvoicedocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        invoiceInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {packingdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Packing List</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>

                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {packingdoc == "" ? (
                                                  <>
                                                    {" "}
                                                    <input
                                                      ref={PackingInput}
                                                      onChange={
                                                        PackingdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        PackingInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={PackingInput}
                                                      onChange={
                                                        PackingdocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        PackingInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {ewaydocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Eway Bill</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {ewaydoc == "" ? (
                                                  <>
                                                    {" "}
                                                    <input
                                                      ref={EwayInput}
                                                      onChange={EwaydocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EwayInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={EwayInput}
                                                      onChange={EwaydocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EwayInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {annoxuredocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Annexure-c</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            <>
                                              {item.document_path !== "" ? (
                                                <>
                                                  <a
                                                    href={`${url}${item.document_path}`}
                                                    target="_blank"
                                                  >
                                                    <PictureAsPdfIcon />
                                                  </a>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {annoxuredoc == "" ? (
                                                  <>
                                                    {" "}
                                                    <input
                                                      ref={AnnoxurInput}
                                                      onChange={
                                                        AnnoxuredocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        AnnoxurInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={AnnoxurInput}
                                                      onChange={
                                                        AnnoxuredocChange
                                                      }
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        AnnoxurInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {checkdocdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Check List</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="approve UploadFile">
                                                {checkdoc == "" ? (
                                                  <>
                                                    {clickbnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.checklist_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  checklistapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <input
                                                      ref={CheckInput}
                                                      onChange={CheckdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        CheckInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    {clickbnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.checklist_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  checklistapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={CheckInput}
                                                      onChange={CheckdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        CheckInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {formdocdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Form-13</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="approve UploadFile">
                                                {formdoc == "" ? (
                                                  <>
                                                    {" "}
                                                    {approvebnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.form13_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  docapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <input
                                                      ref={FormInput}
                                                      onChange={FormdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        FormInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    {approvebnt == true ? (
                                                      <>
                                                        <div className="approve_bnt approved">
                                                          <button className="btn btn-success">
                                                            Approved
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {order.form13_approve ==
                                                        0 ? (
                                                          <>
                                                            <div className="approve_bnt">
                                                              <button
                                                                className="btn btn-warning"
                                                                onClick={
                                                                  docapprove
                                                                }
                                                              >
                                                                Approve
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="approve_bnt approved">
                                                              <button className="btn btn-success">
                                                                Approved
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={FormInput}
                                                      onChange={FormdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        FormInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {eirdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>EIR Copy</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {eirdoc == "" ? (
                                                  <>
                                                    {" "}
                                                    <input
                                                      ref={EIRInput}
                                                      onChange={EIRdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EIRInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={EIRInput}
                                                      onChange={EIRdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        EIRInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    {Otherdocument.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>Others</td>
                                          <td>
                                            {item.shared_by !== "" ? (
                                              <> {item.shared_by}</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td key={item.id}>
                                            {item.document_path !== "" ? (
                                              <>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfIcon />
                                                </a>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          {order.booking_status_id !== 6 ? (
                                            <>
                                              <td className="UploadFile">
                                                {othersdoc == "" ? (
                                                  <>
                                                    {" "}
                                                    <input
                                                      ref={OthersInput}
                                                      onChange={OthersdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        OthersInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="UploadFile_img">
                                                      <img src={Check} />
                                                    </div>
                                                    <input
                                                      ref={OthersInput}
                                                      onChange={OthersdocChange}
                                                      type="file"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <button
                                                      className="docment_box"
                                                      onClick={() =>
                                                        OthersInput.current.click()
                                                      }
                                                    >
                                                      <UploadFile /> Upload File
                                                    </button>
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              {order.booking_status_id !== 6 ? (
                                <>
                                  <div className="row mt-3">
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Container Number</label>
                                      <input
                                        type="text"
                                        placeholder="Container Number"
                                        name="container_number"
                                        value={adddocument.container_number}
                                        onChange={handledocument}
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Seal Number</label>
                                      <input
                                        type="text"
                                        placeholder="Seal Number"
                                        name="seal_number"
                                        onChange={handledocument}
                                        value={adddocument.seal_number}
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                                    <button
                                      className={
                                        isButtonDisabled
                                          ? "btn btn-secondary"
                                          : "btn btn-success"
                                      }
                                      onClick={Chanagesumbit}
                                      disabled={isButtonDisabled}
                                    >
                                      {isButtonDisabled ? "Loading..." : "Save"}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="row px-5">
                        <div className="col-md-6 form-group mb-4">
                          <div class="table-responsive">
                            <table class="table table-striped border-start border-end">
                              <thead>
                                <tr class="head_color">
                                  <th>Document Name</th>
                                  <th>Document</th>
                                </tr>
                              </thead>
                              <tbody>
                                {document.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td>{item.document_name}</td>
                                      <td key={item.id}>
                                        <a
                                          href={`${url}${item.document_path}`}
                                          target="_blank"
                                        >
                                          <PictureAsPdfIcon />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* ..........................Export.................................. */}
                  <div className="row admin-view mt-4 px-5">
                    {hide ? (
                      <>
                        <div className="row">
                          <div className="col-md-6 form-group mb-3">
                            <label>
                              Vendor List <span className="star_icon">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="vendor_id"
                              onChange={handlevendor}
                              value={orderupdate.vendor_id}
                            >
                              <option value="">--Select Vendor--</option>
                              {vendorname.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.first_name}
                                  </option>
                                );
                              })}
                            </select>
                            <p>{errors.vendor_id}</p>
                          </div>
                          <div className="col-md-6 form-group mb-3 drivername_length">
                            <label>
                              Vehicle List <span className="star_icon">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="vehicle_id"
                              onChange={handleInput}
                              value={orderupdate.vehicle_id}
                            >
                              {drivername.length == "" ? (
                                <>
                                  <option value="">--No Vehicle Found--</option>
                                </>
                              ) : (
                                <>
                                  <option value="">--Select Vehicle --</option>
                                  {drivername.map((itm) => {
                                    return (
                                      <option value={itm.id} key={itm.id}>
                                        {itm.vehicle_number}
                                      </option>
                                    );
                                  })}
                                </>
                              )}
                            </select>
                            <div className="drivername_length_child">
                              <h5>{drivername.length}</h5>
                            </div>
                            <p>{errors.vehicle_id}</p>
                          </div>
                          {/* ...........Export........... */}

                          {order.type == "Export" ? (
                            <>
                              <div className="col-md-6 form-group mb-3">
                                <label>Container Number</label>
                                <input
                                  type="text"
                                  placeholder="Container Number"
                                  name="container_number"
                                  value={orderupdate.container_number}
                                  onChange={handleInput}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <label>Seal Number</label>
                                <input
                                  type="text"
                                  placeholder="Seal Number"
                                  name="seal_number"
                                  onChange={handleInput}
                                  value={orderupdate.seal_number}
                                  className="form-control"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* ................................... */}
                        <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                          <button
                            type="submit"
                            onClick={handlereject}
                            className="btn btn-danger"
                          >
                            Reject
                          </button>
                          <button
                            type="submit"
                            onClick={handlesubmit}
                            className={
                              isButtonDisabled
                                ? "btn btn-secondary"
                                : "btn btn-success"
                            }
                            disabled={isButtonDisabled}
                          >
                            {isButtonDisabled ? "Loading..." : "Approve"}
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {/* .......................Vehicle details.......................... */}
                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row px-5 mt-5">
                    <div className="col-md-6 view-main mb-5">
                      {vechile.length !== 0 ? (
                        <>
                          <div className="first-col">
                            <h6>Vendor Name</h6>
                            <h6>Vehicle Number</h6>
                            <h6>Truck Type</h6>
                            <h6>Container Size</h6>
                          </div>
                          <div className="first-col-sibling">
                            <small>{vechile.vendor_name} </small>
                            <small>{vechile.vehicle_number}</small>
                            <small>{vechile.type_name}</small>
                            <small>{vechile.capactiy_name}</small>
                          </div>
                        </>
                      ) : (
                        <>
                          <h6 className="px-4 mt-3">No Record Found</h6>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* .......................Customer details.......................... */}
                <div
                  class="tab-pane fade"
                  id="pills-vechile"
                  role="tabpanel"
                  aria-labelledby="pills-vechile-tab"
                >
                  <div className="row px-5 mt-5">
                    <div className="col-md-6 view-main mb-5">
                      <div className="first-col">
                        <h6>First Name</h6>
                        <h6>Last Name</h6>
                        <h6>Company Name</h6>
                        <h6>Email ID</h6>
                        <h6>Customer Number</h6>
                        <h6>Customer App Number</h6>
                      </div>
                      <div className="first-col-sibling">
                        <small>{customer.first_name} </small>
                        <small>{customer.last_name}</small>
                        <small>{customer.company_name}</small>
                        <small>{customer.email}</small>
                        <small>{customer.contact_number}</small>
                        <small>{customer.customer_sid}</small>
                      </div>
                    </div>
                  </div>
                </div>
                {/* .......................Amount details.......................... */}
                <div
                  class="tab-pane fade "
                  id="pills-order"
                  role="tabpanel"
                  aria-labelledby="pills-order-tab"
                >
                  <div className="row px-5 mt-5">
                    {order_change}
                    {order.approve_charges_id !== 1 ? (
                      <>
                        <form>
                          <div className="row">
                            <div className="col-md-12 form-group mb-3">
                              <input
                                type="hidden"
                                name="id"
                                value={order.id}
                                onChange={handleInput}
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-5 form-group mb-3">
                              <label>
                                Description <span className="star_icon">*</span>
                              </label>
                              <textarea
                                type="text"
                                placeholder="Description"
                                name="description"
                                value={adddocument.description}
                                onChange={handledocument}
                                className="form-control"
                                rows="1"
                              />
                              <p>{errors.description}</p>
                            </div>
                            <div className="col-md-5 form-group mb-3">
                              <label>
                                Amount <span className="star_icon">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Amount"
                                name="amount"
                                value={adddocument.amount}
                                onChange={handledocument}
                                className="form-control"
                              />
                              <p>{errors.amount}</p>
                            </div>

                            {order.approve_charges_id !== 1 ? (
                              <>
                                <div className="col-md-2 form-group mt-4">
                                  <button
                                    onClick={submitorder}
                                    className="btn btn-primary add_bnt"
                                  >
                                    ADD
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                        {/* ................................................................... */}
                        <div className="row admin-view px-2 mt-3 ">
                          {approval ? (
                            <>
                              <div className="col-md-4 ">
                                <button
                                  onClick={handleorder}
                                  className="btn btn-success btn-sm"
                                >
                                  Approve
                                </button>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* .......................payment details.......................... */}
                <div
                  class="tab-pane fade "
                  id="pills-payment"
                  role="tabpanel"
                  aria-labelledby="pills-payment-tab"
                >
                  <div className="container-fluid px-5">
                    <div className="table-responsive">
                      <table class="table table-striped border-start border-end">
                        <thead>
                          <tr className="head_color">
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Transaction ID</th>
                            <th>Method</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payment.length > 0 ? (
                            payment.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.amount}</td>
                                  <td>{item.transaction_id}</td>
                                  <td>{item.method}</td>
                                  <td>{item.status}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <h6 className="px-4 mt-3">No Record Found</h6>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="row mt-3">
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Customer Invoice
                            </button>
                          </h2>
                          {invoice !== "" ? (
                            <>
                              <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="accordion-body">
                                  <a
                                    href={`${customer_pdf}${invoice}`}
                                    target="_blank"
                                  >
                                    <button className="btn btn-primary btn-lg">
                                      Invoice
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* .......................driver details.......................... */}
                <div
                  class="tab-pane fade "
                  id="pills-driver"
                  role="tabpanel"
                  aria-labelledby="pills-driver-tab"
                >
                  <div className="row px-5 mt-5">
                    {driver.length != 0 ? (
                      <>
                        {olddriver.length == 0 ? (
                          <>
                            <h6>Driver </h6>
                          </>
                        ) : (
                          <>
                            <h6>Driver 2</h6>
                          </>
                        )}
                        <div className="col-md-6 view-main">
                          <div className="first-col">
                            <h6>First Name</h6>
                            <h6>Last Name</h6>
                            <h6>Contact Number</h6>
                          </div>
                          <div className="first-col-sibling">
                            <small>{driver.first_name} </small>
                            <small>{driver.last_name}</small>
                            <small>{driver.contact_number}</small>
                          </div>
                        </div>
                        {/* ...................olddriver....................... */}
                        <div className="row px-2 mt-3">
                          <div className="col-md-6">
                            <div className="">
                              {olddriver.map((item) => {
                                return (
                                  <div key={item.id}>
                                    <h6>Driver 1</h6>
                                    <div className="old_drivers">
                                      <div className="old_driver-title">
                                        First Name
                                      </div>
                                      <div className="old_driver-title_s">
                                        : {item.old_driver_first}
                                      </div>
                                    </div>
                                    <div className="old_drivers">
                                      <div className="old_driver-title">
                                        Last Name
                                      </div>
                                      <div className="old_driver-title_s">
                                        : {item.old_driver_last}
                                      </div>
                                    </div>
                                    <div className="old_drivers">
                                      <div className="old_driver-title">
                                        Contact Number
                                      </div>
                                      <div className="old_driver-title_s">
                                        : {item.old_driver_contact}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h6 className="px-4 mt-3">No Record Found</h6>
                      </>
                    )}
                  </div>

                  {/* ..................order assain............................ */}
                  <div className="row admin-view mt-3 px-5">
                    {journey ? (
                      <>
                        <div className="col-md-4">
                          <button
                            onClick={handldriver}
                            disabled={isButtonDisabled}
                            className={
                              isButtonDisabled
                                ? "btn btn-secondary btn-sm"
                                : "btn btn-success btn-sm"
                            }
                          >
                            {isButtonDisabled
                              ? "Loading..."
                              : "  Journey Start"}
                          </button>
                        </div>
                      </>
                    ) : null}
                    {delivery ? (
                      <>
                        <div className="col-md-4">
                          <button
                            onClick={handldelivert}
                            className={
                              isButtonDisabled
                                ? "btn btn-secondary btn-sm"
                                : "btn btn-success btn-sm"
                            }
                            disabled={isButtonDisabled}
                          >
                            {isButtonDisabled ? "Loading..." : "Ready Delivery"}
                          </button>
                        </div>
                      </>
                    ) : null}
                    {journy_complet ? (
                      <>
                        <div className="col-md-4">
                          <button
                            onClick={handlejourny}
                            className={
                              isButtonDisabled
                                ? "btn btn-secondary btn-sm"
                                : "btn btn-success btn-sm"
                            }
                            disabled={isButtonDisabled}
                          >
                            {isButtonDisabled
                              ? "Loading..."
                              : "Journey Completed"}
                          </button>
                        </div>
                      </>
                    ) : null}

                    {loaded ? (
                      <>
                        <div className="col-md-4">
                          <button
                            onClick={handleloaded}
                            className={
                              isButtonDisabled
                                ? "btn btn-secondary btn-sm"
                                : "btn btn-success btn-sm"
                            }
                            disabled={isButtonDisabled}
                          >
                            {isButtonDisabled ? "Loading..." : "Load Picked"}
                          </button>
                        </div>
                      </>
                    ) : null}

                    {unloaded ? (
                      <>
                        <div className="col-md-4">
                          <button
                            onClick={handleunloaded}
                            className={
                              isButtonDisabled
                                ? "btn btn-secondary btn-sm"
                                : "btn btn-success btn-sm"
                            }
                            disabled={isButtonDisabled}
                          >
                            {isButtonDisabled
                              ? "Loading..."
                              : "Unload Delivery"}
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {/* .......................comments details.......................... */}
                <div
                  class="tab-pane fade"
                  id="pills-comments"
                  role="tabpanel"
                  aria-labelledby="pills-comments-tab"
                >
                  <div className="row px-5 mt-5 tracking_row">
                    <h4 className="order-view">Comments</h4>
                    <div className="col-md-12 mt-4">
                      <div className="chat_boxs">
                        {comments.length > 0 ? (
                          comments.map((item) => {
                            return (
                              <div class="comments-list" key={item.id}>
                                {item.role == "Admin" ? (
                                  <>
                                    <ul className="message_left">
                                      <li className="admin_message">
                                        {item.role}
                                      </li>
                                      <li>
                                        <span>{item.message}</span>
                                      </li>
                                    </ul>
                                  </>
                                ) : (
                                  <>
                                    {item.role === "Customer" ? (
                                      <>
                                        <ul className="message_rigth">
                                          <li className="customer_message">
                                            {item.role} :
                                          </li>
                                          <li>
                                            <span>{item.message}</span>
                                          </li>
                                        </ul>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item.role === "Vendor" ? (
                                      <>
                                        <ul className="message_rigth">
                                          <li className="vendor_message">
                                            {item.role} :
                                          </li>
                                          <li>
                                            <span>{item.message}</span>
                                          </li>
                                        </ul>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item.role === "Driver" ? (
                                      <>
                                        <ul className="message_rigth">
                                          <li className="driver_message">
                                            {item.role} :
                                          </li>
                                          <li>
                                            <span>{item.message}</span>
                                          </li>
                                        </ul>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <h6 className="px-4 mt-2">No Record Found</h6>
                        )}
                      </div>
                      <form>
                        <div className="row comment_box">
                          <div className="col-md-6">
                            <div className="col-md-12 form-group mb-3">
                              <textarea
                                class="form-control Chat_box"
                                type="text"
                                placeholder="message"
                                name="message"
                                rows="3"
                                value={inputValue}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              onClick={chatSubmit}
                              className="btn btn-secondary float-end px-5 p-2"
                            >
                              Chat
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* .......................tracking details.......................... */}
                <div
                  class="tab-pane fade"
                  id="pills-tracking"
                  role="tabpanel"
                  aria-labelledby="pills-tracking-tab"
                >
                  <div class="row tracking_row">
                    <div class="col-lg-12">
                      <div className="mt-5 px-5">
                        <div class="table-responsive">
                          <table class="table table-striped border-start border-end ">
                            <thead>
                              <tr class="head_color">
                                <th className="text-center">Order Status</th>
                                <th className="text-center">Order Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tracking.length > 0 ? (
                                tracking.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td className="text-center">
                                        {item.order_status}
                                      </td>
                                      <td className="text-center">
                                        {item.created_at}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <h6 className="px-4 mt-3">No Record Found</h6>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="row px-2 mt-5">
                          <div className="col-lg-12">
                            <div className="">
                              {olddriver.map((item) => {
                                return (
                                  <div key={item.id}>
                                    <div className="old_drivers">
                                      <div className="old_driver-title">
                                        <h6>Driver Change Date :</h6>
                                      </div>
                                      <div className="old_driver-title_s">
                                        {item.changed_time}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ................... */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Approvalview;
