import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import "../Driver/driver.scss";
import eye from "../../../assets/admin/assets/img/eye.svg";
import edit from "../../../assets/admin/assets/img/edit.svg";
import axios from "axios";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import { ErrorMessage } from "../../Customalert/Customalert";

const Vehicle = () => {
  const [vechilelist, setvechilelist] = useState([]);
  const [loading, setloading] = useState(true);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // .......................TablePagination...............................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ............................Table sort.....................................
  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...vechilelist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [vechilelist, sort]);

  // ....................TablePagination search.................................
  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.vehicle_manufacture
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.vehicle_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vendor_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.driver_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.documents.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // ........................vehicle lsit api get...........................

  useEffect(() => {
    const fetchvehicleData = async () => {
      try {
        const res = await axios.get("/admin/vehicle-list");
        if (res.data.status === true) {
          setvechilelist(res.data.VehicleList);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchvehicleData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  //  ...........................vechile_list table............................
  let vechile_list = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    vechile_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.vehicle_manufacture}</td>
                <td>
                  {item.vehicle_type}-{item.vehicle_capacity}
                </td>
                <td>{item.vehicle_number}</td>
                <td>{item.vehicle_status}</td>
                <td>{item.vendor_first_name}</td>
                <td>
                  {item.driver_first_name == "" &&
                  item.driver_changed_status === 1 ? (
                    <>
                      <p>
                        Driver Assigned On Another Order -{item.changed_vehicle}
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="pro-name">
                        <span>{item.driver_first_name}</span>/
                        {item.driver_last_name}
                      </div>
                    </>
                  )}
                </td>
                <td>{item.documents}</td>

                <td>{item.status}</td>
                <td>
                  <div className="actions">
                    <Link to={`/admin/vehicle/vehicle-view/${item.id}`}>
                      <img src={eye} width="35px"></img>
                    </Link>
                    {item.vehicle_status !== "Journey" ? (
                      <>
                        <Link to={`/admin/vehicle/vehicle-update/${item.id}`}>
                          <img src={edit} width="35px"></img>
                        </Link>
                      </>
                    ) : (
                      <>
                        <a className="Vehicle_tooltip">
                          <img
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Vehicle Can't Be Updated"
                            src={edit}
                            width="35px"
                          ></img>
                        </a>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  return (
    <div className="container-fluid px-4">
      <div className="title px-1 mt-4">
        <h4>
          <span className="titile_span">Vehicle List</span>
          <Link
            to="/admin/vehicle/vehicle-add"
            className="btn btn-primary admin float-end"
          >
            Add Vehicle
          </Link>
        </h4>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="card-body  px-4">
          <div className="table-responsive">
            <table className="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th>S No</th>
                  <th className="Customer_table">
                    Vehicle Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vehicle_manufacture")}
                    />
                  </th>
                  <th className="Customer_table">
                    Vehicle Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vehicle_type")}
                    />
                  </th>
                  <th className="Customer_table">
                    Vehicle Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vehicle_number")}
                    />
                  </th>{" "}
                  <th className="Customer_table">
                    Vehicle Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vehicle_status")}
                    />
                  </th>
                  <th className="Customer_table">
                    Vendor Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vendor_first_name")}
                    />
                  </th>
                  <th className="Customer_table">
                    Driver Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("driver_first_name")}
                    />
                  </th>
                  <th className="Customer_table">
                    Document Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("documents")}
                    />
                  </th>
                  <th className="Customer_table">
                    Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("status")}
                    />
                  </th>
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{vechile_list}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
