import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Warehousecreate = () => {
  const [city, setcity] = useState([]);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [addcustomer, setaddcustomer] = useState({
    address: "",
    city_id: "",
    port: "",
    status: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // .................selectedValue..............................
  const [selectedValue, setSelectedValue] = React.useState("1");

  const handleChangee = (event) => {
    setSelectedValue(event.target.value);
  };

  // .....................handleInput............................
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "status":
      case "port":
      case "city_id":
        setaddcustomer({
          ...addcustomer,
          [name]: value,
        });
        break;
      default:
        setaddcustomer({
          ...addcustomer,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  //  ...................validate...............................

  const validate = (values) => {
    const errors = {};
    if (!values.address) {
      errors.address = "Address is required !";
    }
    if (!values.city_id) {
      errors.city_id = "City is required !";
    }
    if (!values.port) {
      errors.port = "Port is required !";
    }
    return errors;
  };

  // .....................submit..........................
  const submitcustomer = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(addcustomer);
    setErrors(errors);
    const formdata = new FormData();
    formdata.append("address", addcustomer.address);
    formdata.append("city_id", addcustomer.city_id);
    formdata.append("port", addcustomer.port);
    formdata.append("status", selectedValue);
    axios
      .post(`/admin/warehouse-create`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setaddcustomer(res.data.warehouse);
          swal("Created", res.data.message, "success");
          history.push("/admin/warehouse/warehouselist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ..........city list api get...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.data.status === false) {
          swal({
            title: "Error",
            text: data.data.message,
            icon: "warning",
          });
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Warehouse Create</span>
          <Link
            to="/admin/warehouse/warehouselist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  Location(Address) <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={addcustomer.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  City <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleInput}
                  value={addcustomer.city_id}
                >
                  <option value="">--Select City--</option>
                  {city.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.city_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Port <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="port"
                  value={addcustomer.port}
                  onChange={handleInput}
                >
                  <option value="">--Select Port--</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                <p>{errors.port}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === "1"}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "1" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === "0"}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "0" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  placeholder="status"
                  name="status"
                  onChange={handleInput}
                  value="1"
                  className="form-control"
                />
              </div>

              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-secondary float-end px-5 p-2"
                  onClick={submitcustomer}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Warehousecreate;
