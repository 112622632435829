import React from "react";
import "../frontend/auth/home.scss";
import Login from "./auth/Login";
import LoginRegister from "./auth/LoginRegister";

const Home = () => {
  return (
    <div className="home">
      <ul
        className="nav nav-pills mb-3 butt-home"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item main-butt" role="presentation">
          <button
            className="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            ADMIN
          </button>
        </li>
        &nbsp; &nbsp; &nbsp;
        <li className="nav-item main-butt" role="presentation">
          <button
            className="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            VENDOR
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <Login />
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <LoginRegister />
        </div>
      </div>
    </div>
  );
};

export default Home;
