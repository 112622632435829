import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import chat_icon from "../../../message.png";
import seach_empt from "../../../seach_empt.png";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import newdriver_icon from "../../../newdriver.gif";
// ................Dialog..............

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const JobSearch = () => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [SearchValue, setSearchValue] = useState("");
  const [selectedimport, setselectedimport] = React.useState("Import");
  const [Joplist, setJoplist] = useState({});
  const [vendorname, setvendorname] = useState([]);
  const [Billinglist, setBillinglist] = useState([]);
  const [ordertrackinglist, setordertrackinglist] = useState([]);
  const [shippingaddresslist, setshippingaddresslist] = useState([]);
  const [vehicledetaillist, setvehicledetaillist] = useState([]);
  const [shipmentdetaillist, setshipmentdetaillist] = useState([]);
  const [shippingchargelist, setshippingchargelist] = useState([]);
  const [orderchargedetaillist, setorderchargedetaillist] = useState([]);
  const [respons_list, setrespons_list] = useState([]);
  const [document_list, setdocument_list] = useState([]);
  const [comments, setcomments] = useState([]);
  const [DiverValue, setDiverValue] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [legercount, setlegercount] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Searchmessage, setSearchmessage] = useState("");
  const [newdriver, setnewdriver] = useState([]);
  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const importhandleChange = (event) => {
    setselectedimport(event.target.value);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    setErrors({});
  };

  const handleDiverChange = (event) => {
    setDiverValue(event.target.value);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.SearchValue) {
      errors.SearchValue = "Search term is required !";
    }

    return errors;
  };

  // .....................editStatus.............................

  const editStatus = (e) => {
    e.preventDefault();
    setDiverValue("");
    const formdata = new FormData();
    formdata.append("driver_id", DiverValue);
    formdata.append("order_id", Joplist.id);
    axios.post(`/admin/order_driver_change`, formdata).then((res) => {
      if (res.data.status === true) {
        setnewdriver(res.data.driver_details);
        setOpen3(false);
      }
      handleClickOpen();
    });
  };

  // ...................JopSubmit.........................

  const JopSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const validationErrors = validate({ SearchValue });
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const formdata = new FormData();
      formdata.append("order_id", SearchValue);
      formdata.append("type", selectedimport);

      try {
        const res = await axios.post(`/admin/job-wise-search`, formdata);
        setrespons_list(res.data.status);
        setSearchmessage(res.data.status);
        if (res.data.status === true) {
          const vendorId = res.data.vendor_id;
          setJoplist(res.data.order_details);
          setBillinglist(res.data.billing_address);
          setordertrackinglist(res.data.order_tracking);
          setshippingaddresslist(res.data.shipping_address);
          setvehicledetaillist(res.data.vehicle_detail);
          setshipmentdetaillist(res.data.shipment_detail);
          setshippingchargelist(res.data.shipping_charges);
          setorderchargedetaillist(res.data.ordercharge_detail);
          setdocument_list(res.data.document_details);
          setcomments(res.data.order_chat);
          setlegercount(res.data.ledger_count);
          if (Object.keys(Joplist).length === 0 && vendorId !== undefined) {
            changedriver(vendorId);
          }
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      } catch (error) {
        swal("Error", error.message, "error");
      }
    }
    setIsSubmit(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 2000);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);
  // ................changedriver.....................

  const changedriver = (vendorId) => {
    const formdata = new FormData();
    formdata.append("vendor_id", vendorId);
    return axios
      .post(`/admin/change_driver_list`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setvendorname(res.data.driver_list);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          // console.error("Error: Invalid request.");
        } else if (error.response.status === 500) {
          // console.error("Error: Internal server error.");
        }
      });
  };

  useEffect(() => {
    if (typeof Joplist.vendor_id !== "undefined") {
      changedriver(Joplist.vendor_id).catch((error) => {
        console.error("Error in useEffect:", error);
      });
    }
  }, [Joplist]);

  // ........................chatSubmit........................

  const chatSubmit = () => {
    alert("message");
  };

  const handleClick = () => {
    const makepdf = document.getElementById("generatePDF");
    const mywindow = window.open("", "PRINT", "height=600,width=600");
    mywindow.document.write("<html><head><title>Lotus Logistics</title>");
    mywindow.document.write(
      '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/css/bootstrap.min.css">'
    );
    mywindow.document.write(
      "<style>@media print {.d-print-none {display: none !important;} .d-print-block {display: block !important;}" +
        " table {border-collapse: collapse;} table, th, td {border: 1px solid black;}" +
        " .row, .col {padding-left: 0px; padding-right: 0px;}" +
        " #generatePDF {display: block !important;}" +
        " h1, h2, h3, h4, h5, p {font-size: 12pt;}" +
        " button {display: block !important;}" +
        " about:blank {display: none !important;}" +
        " button.btn.btn-secondary.me-md-2.Edit {display: none !important;}" +
        " button.btn.btn-secondary.doc_bnt {display: none !important;}" +
        " titile.px-5 {padding: 10px !important;} " +
        " .Shipment_row.border {border: unset !important}" +
        " .all_input h5, .all_input p {margin-bottom: 10 !important;}" +
        "}</style>"
    );
    mywindow.document.write("</head><body>");
    mywindow.document.write(makepdf.innerHTML);
    mywindow.document.write("</body></html>");
    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    return true;
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // ..............BootstrapDialogTitle............

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    const history = useHistory();

    const handleClose = () => {
      onClose();
      // history.push("/admin/order/all-reports");
    };

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <>
      <div className="container-fluid px-4 Chat_bnt">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span">Job Number Search</span>
            <button
              className="btn btn-secondary float-end dow_bnt"
              type="button"
              onClick={handleClick}
            >
              Print
            </button>
          </h4>
        </div>

        {/* ..............................BootstrapDialog........................................ */}
        <div>
          {/* <button onClick={handleClickOpen}>open</button> */}
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "400px",
                  position: "absolute",
                  right: "10px",
                  top: " 40px",
                },
              },
            }}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Driver Changed Successfully.
            </BootstrapDialogTitle>
            <DialogContent>
              {newdriver.map((item) => (
                <div key={item.id}>
                  <div className="Driver_old">
                    <p>
                      Driver :
                      <span className="old_name">{item.old_driver}</span>
                      <img src={newdriver_icon} className="new_icon" />
                      <span className="new_name">{item.new_driver}</span>
                    </p>
                  </div>
                </div>
              ))}
            </DialogContent>
          </BootstrapDialog>
        </div>

        <div className="card mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            <form>
              <div className="row px-5 mt-4">
                <div className="col-lg-6 form-group mb-3">
                  <div className="row">
                    <div className="col-lg-3">
                      <Radio
                        checked={selectedimport === "Import"}
                        onChange={importhandleChange}
                        value="Import"
                        name="type"
                        inputProps={{ "aria-label": "Import" }}
                      />
                      Import
                    </div>
                    <div className="col-lg-3">
                      <Radio
                        checked={selectedimport === "Export"}
                        onChange={importhandleChange}
                        value="Export"
                        name="type"
                        inputProps={{ "aria-label": "Export" }}
                      />
                      Export
                    </div>
                  </div>
                </div>
                {/* ............................................... */}
                <div className="col-lg-6 form-group mb-3 search_row">
                  <div className="search-box jop_sea">
                    <input
                      type="text"
                      name="SearchValue"
                      placeholder="Search"
                      value={SearchValue}
                      onChange={handleInputChange}
                    />
                    <p>{errors.SearchValue}</p>
                  </div>
                  <div className="d-grid gap-2 d-md-block mb-3 mt-1 Search_bnt">
                    <button
                      type="button"
                      className={
                        isButtonDisabled
                          ? "btn btn-secondary sec_bnt"
                          : "btn btn-success sec_bnt"
                      }
                      onClick={JopSubmit}
                      disabled={isButtonDisabled}
                    >
                      {isButtonDisabled ? "Searching..." : "Search"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* ........................... */}
          <div className="print-content" id="generatePDF">
            {respons_list === true ? (
              <>
                <div className="row px-5 p-0 mt-3">
                  {/* ........edit driver........ */}
                  <div className="col-md-6 mb-5">
                    {" "}
                    <div className="titile mb-4">
                      <h4>
                        Order ID
                        {Joplist.length !== 0 ? (
                          <>: {Joplist.order_id}</>
                        ) : (
                          <></>
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-6 form-group mb-5">
                    {legercount !== 1 ? (
                      <>
                        <div class="d-grid gap-2 d-md-flex justify-content-md-end Edit_box">
                          <button
                            class="btn btn-secondary me-md-2 Edit"
                            type="button"
                            onClick={handleOpen3}
                          >
                            Edit Driver
                          </button>

                          <Dialog
                            open={open3}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "1000px",
                                },
                              },
                            }}
                            keepMounted
                            onClose={handleClose3}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogTitle>Edit Driver</DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-slide-description"></DialogContentText>

                              <div className="card-body">
                                <form
                                  onSubmit={editStatus}
                                  encType="multipart/form-data"
                                >
                                  <div className="row px-5">
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Booking Status</label>
                                      <input
                                        type="text"
                                        placeholder="Booking Status"
                                        name="Booking_staues"
                                        className="form-control"
                                        value={Joplist.booking_status}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Vendor Name</label>
                                      <input
                                        type="text"
                                        placeholder="Vendor Name"
                                        name="first_name"
                                        className="form-control"
                                        value={Joplist.vendor_name}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Vehicle Name</label>
                                      <input
                                        type="text"
                                        placeholder="Driver Name"
                                        name="last_name"
                                        className="form-control"
                                        value={Joplist.vehicle_name}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Vehicle Number</label>
                                      <input
                                        type="text"
                                        placeholder="Vendor Name"
                                        name="first_name"
                                        className="form-control"
                                        value={Joplist.vehicle_number}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                      <label>Vehicle Type</label>
                                      <input
                                        type="text"
                                        placeholder="Vendor Name"
                                        name="first_name"
                                        className="form-control"
                                        value={Joplist.vehicle_type}
                                      />
                                    </div>

                                    <div className="col-md-6 form-group mb-3">
                                      <label>Driver Charge</label>
                                      <select
                                        className="form-control"
                                        name="Driver_id"
                                        onChange={handleDiverChange}
                                        value={DiverValue.id}
                                      >
                                        <option value="">
                                          --Select Driver--
                                        </option>
                                        {vendorname.length > 0 &&
                                          vendorname.map((item) => {
                                            return (
                                              <option
                                                value={item.id}
                                                key={item.id}
                                              >
                                                {item.first_name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    </div>
                                  </div>
                                  <DialogActions>
                                    <Button
                                      type="submit"
                                      disabled={isButtonDisabled}
                                    >
                                      {isButtonDisabled
                                        ? "Loading..."
                                        : "Change"}
                                    </Button>
                                    <Button onClick={handleClose3}>
                                      Close
                                    </Button>
                                  </DialogActions>
                                </form>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* ..................Book_col................. */}
                  <div className="col-md-4 Book_col">
                    <div className="all_input">
                      <h5>Customer Name</h5>
                      <p>
                        {Joplist.length !== 0 ? (
                          <>{Joplist.customer_name}</>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 Book_col">
                    <div className="d-grid gap-2 d-md-block">
                      <div className="Payments_sta">
                        <h5>Payments Status</h5>
                        {Joplist.payment == "Paid" ? (
                          <>
                            <div className="paid_bnt">
                              <p>Paid</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="paid_suss">
                              <p>Unpaid</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 Book_col">
                    <div className="all_input">
                      <h5>Date & Time Created</h5>
                      <p>{Joplist.created_at}</p>
                    </div>
                  </div>
                  {/* ........vendor de........ */}
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Booking Status</h5>
                      <p>{Joplist.booking_status}</p>
                    </div>
                  </div>
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Driver</h5>
                      <p>{Joplist.driver_name}</p>
                    </div>
                  </div>
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Vendor</h5>
                      <p>{Joplist.vendor_name}</p>
                    </div>
                  </div>
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Vehicle Name</h5>
                      <p>{Joplist.vehicle_name}</p>
                    </div>
                  </div>
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Vehicle Type</h5>
                      <p>{Joplist.vehicle_type}</p>
                    </div>
                  </div>
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Vehicle Number</h5>
                      <p>{Joplist.vehicle_number}</p>
                    </div>
                  </div>
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Loading Point</h5>
                      <p>{Joplist.loading}</p>
                    </div>
                  </div>
                  <div className="col-md-4 form-group Book_col">
                    <div className="all_input">
                      <h5>Unloading Point</h5>
                      <p>{Joplist.unloading}</p>
                    </div>
                  </div>

                  {/* ................ */}
                  <div className="titile mb-4 mt-4">
                    <h4>Billing Details</h4>
                  </div>
                  <div className="col-md-4 form-group mb-3">
                    <div className="address_box">
                      <h5>Billing Address</h5>
                      {Joplist.length !== 0 ? (
                        <>
                          <div className="Billing_list">
                            <ul>
                              <li>{Billinglist.customer_name}</li>
                              <li>{Billinglist.company_name}</li>
                              <li>{Billinglist.email}</li>
                              <li>{Billinglist.contact_number}</li>
                              <li>{Billinglist.customer_address}</li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 form-group mb-3">
                    <div className="address_box">
                      <h5>Trip Schedule</h5>
                      {Joplist.length !== 0 ? (
                        <>
                          <div className="Billing_list">
                            {ordertrackinglist.map((item) => {
                              return (
                                <div className="" key={item.id}>
                                  <ul className="address_list">
                                    <li>{item.order_status}</li>
                                    <li>{item.created_at}</li>
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 form-group mb-3">
                    <div className="address_box">
                      <h5>Shipping Address</h5>
                      {Joplist.length !== 0 ? (
                        <>
                          <div className="Billing_list">
                            <ul>
                              <li>{shippingaddresslist.customer_name}</li>
                              <li>{shippingaddresslist.company_name}</li>
                              <li>{shippingaddresslist.customer_email}</li>
                              <li>{shippingaddresslist.mobile}</li>
                              <li>{shippingaddresslist.alter_mobile}</li>
                              <li>{shippingaddresslist.delivery_address}</li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {/* ......................... */}
                  <div className="col-md-6">
                    <div className="titile mb-4 mt-4">
                      <h4>Vehicle Details</h4>
                    </div>
                  </div>
                  <div className="col-md-6 dialog_bnt mt-2">
                    <button
                      class="btn btn-secondary doc_bnt"
                      onClick={handleOpen1}
                    >
                      Document
                    </button>
                    <Dialog
                      open={open1}
                      keepMounted
                      onClose={handleClose1}
                      aria-describedby="alert-dialog-slide-description"
                      sx={{
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",
                          },
                        },
                      }}
                    >
                      <DialogTitle>Document</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          <div className="row px-3">
                            <div className="col-md-12 form-group mb-4">
                              <div className="card mt-2">
                                <div class="table-responsive">
                                  <table class="table  table-striped">
                                    <thead>
                                      <tr class="table-primary">
                                        <th>Document Name</th>
                                        <th>Document</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {document_list.length > 0 ? (
                                        document_list.map((item) => {
                                          return (
                                            <tr>
                                              <td>{item.document_name}</td>
                                              <td key={item.id}>
                                                <a
                                                  href={`${url}${item.document_path}`}
                                                  target="_blank"
                                                >
                                                  <PictureAsPdfOutlinedIcon />
                                                </a>{" "}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <h6 className="px-4 mt-3">
                                          No Record Found
                                        </h6>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose1}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div className="col-md-12">
                    <div class="table-responsive">
                      <table
                        className="table table-bordered table-striped border-start border-end"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="head_color">
                            <th>Vehicle No</th>
                            <th className="Customer_table">Vehicle Name</th>
                            <th className="Customer_table">Type</th>
                            <th className="Customer_table">Feet</th>
                            <th className="Customer_table">Driver Name</th>
                            <th className="Customer_table">Vendor Name</th>
                            <th className="Customer_table">Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vehicledetaillist.length !== 0 ? (
                            <>
                              {" "}
                              <tr key={vehicledetaillist.id}>
                                <td className="emails">
                                  {vehicledetaillist.vehicle_number}
                                </td>{" "}
                                <td className="emails">
                                  {vehicledetaillist.vehicle_manufacture}
                                </td>
                                <td className="emails">
                                  {vehicledetaillist.type_name}
                                </td>
                                <td className="emails">
                                  {vehicledetaillist.capactiy_name}
                                </td>
                                <td className="emails">
                                  {vehicledetaillist.driver_name}
                                </td>
                                <td className="emails">
                                  {vehicledetaillist.vendor_name}
                                </td>
                                <td className="emails">
                                  {vehicledetaillist.contact_number}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {" "}
                              <tr>
                                <td colSpan="12" className="py-3">
                                  No Record Found
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* ............................... */}
                  <div className="titile  mb-4 mt-4">
                    <h4>Shipment Details</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered table-striped border-start border-end"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="head_color">
                            <th>Order ID</th>
                            <th className="Customer_table">Item</th>
                            <th className="Customer_table">Weight</th>
                            {/* <th className="Customer_table">Quantity</th> */}
                            <th className="Customer_table">Payment Mode</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shipmentdetaillist.length !== 0 ? (
                            <>
                              <tr key={shipmentdetaillist.id}>
                                <td className="emails">
                                  {shipmentdetaillist.order_id}
                                </td>
                                <td className="emails">
                                  {shipmentdetaillist.item}
                                </td>
                                <td className="emails">
                                  {shipmentdetaillist.weight}
                                </td>
                                <td className="emails">
                                  {shipmentdetaillist.payment_mode}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan="12" className="py-3">
                                  No Record Found
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* .................................... */}
                  <div className="titile mb-4 mt-4">
                    <h4>Shipment Charges</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="Shipment_row border">
                      <div className="row Shipping_border">
                        <div className="col-md-6">
                          <div className="Shipping_title">
                            <h6>Shipping Charges</h6>
                          </div>
                        </div>
                        <div className="col-md-6 Shipping_end">
                          <div className="Shipping_title">
                            <h6>{shippingchargelist.shipping_charges}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="row Shipping_border">
                        {orderchargedetaillist.map((item, index) => (
                          <React.Fragment key={index}>
                            <div className="col-md-6">
                              <div className="Shipping_title">
                                <h6>{item.description}</h6>
                              </div>
                            </div>
                            <div className="col-md-6 Shipping_end">
                              <div className="Shipping_title">
                                <h6>{item.amount}</h6>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      {/* {orderchargedetaillist.length !== "" ? (
                        <></>
                      ) : (
                        <>
                          <div className="row Shipping_border">
                            {orderchargedetaillist.map((item, index) => (
                              <React.Fragment key={index}>
                                <div className="col-md-6">
                                  <div className="Shipping_title">
                                    <h6>{item.description}</h6>
                                  </div>
                                </div>
                                <div className="col-md-6 Shipping_end">
                                  <div className="Shipping_title">
                                    <h6>{item.amount}</h6>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </>
                      )} */}

                      <div className="row gst_border">
                        <div className="col-md-6">
                          <h6>GST</h6>
                        </div>
                        <div className="col-md-6 Shipping_end">
                          <div className="Shipping_title">
                            <h6>{shippingchargelist.gst_amount}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-6 Shipping_end">
                    <div className="Shipping_title">
                      <h6>Total : {shippingchargelist.total_amount}</h6>
                    </div>
                  </div>
                  <div className="Amount_end">
                    <div className="shippingchargelist">
                      <h6>Amount in Words : </h6>
                    </div>
                    <div className="shippingchargelist">
                      <h6> {shippingchargelist.word_total_amount}</h6>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {Searchmessage !== "" && Searchmessage == false ? (
                  <>
                    <div className="Success_message">
                      <h6>Please check. No orders found.</h6>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="seach_back">
                  <img src={seach_empt} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {respons_list === true ? (
        <>
          <div className="Chat_bnt_icon">
            <Dialog
              open={open2}
              keepMounted
              onClose={handleClose2}
              aria-describedby="alert-dialog-slide-description"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "800px",
                  },
                },
              }}
            >
              <DialogTitle>Comments</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <div className="chat_boxs">
                        {comments.length > 0 ? (
                          comments.map((item) => {
                            return (
                              <div class="comments-list" key={item.id}>
                                {item.role == "Admin" ? (
                                  <>
                                    <ul className="message_left">
                                      <li className="admin_message">
                                        {item.role}
                                      </li>
                                      <li>
                                        <span>{item.message}</span>
                                      </li>
                                    </ul>
                                  </>
                                ) : (
                                  <>
                                    {item.role === "Customer" ? (
                                      <>
                                        <ul className="message_rigth">
                                          <li className="customer_message">
                                            {item.role} :
                                          </li>
                                          <li>
                                            <span>{item.message}</span>
                                          </li>
                                        </ul>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item.role === "Vendor" ? (
                                      <>
                                        <ul className="message_rigth">
                                          <li className="vendor_message">
                                            {item.role} :
                                          </li>
                                          <li>
                                            <span>{item.message}</span>
                                          </li>
                                        </ul>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item.role === "Driver" ? (
                                      <>
                                        <ul className="message_rigth">
                                          <li className="driver_message">
                                            {item.role} :
                                          </li>
                                          <li>
                                            <span>{item.message}</span>
                                          </li>
                                        </ul>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <h6 className="px-4 mt-2">No Record Found</h6>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <form onSubmit={chatSubmit}></form>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose2}>Close</Button>
              </DialogActions>
            </Dialog>
            <img src={chat_icon} onClick={handleOpen2} />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default JobSearch;
