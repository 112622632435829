import axios from "axios";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import eye from "../../assets/vendor/assets/img/eye.svg";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import { ErrorMessage } from "../../Components/Customalert/Customalert";

const Ongoingprocess = () => {
  const [loading, setloading] = useState(true);
  const [approval, setapproval] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // ................TablePagination............................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // .....................Table Sort.........................

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...approval].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [approval, sort]);

  // ........................Table search....................................

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.order_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.driver_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.unloading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.vehicle_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.container_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.vendor_earning)
          .toLowerCase()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.order_date.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // ..........................ongoing-orddr-list api get....................................\

  useEffect(() => {
    const fetchongoingData = async () => {
      try {
        const res = await axios.get("/vendor/ongoing-orddr-list");
        if (res.data.status === true) {
          setapproval(res.data.ongoingOrderList);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchongoingData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .......................ongoing_list...........................
  let ongoing_list = "";

  if (loading) {
  } else {
    ongoing_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>
                  <div className="pro-name">{item.order_id}</div>
                </td>
                <td>
                  <div className="pro-name">
                    {item.driver_first_name}
                    {item.driver_first_name == "" ? (
                      <></>
                    ) : (
                      <>
                        / <span>{item.driver_last_name}</span>
                      </>
                    )}
                  </div>
                </td>
                <td>{item.loading}</td>
                <td>{item.unloading}</td>
                <td>
                  {item.type} -{item.truck_capacity_name}
                </td>
                <td>{item.vehicle_number}</td>
                <td>{item.container_number}</td>
                <td>{item.order_amount}</td>
                <td>{item.order_date}</td>
                <td>{item.booking_status_id}</td>
                <td>{item.payment_status}</td>
                <td>{item.vendor_earning}</td>
                <td>
                  <Link to={`/vendor/orderview/${item.id}`}>
                    <img src={eye} width="35px"></img>
                  </Link>
                </td>
              </tr>
            );
          })
      ) : (
        <tr>
          <td colSpan="15" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  const TotalCount = filteredData.reduce((total, item) => {
    return total + item.order_amount;
  }, 0);

  const TotalearningCount = filteredData.reduce((total, item) => {
    const earning = parseFloat(item.vendor_earning);
    return isNaN(earning) ? total : total + earning;
  }, 0);

  return (
    <div className="container-fluid px-4 Export">
      <div className="title px-4 mt-4 ">
        <span className="titile_span">
          <h4>Process List</h4>
        </span>
      </div>
      <div className="">
        <div className="card mt-4  py-4">
          <div className="search-box px-4 cust-search px-3">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="card-body px-3 mt-3">
            <div className="table-responsive">
              <table className="table table-bordered table-striped border-start border-end">
                <thead>
                  <tr className="head_color">
                    <th className="Customer_table">S No</th>
                    <th className="Customer_table">
                      Job Number
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("order_id")}
                      />
                    </th>
                    <th className="Customer_table">
                      Driver Name
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("driver_first_name")}
                      />
                    </th>
                    <th className="Customer_table">
                      Loading
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("loading")}
                      />
                    </th>
                    <th className="Customer_table">
                      Unloading
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("unloading")}
                      />
                    </th>
                    <th className="Customer_table">
                      Type
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("type")}
                      />
                    </th>
                    <th className="Customer_table">
                      Vehicle Number
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("vehicle_number")}
                      />
                    </th>
                    <th className="Customer_table">
                      Container Number
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("container_number")}
                      />
                    </th>
                    <th className="Customer_table">
                      Order Amount <p>({TotalCount})</p>
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("order_amount")}
                      />
                    </th>
                    <th className="Customer_table">
                      Booking Date
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("order_date")}
                      />
                    </th>
                    <th className="Customer_table">
                      Booking Status
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("booking_status_id")}
                      />
                    </th>
                    <th className="Customer_table">
                      Payment Status
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("payment_status")}
                      />
                    </th>
                    <th className="Customer_table">
                      Total Earning <p>({TotalearningCount})</p>
                      <ImportExportIcon
                        className="Customer_table_child"
                        onClick={() => handleSort("vendor_earning")}
                      />
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{ongoing_list}</tbody>
              </table>
            </div>
            {sortedData.length > 5 ? (
              <>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ongoingprocess;
