import React, { useContext, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "../../assets/vendor/css/styles.css";
import "../../Components/admin/admincard.css";
import { Link } from "react-router-dom";
import VendorFooter from "./vendorfooter";
import VendorSidebar from "./vendorsidebar";
import vendorRouts from "../../routes/vendorRouts";
import logo from "../../assets/admin/assets/img/logo.svg";
import NotesIcon from "@mui/icons-material/Notes";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import Swal from "sweetalert2";
import { UserContext } from "../../Usecontext/UserContext";
import Alert from "../../assets/admin/assets/Music/Notification.mp3";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage } from "../../Components/Customalert/Customalert";
const VendorMasterLayout = () => {
  const { venalert, vennotify } = useContext(UserContext);
  const [vendor_notify, setvenor_notify] = useState([]);
  const [ven_belling, setven_belling] = useState("false");

  //  .......................Swal.................................
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // ............................ven_belling..................................
  let toogles = ven_belling ? "bell" : null;

  const handleven = () => {
    setven_belling((ven_belling) => !ven_belling);
  };

  // ..........................handlevendornoti......................................
  const handlevendornoti = (e) => {
    e.preventDefault();
    setven_belling((ven_belling) => !ven_belling);
    axios.post(`/vendor/vendor_notification_alert`).then((res) => {
      if (res.data.status === true) {
        setvenor_notify(res.data.NewNotificationList);
      }
    });
  };

  // .......................logoutsubmit...........................

  const logoutsubmit = (e) => {
    e.preventDefault();
    try {
      axios.post(`/vendor/logout`).then((res) => {
        if (res.data.statuscode === 200) {
          localStorage.removeItem("vendors", res.data.token);
          window.location = "/";
          localStorage.removeItem("vendor_id", res.data.vendor.id);
          localStorage.removeItem("first_name", res.data.vendor.first_name);
        } else if (res.data.status === 401) {
        }
      });
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  };

  return (
    <section className="masterlayout">
      <nav className="sb-topnav navbar navbar-expand navbar-dark nav-bgcor">
        <Link to="/vendor/dashboard" className="navbar-brand ps-3 logo-font">
          <img src={logo}></img>
        </Link>
        <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0">
          <NotesIcon />{" "}
        </button>
        <div className="heading">
          <h5>Welcome!</h5>
          <h4>{localStorage.getItem("first_name")}</h4>
        </div>
        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
          <div className="input-group"></div>
        </form>
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4 nav-icons">
          <li className="first-bell">
            <Link to="#">
              <NotificationsIcon className="ico" onClick={handlevendornoti} />
              {vennotify > 0 ? (
                <small className="bell-icon">{vennotify}</small>
              ) : (
                <small className="bell-icon">0</small>
              )}
              <div className={`second-bell${toogles}`}>
                <div className="cards">
                  <h4 className="head">
                    <h6>Notification</h6>
                    <Link>
                      <CloseIcon onClick={handleven} />
                    </Link>
                  </h4>
                  <div className="childeren-nidify">
                    <ul>
                      {vendor_notify.length > 0 ? (
                        vendor_notify.map((item) => {
                          return (
                            <>
                              <li className={`${item.status}`}>{item.note}</li>
                            </>
                          );
                        })
                      ) : (
                        <small>No Notification!</small>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/vendor/user" className="dropdown-item">
              <PersonIcon className="ico" />
            </Link>
          </li>
          <li>
            <button
              type="button"
              onClick={logoutsubmit}
              className="dropdown-item"
            >
              <LogoutIcon className="ico" />
            </button>
          </li>
        </ul>
      </nav>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <VendorSidebar />
        </div>
        <div id="layoutSidenav_content">
          <main className="main-bg">
            <Switch>
              {vendorRouts.map((vendor, index) => {
                return (
                  vendor.component && (
                    <Route
                      key={index}
                      path={vendor.path}
                      exact={vendor.exact}
                      name={vendor.name}
                      render={(props) => <vendor.component {...props} />}
                    />
                  )
                );
              })}
              <Redirect from="vendor" to="/vendor/dashboard" />
            </Switch>
          </main>
          <VendorFooter />
        </div>
        {venalert
          ? (Toast.fire({
              icon: "success",
              title: "Notification Recevied successfully",
            }),
            (<iframe src={Alert} allow="autoplay"></iframe>))
          : null}
      </div>
    </section>
  );
};

export default VendorMasterLayout;
