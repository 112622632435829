import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import swal from "sweetalert";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const Driverview = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [driverview, setdriverview] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    secondary_number: "",
    emer_num: "",
    state: "",
    state: "",
    status_name: "",
  });

  // ..............................driver-show..............................
  useEffect(() => {
    const driver_view = props.match.params.id;
    const getRouteshow = async () => {
      const res = await axios.get(`/vendor/driver-show/${driver_view}`);
      if (res.data.status === true) {
        setdriverview(res.data.driver);
      }
    };

    try {
      getRouteshow();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">
            Driver View : {driverview.driver_sid}
          </span>
          <Link
            to="/vendor/driverList"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-12 view-main">
              <div className="first-col">
                <h6>First Name</h6>
                <h6>Last Name</h6>
                <h6>Email ID</h6>
                <h6>Phone Number</h6>
                <h6>Secondary Number</h6>
                <h6>Emergency Number</h6>
                <h6>Address</h6>
              </div>
              <div className="first-col-sibling">
                <small>{driverview.first_name}</small>
                <small>{driverview.last_name}</small>
                <small>{driverview.email}</small>
                <small> {driverview.contact_number} </small>
                <small>{driverview.secondary_number}</small>
                <small>{driverview.emer_num}</small>
                <small>{driverview.address}</small>
              </div>
              <div className="second-col">
                <h6>Driver Photo</h6>
                <h6>Driver License</h6>
                <h6>Aadhaar Document</h6>
                <h6>City</h6>
                <h6>State</h6>
                <h6>Status</h6>
              </div>
              <div className="first-col-sibling">
                <small>
                  <a
                    href={`${url}${driverview.profile_photo_path}`}
                    target="_blank"
                  >
                    <PictureAsPdfIcon />
                  </a>
                </small>
                <small>
                  <a href={`${url}${driverview.license_photo}`} target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </small>
                <small>
                  <a href={`${url}${driverview.aadhar_photo}`} target="_blank">
                    <PictureAsPdfIcon />
                  </a>
                </small>
                <small> {driverview.city} </small>
                <small> {driverview.state} </small>
                <small>
                  {driverview.status_name === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{driverview.status_name}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{driverview.status_name}</span>
                      </div>
                    </>
                  )}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driverview;
