import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Link } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import swal from "sweetalert";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const VendorOrderView = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [document, setdocument] = useState([]);
  const [driver, setdriver] = useState([]);
  const [comments, setcomments] = useState([]);
  const [order, setorder] = useState({
    id: "",
    loading: "",
    unloading: "",
    delivery_address: "",
    order_amount: "",
  });

  const [vechile, setvechile] = useState({
    vendor_name: "",
    vehicle_number: "",
    type_name: "",
  });

  // ................inputValue.....................
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // .........................vendor-order-chat api get...............................
  useEffect(() => {
    const fetchData = async () => {
      try {
        const order_id = props.match.params.id;
        const res = await axios.get(`/vendor/vendor-order-chat/${order_id}`);
        if (res.data.status === true) {
          setcomments(res.data.orderchatDetail);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchData();
  }, []);

  // ..................chatSubmit......................

  const chatSubmit = (event) => {
    const order_id = props.match.params.id;
    event.preventDefault();
    const formData = new FormData();
    formData.append("message", inputValue);
    formData.append("order_id", order_id);
    axios.post(`vendor/store-vendor-chat`, formData).then((res) => {
      if (res.data.status === true) {
        setInputValue(" ");
        axios.get(`/vendor/vendor-order-chat/${order_id}`).then((result) => {
          setcomments(result.data.orderchatDetail);
        });
      } else if (res.data.status === false) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "warning",
        });
      }
    });
  };

  // ..........................vendor-order-show api get...............................
  useEffect(() => {
    const view_approval = props.match.params.id;
    try {
      axios.get(`/vendor/vendor-order-show/${view_approval}`).then((res) => {
        if (res.data.status === true) {
          setorder(res.data.order_details);
          setvechile(res.data.vehicle_details);
          setdocument(res.data.document_details);
          setdriver(res.data.driver_detail);
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      });
    } catch (error) {
      swal("Error", error.message, "error");
    }
  }, []);

  return (
    <section className="Addvechile">
      <div className="container-fluid px-4">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span">Order Approval View</span>
            <Link
              to="/vendor/orderlist"
              className="btn btn-primary admin float-end"
            >
              Go Back
            </Link>
          </h4>
        </div>
        <div className="card  mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            <ul
              className="nav nav-pills mb-3 px-5"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  ORDER DETAILS
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  VEHICLE DETAILS
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-driver-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-driver"
                  type="button"
                  role="tab"
                  aria-controls="pills-driver"
                  aria-selected="false"
                >
                  DRIVER DETAILS
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-driver-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-comments"
                  type="button"
                  role="tab"
                  aria-controls="pills-driver"
                  aria-selected="false"
                >
                  COMMENTS
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row px-5 mt-5">
                  <div className="col-md-6 view-main mb-5">
                    <div className="first-col">
                      <h6>Job Number</h6>
                      <h6>Loading</h6>
                      <h6>Unloading</h6>
                      <h6>Mobile Number</h6>
                      <h6>Secondary Number</h6>
                      <h6>Delivery Address</h6>
                    </div>
                    <div className="first-col-sibling">
                      <small>{order.order_id}</small>
                      <small>
                        {order.loading !== "" ? <>{order.loading}</> : <>N/A</>}
                      </small>
                      <small>
                        {order.unloading !== "" ? (
                          <>{order.unloading}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                      <small>
                        {order.mobile !== "" ? <>{order.mobile}</> : <>N/A</>}
                      </small>
                      <small>
                        {order.driver_contact !== "" ? (
                          <>{order.driver_contact}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                      <small>
                        {order.delivery_address !== "" ? (
                          <>{order.delivery_address}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 form-group mb-4">
                      <div className="table-responsive">
                        <table className="table table-striped border-start border-end">
                          <thead>
                            <tr className="table-primary">
                              <th>Document Name</th>
                              <th>Document</th>
                            </tr>
                          </thead>
                          <tbody>
                            {document.length > 0 ? (
                              <>
                                {document.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td>{item.document_name}</td>
                                      <td key={item.id}>
                                        <a
                                          href={`${url}${item.document_path}`}
                                          target="_blank"
                                        >
                                          <PictureAsPdfOutlinedIcon />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan="12" className="py-3">
                                  No Record Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="row px-5 mt-5">
                  <div className="col-md-6 view-main mb-5">
                    <div className="first-col">
                      <h6>Vehicle Number</h6>
                      <h6>Type Name</h6>
                      <h6>Container Size</h6>
                    </div>
                    <div className="first-col-sibling">
                      <small>
                        {vechile.vehicle_number !== "" ? (
                          <> {vechile.vehicle_number}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                      <small>
                        {vechile.type_name !== "" ? (
                          <> {vechile.type_name}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                      <small>
                        {vechile.capactiy_name !== "" ? (
                          <> {vechile.capactiy_name}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade "
                id="pills-comments"
                role="tabpanel"
                aria-labelledby="pills-driver-tab"
              >
                <div className="row px-5 mt-5 tracking_row">
                  <h4 className="order-view">Comments</h4>
                  <div className="col-md-12 mt-4">
                    <div className="chat_boxs">
                      {comments.length > 0 ? (
                        comments.map((item) => {
                          return (
                            <div class="comments-list" key={item.id}>
                              {item.role == "Vendor" ? (
                                <>
                                  <ul className="message_left">
                                    <li className="vendor_message">
                                      {item.role}
                                    </li>
                                    <li>
                                      <span>{item.message}</span>
                                    </li>
                                  </ul>
                                </>
                              ) : (
                                <>
                                  {item.role === "Customer" ? (
                                    <>
                                      <ul className="message_rigth">
                                        <li className="customer_message">
                                          {item.role} :
                                        </li>
                                        <li>
                                          <span>{item.message}</span>
                                        </li>
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.role === "Admin" ? (
                                    <>
                                      <ul className="message_rigth">
                                        <li className="admin_message">
                                          {item.role} :
                                        </li>
                                        <li>
                                          <span>{item.message}</span>
                                        </li>
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.role === "Driver" ? (
                                    <>
                                      <ul className="message_rigth">
                                        <li className="driver_message">
                                          {item.role} :
                                        </li>
                                        <li>
                                          <span>{item.message}</span>
                                        </li>
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <h6 className="px-4 mt-2">No Record Found</h6>
                      )}
                    </div>
                    <form>
                      <div className="row comment_box">
                        <div className="col-md-6">
                          <div className="col-md-12 form-group mb-3">
                            <textarea
                              class="form-control Chat_box"
                              type="text"
                              placeholder="message"
                              name="message"
                              rows="3"
                              value={inputValue}
                              onChange={handleInputChange}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            onClick={chatSubmit}
                            className="btn btn-secondary float-end px-5 p-2"
                          >
                            Chat
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade "
                id="pills-driver"
                role="tabpanel"
                aria-labelledby="pills-driver-tab"
              >
                <div className="row px-5 mt-5">
                  <div className="col-md-6 view-main mb-5">
                    <div className="first-col">
                      <h6>First Name</h6>
                      <h6>Last Name</h6>
                      <h6>Contact Number</h6>
                    </div>
                    <div className="first-col-sibling">
                      <small>
                        {driver.first_name !== "" ? (
                          <> {driver.first_name}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                      <small>
                        {driver.last_name !== "" ? (
                          <> {driver.last_name}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                      <small>
                        {driver.contact_number !== "" ? (
                          <> {driver.contact_number}</>
                        ) : (
                          <>N/A</>
                        )}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VendorOrderView;
