import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useContext } from "react";
import { ErrorMessage } from "../../Customalert/Customalert";

const Cityupdate = (props) => {
  const history = useHistory();
  const { state } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [updatecity, setupdatecity] = useState({
    name: "",
    state_id: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //  ............................handleInput......................................
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "state_id":
        setupdatecity({
          ...updatecity,
          [name]: value,
        });
        break;
      default:
        setupdatecity({
          ...updatecity,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // ............................validate.........................

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "City Name is required !";
    }
    if (!values.state_id) {
      errors.state_id = "State Name is required !";
    }
    return errors;
  };

  //  .......................city_update edit api get..................................
  useEffect(() => {
    const city_update = props.match.params.id;
    const getcity = async () => {
      const res = await axios.get(`/city-show/${city_update}`);
      if (res.data.status === true) {
        setupdatecity(res.data.City);
      }
    };

    try {
      getcity();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  // ..........................submit.................................

  const submitvendor = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(updatecity));
    setIsSubmit(true);
    const formData = new FormData();
    formData.append("name", updatecity.name);
    formData.append("state_id", updatecity.state_id);
    formData.append("id", updatecity.id);
    axios
      .post(`/city-update`, formData)
      .then((res) => {
        if (res.data.status === true) {
          setupdatecity(res.data.city);
          swal("Updated", res.data.message, "success");
          history.push("/admin/city/citylist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ...........................isSubmit...............................
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  return (
    <>
      <div className="container-fluid px-4">
        <div className="titile px-1 mb-5 mt-4">
          <h4>
            <span className="titile_span">Update City</span>
            <Link
              to="/admin/city/citylist"
              className="btn btn-primary admin float-end"
            >
              Go Back
            </Link>
          </h4>
        </div>
        <div className="card mt-4">
          <div className="card-body px-3 mt-3 mb-3">
            <form>
              <div className="row px-5">
                <div className="col-md-6 form-group mb-3">
                  <label>
                    City Name <span className="star_icon">*</span>
                  </label>
                  <input
                    placeholder="First Name"
                    name="name"
                    onChange={handleInput}
                    value={updatecity.name}
                    className="form-control"
                  />
                  <p>{errors.name}</p>
                </div>
                <div className="col-md-6 form-group mb-3 mt-2">
                  <label>State</label>
                  <select
                    className="form-control"
                    name="state_id"
                    onChange={handleInput}
                    value={updatecity.state_id}
                  >
                    <option value="">--Select State--</option>
                    {state.map((itm) => {
                      return (
                        <option value={itm.id} key={itm.id}>
                          {itm.name}
                        </option>
                      );
                    })}
                  </select>
                  <p>{errors.state_id}</p>
                </div>
                <div className="col-md-6 form-group">
                  <input
                    type="hidden"
                    name="id"
                    onChange={handleInput}
                    value={updatecity.id}
                    className="form-control"
                  />
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    className="btn btn-secondary float-end px-5 p-2"
                    onClick={submitvendor}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cityupdate;
