import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import eye from "../../../assets/admin/assets/img/eye.svg";
import "../../admin/Driver/driver.scss";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import swal from "sweetalert";
import TablePagination from "@mui/material/TablePagination";

const Customerkyc = () => {
  const [loading, setloading] = useState(true);
  const [Kyclist, setKyclist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // ........................TablePagination...............................

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // ...........................Table sort.................................

  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...Kyclist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [Kyclist, sort]);

  // ............................Table search............................

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.contact_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.kyc_date_and_time
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.kyc_status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // .........................pending api get................................

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const res = await axios.get("/admin/kyc-pending");
        if (res.data.status === true) {
          setKyclist(res.data.customerlist);
          setloading(false);
        }
      } catch (error) {
        swal("Error", error.message, "error");
      }
    };

    fetchCustomerData();
    // const interval = setInterval(fetchCustomerData, 1000);
    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // ................................kyc_list..................................

  let kyc_list = "";

  if (loading) {
    return (
      <Player
        autoplayuserPerpage
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    kyc_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td>{item.first_name}</td>
                <td>{item.contact_number}</td>
                <td className="emails">{item.email}</td>
                <td>{item.kyc_date_and_time}</td>
                <td className="Kyc_table">
                  {item.kyc_status == "Approved" ? (
                    <p className="Kyc_p">{item.kyc_status}</p>
                  ) : item.kyc_status == "Pending" ? (
                    <p className="Kyc_Pending">{item.kyc_status}</p>
                  ) : item.kyc_status == "Reject" ? (
                    <p className="Kyc_reject">{item.kyc_status}</p>
                  ) : item.kyc_status == "Not upload" ? (
                    <p className="Kyc_Pending">{item.kyc_status}</p>
                  ) : null}
                </td>
                <td>
                  <div className="actions">
                    <Link to={`/admin/customer/customer-view/${item.id}`}>
                      <img src={eye} width="35px"></img>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="15" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  return (
    <div className="container-fluid px-4 Driver">
      <div className="title px-1 mt-4 ">
        <h4>
          <span className="titile_span">Customer KYC Pending</span>
        </h4>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="card-body px-4">
          <div class="table-responsive">
            <table class="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th>S No</th>
                  <th className="emails Customer_table">
                    Customer Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("first_name")}
                    />
                  </th>
                  <th className="Customer_table">
                    Phone Number
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("contact_number")}
                    />
                  </th>
                  <th className="emails Customer_table">
                    Email ID
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("email")}
                    />
                  </th>
                  <th className="Customer_table">
                    Created On
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("kyc_date_and_time")}
                    />
                  </th>
                  <th className="Customer_table">
                    KYC Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("email")}
                    />
                  </th>
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{kyc_list}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Customerkyc;
