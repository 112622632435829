import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import swal from "sweetalert";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const Vendorvehicleview = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [vehicledoc, setvehicledoc] = useState([]);
  const [driverhistroy, setdriverhistroy] = useState([]);
  const [vechileview, setvechileview] = useState({
    vehicle_manufacture: "",
    vehicle_type: "",
    truck_capacity_name: "",
    vehicle_number: "",
    driver_first_name: "",
    driver_last_name: "",
    city_id: "",
    state_name: "",
    make_year: "",
    status_name: "",
  });

  // .....................vehicle-show.......................

  useEffect(() => {
    const vechile_show = props.match.params.id;
    const getRouteshow = async () => {
      const res = await axios.get(
        `/vendor/vendor-vehicle-show/${vechile_show}`
      );
      if (res.data.status === true) {
        setvechileview(res.data.vehicle);
        setvehicledoc(res.data.documentdetails);
        setdriverhistroy(res.data.vehicledriverhistory);
      }
    };

    try {
      getRouteshow();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          Vehicle View
          <Link
            to="/vendor/vehiclelist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-12 mb-4 view-main">
              <div className="first-col">
                <h6>Driver First Name </h6>
                <h6>Driver Last Name</h6>
                <h6>Vehicle Manufacturer</h6>
                <h6>Vehicle Type</h6>
                <h6>Container Size</h6>
              </div>
              <div className="first-col-sibling">
                <small>{vechileview.driver_first_name}</small>
                <small>{vechileview.driver_last_name}</small>
                <small>{vechileview.vehicle_manufacture}</small>
                <small>{vechileview.vehicle_type}</small>
                <small>{vechileview.truck_capacity_name}</small>
              </div>
              <div className="second-col">
                <h6>Vehicle Number</h6>
                <h6>City</h6>
                <h6>State</h6>
                <h6>Make Year</h6>
                <h6>Status</h6>
              </div>
              <div className="first-col-sibling">
                <small>{vechileview.vehicle_number}</small>
                <small>{vechileview.city_name}</small>
                <small>{vechileview.state_name}</small>
                <small>{vechileview.make_year}</small>
                <small>
                  {vechileview.status_name === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{vechileview.status_name}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{vechileview.status_name}</span>
                      </div>
                    </>
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row px-5">
            <div className="col-md-12 form-group mb-4">
              <div class="table-responsive">
                <table class="table  table-striped border-start border-end">
                  <thead>
                    <tr className="head_color">
                      <th>Document Name</th>
                      <th>Document</th>
                      <th>Document Expiry</th>
                      <th>Updated Date</th>
                      <th>Document Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicledoc.length > 0 ? (
                      vehicledoc.map((item) => {
                        return (
                          <tr>
                            <td>{item.document_type_name}</td>
                            <td key={item.id}>
                              <a
                                href={`${url}${item.document_path}`}
                                target="_blank"
                              >
                                <PictureAsPdfOutlinedIcon />
                              </a>
                            </td>
                            <td>{item.certificate_to}</td>
                            <td>{item.updated_at}</td>
                            <td>{item.documents}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <h6 className="px-4 mt-3">No Record Found</h6>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className=" row px-5">
            <div className="col-md-12">
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    <h5>Vehicle Driver History</h5>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  class="accordion-collapse expand"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div class="accordion-body p-2">
                    <div className="card-body mt-2 p-0">
                      <div class="table-responsive">
                        <table class="table table-striped border-start border-end">
                          <thead>
                            <tr className="head_color">
                              <th>Driver Name</th>
                              <th>From Date</th>
                              <th>To Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {driverhistroy.length > 0 ? (
                              driverhistroy.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.first_name}</td>
                                    <td>{item.from_date}</td>
                                    <td>{item.to_date}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <h6 className="px-3 mt-2">No Record Found</h6>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendorvehicleview;
