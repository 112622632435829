import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../admin/Driver/driver.css";
import eye from "../../../assets/admin/assets/img/eye.svg";
import edit from "../../../assets/admin/assets/img/edit.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "../../../assets/admin/lottie/lf20_xkepedzo.json";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TablePagination from "@mui/material/TablePagination";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";
const RouteList = () => {
  const [loading, setloading] = useState(true);
  const [routelist, setroutelist] = useState([]);
  const [sort, setSort] = useState({ key: "", direction: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  //  ...................TablePagination ..................................
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //  ..........................Table sort........................................
  const handleSort = (key) => {
    const direction =
      sort.key === key && sort.direction === "asc" ? "desc" : "asc";
    setSort({ key, direction });
  };

  const sortedData = useMemo(() => {
    return [...routelist].sort((a, b) => {
      if (sort.direction === "asc") {
        return a[sort.key] > b[sort.key] ? 1 : -1;
      }
      return b[sort.key] > a[sort.key] ? 1 : -1;
    });
  }, [routelist, sort]);

  // ..........................Table search....................................

  const applySearchQuery = useCallback(() => {
    const newData = sortedData.filter(
      (item) =>
        item.loading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.unloading.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.truck_type_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.truck_capacity_id
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        String(item.costing_prices)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setFilteredData(newData);
  }, [searchQuery, sortedData]);

  useEffect(() => {
    applySearchQuery();
  }, [applySearchQuery]);

  // .........................route-list............................

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const res = await axios.get("/admin/route-list");
        if (res.data.status === true) {
          setroutelist(res.data.routelist);
          setloading(false);
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchCustomerData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchQuery]);

  // .....................route_list...............................

  let route_list = "";

  if (loading) {
    return (
      <Player
        autoplay
        loop
        src={Lottie}
        style={{ height: "500px", width: "500px" }}
      ></Player>
    );
  } else {
    route_list =
      filteredData.length > 0 ? (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            const serialNo = page * rowsPerPage + index + 1;
            return (
              <tr key={item.id}>
                <td>{serialNo}</td>
                <td className="emails">{item.loading}</td>
                <td className="emails">{item.unloading}</td>
                <td>{item.type}</td>
                <td>{item.truck_type_id}</td>
                <td>{item.truck_capacity_id}</td>
                <td>{item.costing_price}</td>
                <td>{item.status}</td>
                <td>
                  <div className="actions">
                    <Link to={`/admin/route/route-view/${item.id}`}>
                      <img src={eye} width="35px"></img>
                    </Link>
                    <Link to={`/admin/route/route-update/${item.id}`}>
                      <img src={edit} width="35px"></img>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
      ) : (
        <tr key="no-record">
          <td colSpan="12" className="py-3">
            No Record Found
          </td>
        </tr>
      );
  }

  return (
    <div className="container-fluid px-4">
      <div className="title px-1 mt-4">
        <h4>
          <span className="titile_span">Route List</span>
          <Link
            to="/admin/route/route-add"
            className="btn btn-primary admin float-end"
          >
            Add Route
          </Link>
        </h4>
      </div>
      <div className="card mt-4 mb-4  py-4">
        <div className="search-box px-4 cust-search px-3">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="card-body px-4">
          <div className="table-responsive">
            <table className="table table-bordered table-striped border-start border-end">
              <thead>
                <tr className="head_color">
                  <th>S No</th>
                  <th className="emails Customer_table">
                    Loading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("loading")}
                    />
                  </th>
                  <th className="emails Customer_table">
                    Unloading
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("unloading")}
                    />
                  </th>
                  {/* <th className="emails Customer_table">
                    Vendor Name
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("vendor")}
                    />
                  </th> */}
                  <th className="emails Customer_table">
                    Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("type")}
                    />
                  </th>
                  <th className="Customer_table">
                    Vehicle Type
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("truck_type_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Container Size
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("truck_capacity_id")}
                    />
                  </th>
                  <th className="Customer_table">
                    Costing Price
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("costing_price")}
                    />
                  </th>
                  <th className="Customer_table">
                    Status
                    <ImportExportIcon
                      className="Customer_table_child"
                      onClick={() => handleSort("status")}
                    />
                  </th>
                  <th className="actio">Action</th>
                </tr>
              </thead>
              <tbody>{route_list}</tbody>
            </table>
          </div>
          {sortedData.length > 5 ? (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={routelist.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RouteList;
