import React, { useContext, useEffect } from "react";
import "./vendor.scss";
import axios from "axios";
import { UserContext } from "../../Usecontext/UserContext";

const VendorFooter = () => {
  const { setvennotify, setvenalert } = useContext(UserContext);
  useEffect(() => {
    const interval = setInterval(() => {
      axios.get(`/vendor/vendor_alert_count`).then((res) => {
        if (res.data.status === true) {
          setvennotify(res.data.new_notification_count);
          setvenalert(res.data.new_alert_count);
        }
      });
    }, 12000);
    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="py-2 bg-light mt-auto footer ">
      <div className="container-fluid px-2">
        <div className="d-flex align-items-center justify-content-between small">
          <div className="text-muted">
            Copyright &copy; Lotus Logistics 2023
          </div>
        </div>
      </div>
    </footer>
  );
};

export default VendorFooter;
