import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ErrorMessage } from "../../Customalert/Customalert";

const Viewadmin = (props) => {
  const [warehouseshow, setwarehouseshow] = useState({
    name: "",
    email: "",
    mobileno: "",
    status_name: "",
  });

  // ..........................user-show..........................

  useEffect(() => {
    const user_list = props.match.params.id;
    const getvehicle = async () => {
      const res = await axios.get(`admin/user-show/${user_list}`);
      if (res.data.status === true) {
        setwarehouseshow(res.data.user);
      }
    };

    try {
      getvehicle();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Admin Show</span>
          <Link
            to="/admin/adminlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-6 view-main">
              <div className="first-col">
                <h6>Name</h6>
                <h6>Email</h6>
                <h6>Mobile Number</h6>
                <h6>Status</h6>
              </div>
              <div className="first-col-sibling">
                <small>{warehouseshow.name}</small>
                <small>{warehouseshow.email}</small>
                <small>{warehouseshow.mobileno}</small>
                <small>
                  {warehouseshow.status_name === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{warehouseshow.status_name}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{warehouseshow.status_name}</span>
                      </div>
                    </>
                  )}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewadmin;
