import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./customer.scss";
import swal from "sweetalert";

const VendorAllorder = (props) => {
  const [vendororder, setvendororder] = useState([]);
  const [vendordetails, setvendordetails] = useState({
    vendor_name: "",
    email: "",
    contact_number: "",
    commission_per: "",
    status: "",
    priority: "",
  });

  // .................vendor-all-order list api get.............................

  useEffect(() => {
    const order_show = props.match.params.id;
    const getVehicle = async () => {
      const res = await axios.get(`/admin/vendor-all-order/${order_show}`);
      if (res.data.status === true) {
        setvendororder(res.data.VendorOrderList);
        setvendordetails(res.data.vendordetails);
      }
    };
    try {
      getVehicle();
    } catch (error) {
      swal("Error", error.message, "error");
    }
  }, []);

  return (
    <div className="container-fluid px-4 customer-view">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Vendor List</span>
          <Link
            to="/admin/vendor/vendorlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-6 view-main">
              <div className="first-col">
                <h6>Vendor Name </h6>
                <h6>Email ID</h6>
                <h6>Phone Number</h6>
                <h6>Commission</h6>
                <h6>Priority Number</h6>
                <h6>Status</h6>
              </div>
              <div className="first-col-sibling">
                <small>{vendordetails.vendor_name}</small>
                <small>{vendordetails.email}</small>
                <small>{vendordetails.contact_number}</small>
                <small>{vendordetails.commission_per}</small>
                <small>{vendordetails.priority}</small>
                <small>
                  {vendordetails.status === "Active" ? (
                    <>
                      <div className="Status_active">
                        <span>{vendordetails.status}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Status_inactive">
                        <span>{vendordetails.status}</span>
                      </div>
                    </>
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row px-2 mt-4">
            <div className="col-md-12">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      <h5>Vendor OrderList</h5>
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse expand "
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <div className="">
                        <div class="table-responsive">
                          <table class="table  table-bordered table-striped border-start border-end">
                            <thead>
                              <tr className="head_color">
                                <th>Customer Name</th>
                                <th>Driver Name</th>
                                <th>Customer Company Name</th>
                                <th>Vehicle Number</th>
                                <th>loading</th>
                                <th>Unloading</th>
                                <th>Order Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {vendororder.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.customer_first_name}</td>
                                    <td>{item.driver_first_name}</td>
                                    <td>{item.customer_company_name}</td>
                                    <td>{item.vehicle_number}</td>
                                    <td>{item.loading}</td>
                                    <td>{item.unloading}</td>
                                    <td>{item.order_amount}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorAllorder;
