import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Radio from "@mui/material/Radio";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Updatedriver = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const { state } = useContext(UserContext);
  const [city, setcity] = useState([]);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [updatedrive, setupdatedrive] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    profile_photo_path: "",
    contact_number: "",
    address: "",
    age: "",
    secondary_number: "",
    emer_num: "",
    aadhar_number: "",
    pan_number: "",
    status: "",
  });

  // .....................showPassword.............................

  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // ......................selectedValue.................................
  const [selectedValue, setSelectedValue] = React.useState();

  const handleChangee = (event) => {
    setSelectedValue(parseInt(event.target.value));
    if (selectedValue === 1) {
      return "true";
    } else {
      return "false";
    }
  };

  useEffect(() => {
    setSelectedValue(updatedrive.status);
  }, [updatedrive]);

  // ................Document.......................
  const [profile, setprofile] = useState("");
  const [aadhar, setaadhar] = useState("");
  const [license, setlicense] = useState("");

  function profileChange(event) {
    setprofile(event.target.files[0]);
  }

  function aadharChange(event) {
    setaadhar(event.target.files[0]);
  }

  function licenseChange(event) {
    setlicense(event.target.files[0]);
  }

  // ...........................handleInput..............................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setupdatedrive({
          ...updatedrive,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
        setupdatedrive({
          ...updatedrive,
          [name]: value,
        });
        break;
      case "vendor_id":
      case "state_id":
      case "city_id":
        setupdatedrive({
          ...updatedrive,
          [name]: value,
        });
        break;
      case "address":
        setupdatedrive({
          ...updatedrive,
          [name]: value,
        });
        break;
      case "contact_number":
      case "secondary_number":
      case "emer_num":
        setupdatedrive({
          ...updatedrive,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      default:
        setupdatedrive({
          ...updatedrive,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // .........................validate...........................
  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "First Name is required !";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required !";
    }

    if (!values.email) {
      errors.email = "Email ID is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }

    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password Confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }
    if (!values.contact_number) {
      errors.contact_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.contact_number)) {
      errors.contact_number = "Phone Number must be 10 digits !";
    }
    if (!values.secondary_number) {
      errors.secondary_number = "Secondary  Number is required !";
    } else if (!phoneRegex.test(values.secondary_number)) {
      errors.secondary_number = "Secondary  Number must be 10 digits !";
    }
    if (!values.city_id) {
      errors.city_id = "City is required !";
    }
    if (!values.address) {
      errors.address = "Address is required !";
    }
    if (!values.state_id) {
      errors.state_id = "State is required !";
    }

    if (!values.vendor_id) {
      errors.vendor_id = "Vendor Name is required !";
    }
    if (!profile) {
      errors.profile = "Profile Document is required !";
    }

    if (!license) {
      errors.license = "License Document is required !";
    }

    if (!aadhar) {
      errors.aadhar = "Aadhaar Document is required !";
    }

    return errors;
  };

  // .........................driver edit api get..................................
  useEffect(() => {
    const getDriver = async () => {
      const driver_update = props.match.params.id;
      const res = await axios.get(`/admin/driver-show/${driver_update}`);
      if (res.data.status === true) {
        setupdatedrive(res.data.driver);
      }
    };

    try {
      getDriver();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  // ................................submit............................
  const submitdriver = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(updatedrive);
    setErrors(errors);
    setIsSubmit(true);
    if (
      updatedrive.password &&
      updatedrive.password.length > 0 &&
      updatedrive.password !== updatedrive.password_confirmation
    ) {
      swal("Passwords Miss Match", "warning");
    } else {
      const formData = new FormData();
      formData.append("first_name", updatedrive.first_name);
      formData.append("last_name", updatedrive.last_name);
      formData.append("contact_number", updatedrive.contact_number);
      formData.append("secondary_number", updatedrive.secondary_number);
      formData.append("emer_num", updatedrive.emer_num);
      formData.append("email", updatedrive.email);
      formData.append("state_id", updatedrive.state_id);
      formData.append("city_id", updatedrive.city_id);
      formData.append("status", selectedValue);
      formData.append("address", updatedrive.address);
      formData.append("userid", updatedrive.id);
      formData.append("password", updatedrive.password);
      formData.append(
        "password_confirmation",
        updatedrive.password_confirmation
      );
      if (profile != "") {
        formData.append("profile_photo_path", profile);
      }
      if (license != "") {
        formData.append("license_photo", license);
      }
      if (aadhar != "") {
        formData.append("aadhar_photo", aadhar);
      }
      axios
        .post(`/admin/driver-update`, formData)
        .then((res) => {
          if (res.data.status === true) {
            setupdatedrive(res.data.driver);
            swal("Updated", res.data.message, "success");
            history.push("/admin/driver/driverlist");
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch((error) => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ............................isSubmit...........................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  // ..........city list get api ...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.status === false) {
          swal("Error", data.status, "error");
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // ...........................Document show................................

  const getprofileimg = () => {
    if (updatedrive.profile_photo_path != "") {
      return (
        <div className="pdt_child">
          <a href={`${url}${updatedrive.profile_photo_path}`} target="_blank">
            <PictureAsPdfIcon />
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const getlicenseimg = () => {
    if (updatedrive.license_photo != "") {
      return (
        <div className="pdt_child">
          <a href={`${url}${updatedrive.license_photo}`} target="_blank">
            <PictureAsPdfIcon />
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const getaadharimg = () => {
    if (updatedrive.aadhar_photo != "") {
      return (
        <div className="pdt_child">
          <a href={`${url}${updatedrive.aadhar_photo}`} target="_blank">
            <PictureAsPdfIcon />
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span"> Update Driver</span>
          <Link
            to="/admin/driver/driverlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  First Name <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="First Name"
                  name="first_name"
                  onChange={handleInput}
                  value={updatedrive.first_name}
                  className="form-control"
                />
                <p>{errors.first_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Last Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  onChange={handleInput}
                  value={updatedrive.last_name}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  State <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="state_id"
                  onChange={handleInput}
                  value={updatedrive.state_id}
                >
                  <option value="">--Select State--</option>
                  {state.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.state_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  City <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleInput}
                  value={updatedrive.city_id}
                >
                  <option value="">--Select City--</option>
                  {city.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.city_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Phone Number <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Phone Number"
                  name="contact_number"
                  value={updatedrive.contact_number}
                  onChange={handleInput}
                  className="form-control"
                />
                {errors.contact_number && <p>{errors.contact_number}</p>}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Secondary Number <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="secondary Number"
                  name="secondary_number"
                  onChange={handleInput}
                  value={updatedrive.secondary_number}
                  className="form-control"
                />
                {errors.secondary_number && <p>{errors.secondary_number}</p>}
              </div>

              <div className="col-md-6 form-group mb-3">
                <label>Emergency Number</label>
                <input
                  placeholder="Emergency Number"
                  onChange={handleInput}
                  value={updatedrive.emer_num}
                  name="emer_num"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Address <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={updatedrive.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Driver Photo</label>
                <input
                  type="file"
                  placeholder="Driver Photo"
                  name="driver_Photo"
                  onChange={profileChange}
                  className="form-control"
                />

                {getprofileimg()}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Driving License</label>
                <input
                  type="file"
                  placeholder="Driver License"
                  name="driver_license"
                  onChange={licenseChange}
                  className="form-control"
                />

                {getlicenseimg()}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Aadhaar Doument</label>
                <input
                  type="file"
                  placeholder="Aadhaar Doument"
                  name="aadhaar_doc"
                  onChange={aadharChange}
                  className="form-control"
                />

                {getaadharimg()}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Email ID <span className="star_icon">*</span>
                </label>
                <input
                  placeholder="Email ID"
                  name="email"
                  onChange={handleInput}
                  value={updatedrive.email}
                  className="form-control"
                  disabled
                />
                <p>{errors.email}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Password</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleInput}
                    value={updatedrive.password}
                    name="password"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowPassword}
                    className="password-toggle-btn "
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Confirm Password</label>
                <div className="password-input">
                  <input
                    type={comfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleInput}
                    value={updatedrive.password_confirmation}
                    name="password_confirmation"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowcomfirmPassword}
                    className="password-toggle-btn "
                  >
                    {comfirmPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === 1}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "true" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === 0}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "false" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  name="country_id"
                  value="1"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  name="status"
                  value="1"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  name="userid"
                  value={updatedrive.id}
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <button
                  onClick={submitdriver}
                  disabled={isButtonDisabled}
                  className="btn btn-secondary float-end px-5 p-2"
                >
                  {isButtonDisabled ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Updatedriver;
