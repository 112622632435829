import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../Usecontext/UserContext";
import { useContext } from "react";
import Radio from "@mui/material/Radio";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ErrorMessage } from "../../Customalert/Customalert";

const AddDriver = () => {
  const { state } = useContext(UserContext);
  const [city, setcity] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [vendorname, setvendorname] = useState([]);
  const history = useHistory();
  const [adddriver, setadddriver] = useState({
    first_name: "",
    last_name: "",
    age: "",
    contact_number: "",
    secondary_number: "",
    aadharnumber: "",
    pan: "",
    emer_num: "",
    email: "",
    state_id: "",
    city_id: "",
    vendor_id: "",
  });

  // ......................showPassword.........................

  const [showPassword, setShowPassword] = useState(false);
  const [comfirmPassword, setcomfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcomfirmPassword = () => {
    setcomfirmPassword(!comfirmPassword);
  };

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // .....................selectedValue..................
  const [selectedValue, setSelectedValue] = React.useState("1");
  const handleChangee = (event) => {
    setSelectedValue(event.target.value);
  };

  // .......................Document..................................
  const [profile, setprofile] = useState("");
  const [aadhar, setaadhar] = useState("");
  const [license, setlicense] = useState("");

  function profileChange(event) {
    setprofile(event.target.files[0]);
  }

  function aadharChange(event) {
    setaadhar(event.target.files[0]);
  }

  function licenseChange(event) {
    setlicense(event.target.files[0]);
  }

  // ......................handleInput............................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "password":
      case "password_confirmation":
        setadddriver({
          ...adddriver,
          [name]: value.replace(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
            ""
          ),
        });
        break;
      case "email":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "vendor_id":
      case "state_id":
      case "city_id":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "address":
        setadddriver({
          ...adddriver,
          [name]: value,
        });
        break;
      case "contact_number":
      case "secondary_number":
      case "emer_num":
        setadddriver({
          ...adddriver,
          [name]: value.replace(/\D/g, "").slice(0, 10),
        });
        break;
      default:
        setadddriver({
          ...adddriver,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  //  ........................validate..............................

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "First Name is required !";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required !";
    }

    if (!values.email) {
      errors.email = "Email ID is required !";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email ID address !";
    }

    if (!values.password) {
      errors.password = "Password is required !";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = "Password confirmation is required !";
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = "Passwords do not match !";
    }
    if (!values.contact_number) {
      errors.contact_number = "Phone Number is required !";
    } else if (!phoneRegex.test(values.contact_number)) {
      errors.contact_number = "Phone Number must be 10 digits !";
    }
    if (!values.secondary_number) {
      errors.secondary_number = "Secondary  Number is required !";
    } else if (!phoneRegex.test(values.secondary_number)) {
      errors.secondary_number = "Secondary  Number must be 10 digits !";
    }
    if (!values.city_id) {
      errors.city_id = "City is required !";
    }
    if (!values.address) {
      errors.address = "Address is required !";
    }
    if (!values.state_id) {
      errors.state_id = "State is required !";
    }
    if (!values.vendor_id) {
      errors.vendor_id = "Vendor Name is required !";
    }

    if (!profile) {
      errors.profile = "Profile Document is required !";
    }

    if (!license) {
      errors.license = "License Document is required !";
    }

    if (!aadhar) {
      errors.aadhar = "Aadhaar Document is required !";
    }

    return errors;
  };

  // .......................submit........................

  const submitdriver = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const errors = validate(adddriver);
    setErrors(errors);
    setIsSubmit(true);
    if (
      adddriver.password &&
      adddriver.password.length > 0 &&
      adddriver.password !== adddriver.password_confirmation
    ) {
      swal("Passwords Miss Match", "warning");
    } else {
      const formData = new FormData();
      formData.append("first_name", adddriver.first_name);
      formData.append("last_name", adddriver.last_name);
      formData.append("vendor_id", adddriver.vendor_id);
      formData.append("contact_number", adddriver.contact_number);
      formData.append("secondary_number", adddriver.secondary_number);
      formData.append("emer_num", adddriver.emer_num);
      formData.append("address", adddriver.address);
      formData.append("email", adddriver.email);
      formData.append("password", adddriver.password);
      formData.append("password_confirmation", adddriver.password_confirmation);
      formData.append("state_id", adddriver.state_id);
      formData.append("city_id", adddriver.city_id);
      formData.append("status", selectedValue);
      formData.append("profile_photo_path", profile);
      if (profile != "") {
        formData.append("profile_photo_path", profile.name);
      }
      formData.append("license_photo", license);
      if (license != "") {
        formData.append("license_photo", license.name);
      }
      formData.append("aadhar_photo", aadhar);
      if (aadhar != "") {
        formData.append("aadhar_photo", aadhar.name);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`/admin/driver-create`, formData, config)
        .then((res) => {
          if (res.data.status === true) {
            setadddriver(res.data.driver);
            swal("Created", res.data.message, "success");
            history.push("/admin/driver/driverlist");
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        })
        .catch(() => {
          ErrorMessage({ message: "Something Went Wrong !" });
        });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ......................isSubmit......................

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
    }
  }, [errors]);

  // .......................vendor list api get..................
  useEffect(() => {
    axios.get(`/vendorlist`).then((res) => {
      if (res.data.status === true) {
        setvendorname(res.data.vendor);
      } else if (res.data.status === false) {
        swal("Error", res.data.status, "error");
      }
    });
  }, []);

  // ..........city list get api ...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.status === false) {
          swal("Error", data.status, "error");
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Add New Driver</span>
          <Link
            to="/admin/driver/driverlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form novalidate>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  First Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  onChange={handleInput}
                  value={adddriver.first_name}
                  className="form-control"
                />
                <p>{errors.first_name}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Last Name <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  onChange={handleInput}
                  value={adddriver.last_name}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Vendor Name <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="vendor_id"
                  onChange={handleInput}
                  value={adddriver.vendor_id}
                >
                  <option value="">--Select Vendor--</option>
                  {vendorname.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.first_name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.vendor_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  State <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="state_id"
                  onChange={handleInput}
                  value={adddriver.state_id}
                >
                  <option value="">--Select State--</option>
                  {state.map((itm) => {
                    return (
                      <option value={itm.id} key={itm.id}>
                        {itm.name}
                      </option>
                    );
                  })}
                  <option>State</option>
                </select>
                <p>{errors.state_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  City <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleInput}
                  value={adddriver.city_id}
                >
                  <option value="">--Select City--</option>
                  {city.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p>{errors.city_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Phone Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="contact_number"
                  onChange={handleInput}
                  value={adddriver.contact_number}
                  className="form-control"
                />
                {errors.contact_number && <p>{errors.contact_number}</p>}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Secondary Number <span className="star_icon">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Secondary Number"
                  name="secondary_number"
                  onChange={handleInput}
                  value={adddriver.secondary_number}
                  className="form-control"
                />
                {errors.secondary_number && <p>{errors.secondary_number}</p>}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>Emergency Number</label>
                <input
                  type="text"
                  placeholder="Emergency Number"
                  onChange={handleInput}
                  value={adddriver.emer_num}
                  name="emer_num"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Address <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={adddriver.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Driver Photo <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="Driver Photo"
                  name="driver_Photo"
                  onChange={profileChange}
                  className="form-control"
                />
                <p>{errors.profile}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Driving License <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="Driver License"
                  name="driver_license"
                  onChange={licenseChange}
                  className="form-control"
                />
                <p>{errors.license}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Aadhaar Doument <span className="star_icon">*</span>
                </label>
                <input
                  type="file"
                  placeholder="Aadhaar Doument"
                  name="aadhar_doc"
                  onChange={aadharChange}
                  className="form-control"
                />
                <p>{errors.aadhar}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Email ID <span className="star_icon">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Email ID"
                  name="email"
                  onChange={handleInput}
                  value={adddriver.email}
                  className="form-control"
                />
                <p>{errors.email}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Password <span className="star_icon">*</span>
                </label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleInput}
                    value={adddriver.password}
                    name="password"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowPassword}
                    className="password-toggle-btn "
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                <p>{errors.password}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Confirm Password <span className="star_icon">*</span>
                </label>
                <div className="password-input">
                  <input
                    type={comfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleInput}
                    value={adddriver.password_confirmation}
                    name="password_confirmation"
                    className="form-control"
                  />
                  <a
                    onClick={toggleShowcomfirmPassword}
                    className="password-toggle-btn "
                  >
                    {comfirmPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                  </a>
                </div>
                <p>{errors.password_confirmation}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === "1"}
                      onChange={handleChangee}
                      value="1"
                      name="status"
                      inputProps={{ "aria-label": "1" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === "0"}
                      onChange={handleChangee}
                      value="0"
                      name="status"
                      inputProps={{ "aria-label": "0" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  onClick={submitdriver}
                  disabled={isButtonDisabled}
                  className="btn btn-secondary float-end px-5 p-2"
                >
                  {isButtonDisabled ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDriver;
