import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../Components/admin/admincard.css";
import vendor from "../../../assets/admin/assets/img/admin.svg";
import driver from "../../../assets/admin/assets/img/driver.svg";
import total_earning from "../../../assets/admin/assets/img/total_earing.svg";
import im_icon from "../../../import.svg";
import ex_icon from "../../../export.svg";
import cr_icon from "../../../Credits.svg";
import { Link } from "react-router-dom";
import Import from "../../../assets/admin/assets/img/Import.svg";
import Export from "../../../assets/admin/assets/img/Export.svg";
import { ErrorMessage } from "../../../Components/Customalert/Customalert";

const Vendorcard = () => {
  const [card, setcard] = useState([]);

  useEffect(() => {
    axios
      .get(`/vendor/total-count-all`)
      .then((res) => {
        if (res.data.status === true) {
          setcard(res.data);
        }
      })
      .catch((err) => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
  }, []);

  return (
    <>
      <div className="col-md-4 mb-3 cards-design">
        <Link to="/vendor/vehiclelist">
          {" "}
          <div className="card ">
            <div className="title">
              <h5>Total Vehicles</h5>
            </div>
            <div className="icons">
              <img src={vendor} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.vehiclecount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 cards-design">
        <Link to="/vendor/Orderlist">
          <div className="card">
            <div className="title">
              <h5>Total Orders</h5>
            </div>
            <div className="icons">
              <img src={total_earning} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.ordercount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 cards-design">
        <Link to="/vendor/driverList">
          <div className="card">
            <div className="title">
              <h5>Total Drivers</h5>
            </div>
            <div className="icons">
              <img src={driver} className="icon" width="50px"></img>
            </div>
            <div className="value">
              <p>{card.drivercount}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-3 cards-design">
        {/* <Link to="/vendor/List/Import"> */}{" "}
        <div className="card ">
          <div className="title">
            <h5>Import</h5>
          </div>
          <div className="icons">
            <img src={Import} className="icon" width="50px"></img>
          </div>
          <div className="value">
            <p>{card.importcount}</p>
          </div>
        </div>
        {/* </Link> */}
      </div>
      <div className="col-md-4 mb-3 cards-design">
        {/* <Link to="/vendor/List/Export"> */}{" "}
        <div className="card ">
          <div className="title">
            <h5>Export</h5>
          </div>
          <div className="icons">
            <img src={Export} className="icon" width="50px"></img>
          </div>
          <div className="value">
            <p>{card.exportcount}</p>
          </div>
        </div>
        {/* </Link> */}
      </div>
      <div className="col-md-4 mb-3 cards-design">
        {/* <Link to="/vendor/Credits"> */}{" "}
        <div className="card ">
          <div className="title">
            <h5>Total Earnings</h5>
          </div>
          <div className="icons">
            <img src={total_earning} className="icon" width="50px"></img>
          </div>
          <div className="value">
            <p>{card.totalearning}</p>
          </div>
        </div>
        {/* </Link> */}
      </div>
    </>
  );
};

export default Vendorcard;
