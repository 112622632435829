import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./customer.scss";
import eye from "../../../assets/admin/assets/img/eye.svg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const VendorView = (props) => {
  const url = process.env.REACT_APP_API_KEY_link_url;
  const [inprogresss, setinprogress] = useState([]);
  const [vechile, setvechile] = useState([]);
  const [totalorder, settotalorder] = useState({});
  const [driverview, setdriverview] = useState({
    first_name: "",
    last_name: "",
    email: "",
    profile_photo_path: "",
    license_photo: "",
    contact_number: "",
    address: "",
    secondary_number: "",
    pan_number: "",
    city_name: "",
    state_name: "",
    priority: "",
    commission_per: "",
    status_name: "",
  });

  //  ................vendor-show api get......................

  useEffect(() => {
    const vendor_show = props.match.params.id;
    const getVendor = async () => {
      const res = await axios.get(`/admin/vendor-show/${vendor_show}`);
      if (res.data.status === true) {
        setdriverview(res.data.vendor);
        setvechile(res.data.vehicledetails);
        setinprogress(res.data.inprogresslist);
        settotalorder(res.data.totalorder);
      }
    };

    try {
      getVendor();
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }, []);

  return (
    <div className="container-fluid px-4 customer-view">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">
            Vendor View : {driverview.vendor_sid}
          </span>
          <Link
            to="/admin/vendor/vendorlist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <div className="row px-5">
            <div className="col-md-12 view-main">
              <div className="first-col">
                <h6>Vendor Name</h6>
                <h6>Address</h6>
                <h6>Email ID</h6>
                <h6>Phone Number</h6>
                <h6>Secondary Number</h6>
                <h6>GST Number</h6>
              </div>
              <div className="first-col-sibling">
                <small>{driverview.first_name}</small>
                <small>{driverview.address}</small>
                <small>{driverview.email}</small>
                <small>{driverview.contact_number}</small>
                <small>{driverview.secondary_number}</small>
                <small>{driverview.gst_number}</small>
              </div>
              <div className="second-col">
                <h6>State</h6>
                <h6>City</h6>
                <h6>Priority</h6>
                <h6>Commission</h6>
                <h6>PAN Document</h6>
                <h6>GST Document</h6>
              </div>
              <div className="first-col-sibling">
                <small>{driverview.state_name}</small>
                <small>{driverview.city_name}</small>

                <small>{driverview.priority}</small>
                <small>
                  {driverview.commission_per
                    ? driverview.commission_per
                    : "null"}
                </small>
                <small>
                  {driverview.pan_doc !== "" ? (
                    <>
                      <a href={`${url}${driverview.pan_doc}`} target="_blank">
                        <PictureAsPdfIcon />
                      </a>
                    </>
                  ) : (
                    <>
                      <small>N/A</small>
                    </>
                  )}
                </small>
                <small>
                  {driverview.gst_doc !== "" ? (
                    <>
                      <a href={`${url}${driverview.gst_doc}`} target="_blank">
                        <PictureAsPdfIcon />
                      </a>
                    </>
                  ) : (
                    <>
                      <small>N/A</small>
                    </>
                  )}
                </small>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                {totalorder ? (
                  <Link to={`/admin/vendor/vendor-allorder/${driverview.id}`}>
                    <button className="btn btn-success mt-5" type="sumbit">
                      Total Orders
                    </button>
                  </Link>
                ) : null}
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
          <div className="row px-2 mt-4">
            <div className="col-md-12">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      <h5>Vehicle List</h5>
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse collapse "
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <div className="card-body mt-2">
                        <div class="table-responsive">
                          <table class="table  table-striped border-start border-end">
                            <thead>
                              <tr className="head_color">
                                <th>S No</th>
                                <th>Vehicle Number</th>
                                <th>Vehicle Status</th>
                                <th>Vehicle Type</th>
                                <th>Vehicle Feet</th>
                                <th>Driver Name</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {vechile.length > 0 ? (
                                vechile.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item.vehicle_number}</td>
                                      <td>{item.vehicle_status}</td>
                                      <td>{item.truck_type}</td>
                                      <td>{item.truck_capacity}</td>
                                      <td>{item.driver_name}</td>
                                      <td>
                                        <Link
                                          to={`/admin/vehicle/vehicle-view/${item.vehicle_id}`}
                                        >
                                          <img src={eye} width="35px"></img>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <h6 className="px-4 mt-3">No Record Found</h6>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      <h5>In Progress List</h5>
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    class="accordion-collapse expand"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div class="accordion-body">
                      <div className="card-body mt-2">
                        <div class="table-responsive">
                          <table class="table table-striped border-start border-end">
                            <thead>
                              <tr className="head_color">
                                <th>S No</th>
                                <th>Job Number</th>
                                <th>Customer Name</th>
                                <th>Driver Name</th>
                                <th>Loading</th>
                                <th>Unloading</th>
                                <th>Vehicle Number</th>
                                <th>Booking Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {inprogresss.length > 0 ? (
                                inprogresss.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item.order_id}</td>
                                      <td>{item.customer_first_name}</td>
                                      <td>{item.driver_first_name}</td>
                                      <td>{item.loading}</td>
                                      <td>{item.unloading}</td>
                                      <td>{item.vehicle_number}</td>
                                      <td>{item.booking_status_id}</td>
                                      <td>
                                        <Link
                                          to={`/admin/order/approval-view/${item.id}`}
                                        >
                                          <img src={eye} width="35px"></img>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <h6 className="px-4 mt-3">No Record Found</h6>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorView;
