import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Radio from "@mui/material/Radio";
import swal from "sweetalert";
import { ErrorMessage } from "../../Customalert/Customalert";

const Warehouseupdate = (props) => {
  const history = useHistory();
  const [city, setcity] = useState([]);
  const [errors, setErrors] = useState({});
  const [warehouseview, setwarehouseview] = useState({
    address: "",
    city_id: "",
    id: "",
    status: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //  .....................handleChangee.................................
  const [selectedValue, setSelectedValue] = React.useState(0);

  const handleChangee = (event) => {
    setSelectedValue(parseInt(event.target.value));
    if (selectedValue === 1) {
      return "true";
    } else {
      return "false";
    }
  };

  useEffect(() => {
    setSelectedValue(warehouseview.status);
  }, [warehouseview]);

  // ..................handleInput...............................

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    switch (name) {
      case "status":
      case "port":
      case "city_id":
        setwarehouseview({
          ...warehouseview,
          [name]: value,
        });
        break;
      default:
        setwarehouseview({
          ...warehouseview,
          [name]: value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
    }
  };

  // ........................validate.............................

  const validate = (values) => {
    const errors = {};
    if (!values.address) {
      errors.address = "Address is required !";
    }
    if (!values.city_id) {
      errors.city_id = "City is required !";
    }
    if (!values.port) {
      errors.port = "Port is required !";
    }
    return errors;
  };

  // ..................warehouse edit api...........................

  useEffect(() => {
    const warehouse_show = props.match.params.id;

    const getcity = async () => {
      try {
        const res = await axios.get(`/admin/warehouse-show/${warehouse_show}`);
        if (res.data.status === true) {
          setwarehouseview(res.data.warehouseshow);
        } else {
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    getcity();
  }, [props.match.params.id]);

  // .........................submit.................................
  const submitvendor = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setErrors(validate(warehouseview));
    const formdata = new FormData();
    formdata.append("id", warehouseview.id);
    formdata.append("address", warehouseview.address);
    formdata.append("city_id", warehouseview.city_id);
    formdata.append("port", warehouseview.port);
    formdata.append("status", selectedValue);
    axios
      .post(`/admin/warehouse-update`, formdata)
      .then((res) => {
        if (res.data.status === true) {
          setwarehouseview(res.data.warehouse);
          swal("Updated", res.data.message, "success");
          history.push("/admin/warehouse/warehouselist");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      })
      .catch(() => {
        ErrorMessage({ message: "Something Went Wrong !" });
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // ..........city list api get...........
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/city-list");
        const data = response.data;
        if (data.status === true) {
          setcity(data.citylist);
        } else if (data.status === false) {
        }
      } catch (error) {
        // alert.error("An error occurred while fetching data.");
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-fluid px-4">
      <div className="titile px-1 mb-5 mt-4">
        <h4>
          <span className="titile_span">Warehouse Update</span>
          <Link
            to="/admin/warehouse/warehouselist"
            className="btn btn-primary admin float-end"
          >
            Go Back
          </Link>
        </h4>
      </div>
      <div className="card mt-4">
        <div className="card-body px-3 mt-3 mb-3">
          <form>
            <div className="row px-5">
              <div className="col-md-6 form-group mb-3">
                <label>
                  Location(Address) <span className="star_icon">*</span>
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={handleInput}
                  value={warehouseview.address}
                  rows="3"
                ></textarea>
                <p>{errors.address}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  City <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  disabled
                  onChange={handleInput}
                  value={warehouseview.city_id}
                >
                  {city.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  <option value="">Select City</option>
                </select>
                <p>{errors.city_id}</p>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Port <span className="star_icon">*</span>
                </label>
                <select
                  className="form-control"
                  name="port"
                  value={warehouseview.port}
                  onChange={handleInput}
                >
                  <option value="">--Select Port--</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div className="col-md-6 form-group mb-3">
                <label>
                  Status <span className="star_icon">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-3">
                    <Radio
                      checked={selectedValue === 1}
                      onChange={handleChangee}
                      value={1}
                      name="status"
                      inputProps={{ "aria-label": "true" }}
                    />
                    Active
                  </div>
                  <div className="col">
                    <Radio
                      checked={selectedValue === 0}
                      onChange={handleChangee}
                      value={0}
                      name="status"
                      inputProps={{ "aria-label": "false" }}
                    />
                    In-active
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-group mb-3">
                <input
                  type="hidden"
                  placeholder="ID"
                  name="id"
                  onChange={handleInput}
                  value={warehouseview.id}
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-secondary float-end px-5 p-2"
                  onClick={submitvendor}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Warehouseupdate;
