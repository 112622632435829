import axios from "axios";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import "../auth/auth.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import icon from "../../../assets/frontend/icon.png";

const Register = () => {
  const history = useHistory();

  const [Register, setRegister] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);

  const handleInput = (e) => {
    e.preventDefault();
    setRegister({ ...Register, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.mobileno) {
      errors.mobileno = "Mobile Number is required!";
    } else if (values.mobileno.length < 10) {
      errors.mobileno = "Mobile Number must be more than 10 Number";
    } else if (values.mobileno.length > 10) {
      errors.mobileno = "Mobile Number cannot exceed more than 10 Numbers";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    if (values.password !== values.Confirm_Password) {
      errors.Confirm_Password = " Password Don't match ";
    }
    return errors;
  };

  const RegisterSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(Register));
    setIsSubmit(true);

    const data = {
      name: Register.name,
      email: Register.email,
      password: Register.password,
      mobileno: Register.mobileno,
    };

    axios.get("/csrf-cookie").then((response) => {
      axios.post(`/auth/register`, data).then((res) => {
        if (res.data.status === true) {
          localStorage.setItem("token", res.data.token);
          swal("succesful", res.data.message, "success");
          history.push("/");
        } else if (res.data.status === false) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "warning",
          });
        }
      });
    });
  };

  return (
    <div className="home">
      <div className=" register container py-5">
        <div className="row justify-content-center gx-0">
          <div className="col-md-4">
            <img src={icon} className="profile-admin"></img>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body ">
                <form onSubmit={RegisterSubmit}>
                  <div className="form-group mb-3">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={Register.name}
                      className="form-control"
                    />
                  </div>
                  <p>{formErrors.name}</p>
                  <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                      type=""
                      name="email"
                      onChange={handleInput}
                      value={Register.email}
                      className="form-control"
                    />
                  </div>
                  <p>{formErrors.email}</p>
                  <div className="form-group mb-3">
                    <label>Password</label>
                    <input
                      type=""
                      name="password"
                      onChange={handleInput}
                      value={Register.password}
                      className="form-control"
                    />
                  </div>
                  <p>{formErrors.password}</p>
                  <div className="form-group mb-3">
                    <label>Confirm Password</label>
                    <input
                      type=""
                      name="Confirm_Password"
                      onChange={handleInput}
                      value={Register.confirmpassword}
                      className="form-control"
                    />
                  </div>
                  <p>{formErrors.Confirm_Password}</p>
                  <div className="form-group mb-3">
                    <label>Mobile Number</label>
                    <input
                      type=""
                      name="mobileno"
                      onChange={handleInput}
                      value={Register.mobileno}
                      className="form-control"
                    />
                  </div>
                  <p>{formErrors.mobileno}</p>
                  <div className="row butt">
                    <div className="form-group mb-3 col-md-6">
                      <button type="submit" className="btn btn-primary">
                        Register
                      </button>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <Link to="/">
                        {" "}
                        <button className="btn btn-primary btn-sm">
                          {" "}
                          Go Back{" "}
                        </button>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
