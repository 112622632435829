import React, { useState, useEffect } from "react";
import { UserContext } from "../../../Usecontext/UserContext";
import axios from "axios";
import swal from "sweetalert";

const ValueProvide = ({ children }) => {
  const [pagenum, setpagenum] = useState({});
  const [notify_alert, setalert] = useState(false);
  const [state, setstate] = useState([]);
  const [vennotify, setvennotify] = useState([]);
  const [trucktype, settrucktype] = useState([]);
  const [venalert, setvenalert] = useState(false);
  const [notification, setnotification] = useState([]);
  const [truckcapacity, settruckcapacity] = useState([]);
  const [driver_path, setdriver_path] = useState({});
  const [customer_path, setcustomer_path] = useState({});
  const [user_path, setuser_path] = useState({});
  const [vendor_path, setvendor_path] = useState({});
  const [vehicle_doc_path, setvehicle_doc_path] = useState({});
  const [approval, setapproval] = useState([]);
  const [customerpdf, setcustomerpdf] = useState({});
  const [customers, setcustomer] = useState({});
  const [customer_pdf, setcustomer_pdf] = useState([]);

  // useEffect api
  useEffect(() => {
    trucktypeapi();
    truckcapacityapi();
    stateapi();
  }, []);

  const trucktypeapi = async () => {
    const res = await axios.get(`/typelist`);
    if (res.data.status === true) {
      settrucktype(res.data.truck_type);
    } else if (res.data.status === false) {
      swal({
        title: "Error",
        text: res.data.message,
        icon: "warning",
      });
    }
  };

  const truckcapacityapi = async () => {
    const res = await axios.get(`capacitylist`);
    if (res.data.status === true) {
      settruckcapacity(res.data.truck_capacity);
    } else if (res.data.status === false) {
      swal({
        title: "Error",
        text: res.data.message,
        icon: "warning",
      });
    }
  };

  const stateapi = async () => {
    const res = await axios.get(`/state-list`);
    if (res.data.status === true) {
      setstate(res.data.statelist);
    } else if (res.data.status === false) {
      swal({
        title: "Error",
        text: res.data.message,
        icon: "warning",
      });
    }
  };

  useEffect(() => {
    const getitems = async () => {
      const result = await axios.get(`/admin/total-orderCount`);
      setpagenum(result.data.OrderCount);
      setcustomer(result.data.CustomerCount);
    };
    getitems();
  }, []);

  useEffect(() => {
    axios.get(`/config`).then((res) => {
      if (res.data.status === true) {
        setdriver_path(res.data.config.driver_path);
        setcustomer_path(res.data.config.customer_path);
        setuser_path(res.data.config.user_path);
        setvendor_path(res.data.config.vendor_path);
        setvehicle_doc_path(res.data.config.vehicle_doc_path);
        setcustomerpdf(res.data.config.customer_pdf);
        setcustomer_pdf(res.data.config.order_path);
      } else if (res.data.status === false) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "warning",
        });
      }
    });
  }, []);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.first_name) {
      errors.first_name = "First Name is required !";
    }
    if (!values.vendor_id) {
      errors.vendor_id = "Vendor Id is required !";
    }
    if (!values.driver_id) {
      errors.driver_id = "Driver Id is required !";
    }
    if (!values.vehicle_manufacture) {
      errors.vehicle_manufacture = "Vehicle Manufacture  is required !";
    }
    if (!values.truck_type_id) {
      errors.truck_type_id = "Truck Type is required !";
    }
    if (!values.truck_capactiy_id) {
      errors.truck_capactiy_id = "Truck Capactiy is required !";
    }
    if (!values.length) {
      errors.length = "Length  is required !";
    }
    if (!values.width) {
      errors.width = "Width  is required !";
    }
    if (!values.vehicle_number) {
      errors.vehicle_number = "Vehicle Number  is required !";
    }
    if (!values.last_name) {
      errors.last_name = "Last Name is required !";
    }
    if (!values.address) {
      errors.address = "Address is required !";
    }
    if (!values.insurance) {
      errors.insurance = "Insurance is required !";
    }
    if (!values.rcbook) {
      errors.rcbook = "Rcbook is required !";
    }
    if (!values.localpermit) {
      errors.localpermit = "Localpermit is required !";
    }
    if (!values.fc) {
      errors.fc = "Fc is required !";
    }
    if (!values.roadtax) {
      errors.roadtax = "Roadtax is required !";
    }
    if (!values.description) {
      errors.description = "Description is required !";
    }
    if (!values.amount) {
      errors.amount = "Amount is required !";
    }
    if (!values.polution) {
      errors.polution = "Polution is required !";
    }
    if (!values.nationalpermit) {
      errors.nationalpermit = "Nationalpermit is required !";
    }
    if (!values.state_id) {
      errors.state_id = " State is required !";
    }
    if (!values.city_id) {
      errors.city_id = " City is required !";
    }
    if (!values.commission_per) {
      errors.commission_per = " Commission is required !";
    }
    if (!values.priority) {
      errors.priority = " Priority is required !";
    }
    if (!values.status) {
      errors.status = " status is required !";
    }
    if (!values.email) {
      errors.email = "Email is required !";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format !";
    }
    if (!values.contact_number) {
      errors.contact_number = "Mobile Number is required !";
    } else if (values.contact_number.length < 10) {
      errors.contact_number = "Mobile Number must be more than 11 Number !";
    } else if (values.contact_number.length > 15) {
      errors.contact_number =
        "Mobile Number cannot exceed more than 10 Numbers !";
    }
    if (!values.secondary_number) {
      errors.secondary_number = "Mobile Number is required !";
    } else if (values.secondary_number.length < 10) {
      errors.contact_number = "Mobile Number must be more than 10 Number !";
    } else if (values.secondary_number.length > 10) {
      errors.secondary_number =
        "Mobile Number cannot exceed more than 10 Numbers !";
    }
    if (!values.emer_num) {
      errors.emer_num = "Mobile Number is required !";
    } else if (values.emer_num.length < 10) {
      errors.emer_num = "Mobile Number must be more than 10 Number !";
    } else if (values.emer_num.length > 10) {
      errors.emer_num = "Mobile Number cannot exceed more than 10 Numbers !";
    }
    if (!values.container_number) {
      errors.container_number = "Container Number is required !";
    } else if (values.container_number.length < 8) {
      errors.container_number =
        "Container Number must be more than 11 Number !";
    } else if (values.container_number.length > 15) {
      errors.container_number =
        "Container Number cannot exceed more than 11 Numbers !";
    }
    if (!values.password) {
      errors.password = "Password is required !";
    } else if (values.password.length < 8) {
      errors.password = "Password must be more than 8 characters !";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters !";
    }
    if (values.password !== values.password_confirmation) {
      errors.password_confirmation = " Password Don't match !";
    }
    return errors;
  };

  return (
    <UserContext.Provider
      value={{
        validate,
        state,
        trucktype,
        truckcapacity,
        driver_path,
        vehicle_doc_path,
        vendor_path,
        user_path,
        customer_path,
        pagenum,
        approval,
        approval,
        setapproval,
        customerpdf,
        customers,
        notification,
        setnotification,
        setalert,
        notify_alert,
        setvennotify,
        vennotify,
        setvenalert,
        venalert,
        customer_pdf,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default ValueProvide;
