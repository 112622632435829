import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export function ErrorMessage({ message }) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    html: `<h6 class="text-center mt-2">${message}</h6>`,
    showCancelButton: false,
    showConfirmButton: false,
  });
}
